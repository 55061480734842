import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Emergent extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'Waste management and reduction consultancy in Nashville, TN, founded by a 20+ years executive in municipal waste management. Naming, branding, stationery design, website, and social media for go-to-market sales campaign.'
					}}
					details={[
						{ title: 'Year', content: '2014' },
						{ title: 'Client', content: 'Emergynt Management Group' },
						{ title: 'Role', content: 'Naming, Branding, Design, Strategy' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXnMvgPslQcXaKrC7FMbEFlPo4NGsw7_BAbqeUG5F79vOnvZ5OMapGVZHpO8eryZFqzyDGBDsi2erMJFAWQeTylrQGvCCWgAzeu81gOVEmJUT-EgDE3rp1iMchIBUu4F1pbvMA5UaRTAcWrLCcPxdXhBQ=w1200-h600-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX89iojAHs1eCZIOjIyFMprbxhdIZJU9wafqaIKmEF_dFJJPihh8DEPmz5PjRe44LnkieHwzqy7YNx-jMZDQ2lSbHjQBISsGGs9CjyvSxBndufyWFxs4na5NeFyEqYGiNWpCocXQ6U4V_oAVCBmJIVs_A=w1200-h478-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX-kQR2gT3ZY9AqVV5i3EONN6lHuGoSR2tQAVJXBfreE0sTemip_qmwC7gOsLovllgg3lIm6rUyWxSowvD5O8goUSKc0-Rma-ZiTeFhVzyAItWdzJ0IMqYrmr9R67CvrPLAUEYi0iVLXftuQwN9C5oAyA=w1200-h600-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV1oZrtCty720C5KhDaefK2QXT4vv8dRyDbcd6ZVvSXi01EthOICJjHO6hu-sfpyplbP7fCMB5TN7M5Pu1GEFf5A86sV-t0jOMI0IZAXcOrBn8CZ4QJIQCL9Ehc8bkWaLMf65-B-nZQWCHZ0q3wpKF_tw=w1200-h800-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV08gV82InnyNHojIBnERS9STibdIfaomHuWd1W-h00BKZORjeqKa2d5-9l4YH7qV9HLuJ9AoR4HxPxlP9fbgz5DvPZQS_avUGMDFu43q8fFmOO3rUrZlkPgbSADv9FKszbBaSvi4nsytGPPGaP6vyhRQ=w1200-h800-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVvKaqdVTtfjEQ2nV5UHlJY1DggsmFjJnwtXoL1sT46CX3W0X6ASrH25H-OkAz-si1fxOChMsGSaVKGBdKOD1IRgfwjX7Ybl_0B_Enupz40ymoidO60ahibGMXBlUsBx87yM7vo6WLFneX-KB-agRateg=w1200-h800-no?authuser=0"
					alt="Emergynt sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

			</>
		);
	}
}