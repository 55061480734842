import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class DataFi extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'In 2014, it was still pretty edgy to proclaim <em>“finance is the next industry for technology to disrupt.”</em> The philosophy and mission of DataFi was exactly that: create a corporate vehicle through which subsidiary FinTech products and services could launch, and help bring contemporary concepts to the financial services industry. With a major investor (and financial industry titan) in tow, and a founding team of startup veterans, DataFi set the table for disruption.'
					}}
					details={[
						{ title: 'Year', content: '2014' },
						{ title: 'Client', content: 'DataFi' },
						{ title: 'Role', content: 'Naming, Branding, Print Design, Website Design' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWPbgA397x4Z5RjXCExomYiTu0AjtBrYV68Xrt2Tg8v-6-ayptMY-jK-cbBNzUkGo5Zq8Bp2Th-lTA4n1TJcaOREvHDbDVOR2nm_9LPt94rqN2ihUPDfZAiJVh7aWAkshUnFVt3E2zwDMxxYhH58Q-aZw=w2320-h1780"
					alt="Stylized sample image of the DataFi stationery system."
				/>

				<Spacer spacing={120} />

				<CaseStudyQuote
					content="We had bold ambitions for DataFi: scale our work to every bank in the US."
					person={{ name: 'Scott Sohr', title: 'DataFi Founder' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Establishing a name and brand identity"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">As is always the case with new ventures, time was of the essence, forcing us to move quickly through mind mapping exercises and rally around a concept. With data science at the heart of the business, the mashonym of DataFi quickly surfaced. After standard checks and balances, the domain was secured and the company name was made concrete via the logo concept below.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWeTIZ_80v5ckpkqkukAb-1X03GkOkuslUOJmGlrNKABvqyCZdfSln2iqgDSnzWX9hxzNDe2iiaue_nwUzjSTd7zu0qZ-I6om6jhNxkEjHKpJg8A82ve2e6ACgUOrZBZkH1PD6Lz_PI0tpQKoguN0C6_g=w1840-h1266"
					alt="Image of the DataFi logo's construction technique and the atomic elements of the DataFi logo design."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Brand application design"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">With no tangible products to offer yet, we needed brand materials that would help us get in front of industry professionals and validate our ideas. To help get a foot in the door, we rolled out brand pieces for our team: business cards, letterhead, envelopes, mailing materials for shipping our RFP responses, and standard intra-office tangibles like appareal and office signage.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWz2fhmOU7VyOG1QqUucETJkDL20YSiOJMdRLw38j0-vrPAOTqZioFNJXbC8tcNm0FcHPABu_XwV3q6-Th_FoEg6qPmSCgYPtaBeiGP-WiJPLOsplQbq_w8o0vu957ajT4z7igbz8panmSorxVWu6Z_-A=w1840-h1814"
					alt="Sample images of the DataFi logo applied to various branded materials such as business cards, letterhead, and apparel."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Tradeshow bill of materials"
					image={false}
					reverse={false}
					subtitle="We rolled the dice and became participants in MetroStudy’s 2015 industry tradeshow. By now, we were squarely in the construction lending space, and wanted to spark conversations with lenders and borrowers.<br/><br/>We conducted interviews with clients that were using our MVP-level loan origination product, and used their feedback to create a series of banner stands and handbills that we hoped would attract new clients. We exited the conference 100+ clients richer for the investment."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXm7j0-TEB8Iomu6GkODLwYHPq_LiMwJYVyRffGT7a-yXXKTYvHyBLoD-epBKOSL0G4MUhkwGHBPAc9-jlmhKDHssvQ2lraX6hdspp6-LvxE5RpzNkq4ofqlFqp4qMeR2mIdop5vRWrpETLxs7s0Jlm5w=w1320-h1582"
						alt="Image of DataFi banner stands created for the 2015 MetroStudy financial services industry conference."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Marketing website design"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">As the company continued to grow, we outpaced the capabilities of the splash page we launched the brand with, and eventually developed a full-blown web presence. The site featured the stock components: a conversion-optimized homepage, a white paper and gated-content blog and insights section, and other standard website fare, complete with a codified design system for desktop and responsive views.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXUK1r6CsO8420xxep64FmFQL9E5CBOgU7ElptK-LY3Y6gMvDbYFUpvgUC_Csgbo4u83ZaUgmR6ymk3JIktE0TNxG5f2kXjpWhDO72C6718k9IXtRhsGlv_l35JIiJzVOet1xe8CwktG6bsdsGv0Rruhg=w1840-h1200"
					alt="Sample images of the DataFi marketing website."
				/>

				<Spacer spacing={120} />

				<p className="caseStudy__reflection paragraph--small">In a short but dizzying 2 years, DataFi exploded from concept to reality. As our construction lending platform evolved into a $2B+ company, we sunset DataFi: the brand, the website, everything. We quickly diverted all attention into the new company, Built, which you can learn more about elsewhere in this site.</p>

				<Spacer spacing={120} />

			</>
		);
	}
}
