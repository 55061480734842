import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class SuperiorProducts extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Tasked with helping the owner position his 100-year old prosthetics and orthotics company for acquisition, our team was given near-complete creative license to create an attractive, high-fitness oriented brand that stood apart from the brand-lacking competition. With a masterful logomark from talented designer Kevin Burr, high-end photography from Travis Neely, and beautiful design work from Sean Furr, our 6-person project team delivered on the client request, and the owner successfully sold the business to Hanger Prosthetics in 2014 for a multi-million dollar sum.'
					}}
					details={[
						{ title: 'Year', content: 2014 },
						{ title: 'Client', content: 'Superior Prosthetics and Orthotics' },
						{ title: 'Role', content: 'Branding, Art Direction, Print Design, Web Design' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXZfEe3mZigPnPl9K8xiDggTgSGADfHbWUxTSYCYpQrdJshvd7uFEfa_lBmXBJLjM7zmcAeJixmVB4jaOoG_W42gLZCNh38W7OSnP2XNc4vzybdztMtUkYUlJ0uvDp5_h0QmCCUls4szcJpdvkS1ElChw=w1200-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV363eX1b1SBO25MvNYoWVjqwM6mTiZr2IFgUKUtNXNgK_5YrPlREOtK39C9TmKzDDGt3cSDOW5xmGZl_enMgBNEoAn0VdVsa1_Q6CBE0wt75y5iCH1mS5yjTR61ef9-rxej-6fcCQzJoYLqTOSAEj2_w=w1200-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXPq6rMqCGjPNMLA0PBJgkgwtb0h82kgVUlPD3085P3PviOshn_6Lm-oZeUxXx3oy7OXc62Wr4pvkUnfPRwVvCsSRk0at0fliM3IJIupAhBaBjbLtDAN2d8l5K1zBy1aWyVXtuSWkAlleNXIW1yjMZ9IQ=w1200-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXwvMIdSbQ58pD1qE8_d-HMHgu8Vlp53DTGUhM6mrip4R-WCtM6KqnksdWdTeIgjciNRoBbPT3REfkCsIVtS24Tvm0FqHh_gtEQcrwKyNthY93zBYqRb02KPkr0PgWct7eMdtjMCufp53h_FbTK9Yq9yQ=w1200-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX3wr4zJmyvj9Vy0alhRxjwZyd0srrOXEv-zWTuzjtsX09Ut14S6vsu0C1b5DY3qWZzHvPhrze0LrqUxxQfoS707G6b4bBZirrspQV8hinI3Wlrxcz0zKf8ILnCPrRKjQLykeIQdUqhFAvopHdexgPtVg=w1200-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWBoxYlTC8jwfh2pABVnRwGdCwGSr6BWcIm5q2T-F5-Hj0gI9v7o6YfJLJtkbztqY0lMD_e05DIbSwaWABvKgwdKUbpZyvS2Tf4Cjk73SZNVTjyERNjkEobTuegN6jhiX6VIbaDZRdQuPB-n9HiBUpcUA=w1200-h650-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUYqO5RIukVyYB0daG6q4weBBL4OEezAQ-gUX7pxONaYZi6AxtQtJxxyP_b3oVQtX5MSRaas3eFKGCIDOX5RzYQKiTdJUMM4HvWUeFZ74qNw9j0Tu-i4gSt9iXxlhM89qg9z6NOvR7HCMFlCvg8O1ha7w=w1500-h800-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX9iq47mxkNmyuD8pYUT_3FItryndW9peg6M2Si852YyBXFu-KGmM3CEADOB5lx39AIfiCF67onz9ivbj_kMsPewi8udQOMtySOMgYX6HItzFsDPI1gOA6RYH_7sH6bhTd5R30KGaxWuCWggbHOGetPbg=w1200-h498-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXgLDvex67meoaU_9TTm3oBpryne_pO5OHFUW2JahVZtQwvymLHpo-ckFdlbEB8UkzFDgXog5wOnr1x9qvDwTnZmBsk_lU8FxodYQyWhGCMQ9mdlrxGl5ww07W_oW4bduZ1iVVG6_PsA75tENDCrrCL5A=w900-h495-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWyX3i-LhQsVEdQIrZmEHvsS8EeV-JwiXsI4I62raHcg9fkJo_GiQc9g9AvZIO1tDk6qfMAwQ0x4oCZM7iqtRkXgT9y2Dd3GRg56wXOK-H_WECuz-T80IY8MOIqv2uVaL4FpFWv35FWyOYhmarUZJoeiQ=w1200-h1698-no?authuser=0"
					alt="Superior Prosthetics and Orthotics sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<iframe title="vimeo-player" src="https://player.vimeo.com/video/84065944?h=e21fdd404d" width="1100" height="720" frameborder="0" allow="fullscreen" allowfullscreen title="Superior O&P, Case Study"></iframe>

				<Spacer spacing={180} />				

			</>
		);
	}
}








