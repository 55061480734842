import React from 'react';
import ReactDOM from 'react-dom';

export default class CaseStudyTestimonies extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	Testimony = ({ quote, name, title }) => (
		<div className="testimony">
			<p className="testimony__quote paragraph--small">{quote}</p>
			<p className="testimony__person paragraph--small">
				<span className="testimony__person__name">{name}</span>
				{title}
			</p>
		</div>
	);

	render() {
		const { Testimony } = this;
		return (
			<div className="caseStudy__testimonies">
				{this.props.testimonies.map((testimony, index) => (
					<Testimony quote={testimony.quote} name={testimony.name} title={testimony.title} />
				))}
			</div>
		);
	}
}
