import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class HiddenHolsters extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'A not-so-top-secret idea from a very top-secret source. When you have a client with more security clearance than you should know about, you pull out all stops to impress. We took the client concept through naming, branding, art direction and photography, then capped it off with a (at the time) leading edge website that brought a Hollywood feeling to an e-commerce desktop and mobile experience.'
					}}
					details={[
						{ title: 'Year', content: '2013' },
						{ title: 'Client', content: 'Hidden Holsters' },
						{ title: 'Role', content: 'Naming, Branding, Art Direction, Photography, Copywriting, Web Design' }
					]}
				/>

				<Spacer spacing={60} />

<iframe title="vimeo-player" src="https://player.vimeo.com/video/58262596?h=6319fea616" width="1100" height="720" frameborder="0" allowfullscreen></iframe>

				<Spacer spacing={120} />

			</>
		);
	}
}