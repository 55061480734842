export const projects = [

	{
		name: 'Highbrow',
		tags: ['Branding', 'Strategy', 'E-Commerce'],
		description: 'Real people, real stories, real healing through CBD and MMJ',
		route: '/highbrow'
	},

	{
		name: 'Buffalo Wild Wings',
		tags: ['UX Design'],
		description: 'Contactless in-store ordering for Buffalo Wild Wings',
		route: '/buffalo'
	},	
	
	{
		name: 'Skurt',
		tags: ['Research', 'UX Design'],
		description: 'A 5-day sprint to reimagine the rental car rebooking experience',
		route: '/skurt'
	},
	
	{
		name: 'Noah Basketball',
		tags: ['UX Design'],
		description: 'Helping basketball players and coaches find the perfect arc',
		route: '/noah-basketball'
	},

	{
		name: 'HealthShift',
		tags: ['UX Design', 'Naming', 'Branding'],
		description: 'On a mission to create more members of a healthier world',
		route: '/healthshift'
	},

	{
		name: 'Built',
		tags: ['Naming', 'Branding', 'UX Design'],
		description: `Inventing the world's first construction lending platform`,
		route: '/built'
	}

];

export const archives = [
	{ name: 'Inspire Brands', route: '/inspire', hasCaseStudy: false },
	{ name: 'Egencia', route: '/egencia', hasCaseStudy: false },
	{ name: 'DataFi', route: '/datafi', hasCaseStudy: true },
	{ name: 'Uberscore', route: '/uberscore', hasCaseStudy: true },
	{ name: 'Canary Venture', route: '/canary-venture', hasCaseStudy: true },
	{ name: 'Kent Maggard', route: '/kent-maggard', hasCaseStudy: true },
	{ name: 'ApexWorks', route: '/apexworks', hasCaseStudy: true },
	{ name: 'NAHM', route: '/nahm', hasCaseStudy: true },
	{ name: 'Edmeades', route: '/edmeades', hasCaseStudy: true },
	{ name: 'Superior Prosthetics', route: '/superior-prosthetics', hasCaseStudy: true },
	{ name: 'Chumash Magazine', route: '/chumash-magazine', hasCaseStudy: true },
	{ name: 'Speakable', route: '/speakable', hasCaseStudy: true },
	{ name: 'Ten Fast Feet', route: '/ten-fast-feet', hasCaseStudy: true },
	{ name: 'Emergynt', route: '/emergynt', hasCaseStudy: true },
	{ name: 'JobClocker', route: '/jobclocker', hasCaseStudy: true },
	{ name: 'Hidden Holsters', route: '/hidden-holsters', hasCaseStudy: true },
	{ name: 'Art School', route: '/art-school', hasCaseStudy: true },
	{ name: 'Logos', route: '/logos', hasCaseStudy: true },	
];

let featuredRoutes = [];
let totalRoutes = [];
let totalProjects = [];

for (let i = 0; i < projects.length; i++) {
	featuredRoutes.push(projects[i].route);
	totalRoutes.push(projects[i].route);
	totalProjects.push({
		name: projects[i].name,
		route: projects[i].route
	});
}

for (let i = 0; i < archives.length; i++) {
	totalRoutes.push(archives[i].route);
	totalProjects.push({
		name: archives[i].name,
		route: archives[i].route
	});
}

export const routes = featuredRoutes;
export const allRoutes = totalRoutes;
export const allProjects = totalProjects;

export const isProjectRoute = path => {
	return allRoutes.indexOf(path) != -1;
};

export const clients = [
	{ name: 'Expedia Group', link: '' },
	{ name: 'Inspire Brands', link: '' },
	{ name: 'DARPA', link: '' },
	{ name: 'Vanderbilt Medical Center', link: '' },
	{ name: 'Built Technologies', link: '' },
	{ name: 'American Express', link: '' },
	{ name: 'Yahoo!', link: '' },
	{ name: 'Bridgestone', link: '' },
	{ name: 'Husqvarna', link: '' },
	{ name: 'UNC Greensboro', link: '' },
	{ name: 'Primo Water', link: '' },
	{ name: 'Tallgrass Beef', link: '' },
	{ name: 'Buffalo Wild Wings', link: '' },
	{ name: "Jimmy John's", link: '' },
	{ name: "Arby's", link: '' },
	{ name: "Sonic Drive-in", link: '' },
	{ name: "Rusty Taco", link: '' },
	{ name: 'Vanderbilt University', link: '' },
	{ name: 'Noah Basketball', link: '' },
	{ name: 'Highbrow Group', link: '' },
	{ name: 'Wileman Restaraunt Group', link: '' },
	{ name: 'HealthShift', link: '' },	
	{ name: 'DataFi', link: '' },	
	{ name: 'Uberscore', link: '' },
	{ name: 'Canary Venture Co.', link: '' },
	{ name: 'Kent Maggard', link: '' },
	{ name: 'EndoInsight Devices', link: '' },
	{ name: 'Superior Prosthetics', link: '' },
	{ name: 'Briggs & Stratton', link: '' },
	{ name: 'Lonely Planet', link: '' },
	{ name: 'Pathrise', link: '' },
	{ name: 'XOYO Clothiers', link: '' },
	{ name: 'Waypoint Therapy', link: '' },
	{ name: 'Ten Fast Feet', link: '' },
	{ name: 'Chumash Magazine', link: '' },
	{ name: 'Collins Law Firm', link: '' },
	{ name: 'Edmeades', link: '' },
	{ name: 'Mondavi Winery', link: '' },
	{ name: 'Waypoint Therapy', link: '' },	
	{ name: 'Skurt', link: '' }
];

export const awards = [
	{ award: 'AIGA TennShow 2014', link: '', details: 'Gold Award  |  2014  |  Mobile App Design award for Noah Basketball suite' },
	{ award: 'AIGA TennShow 2014', link: '', details: 'Silver Award  |  2014  |  Brand & Identity Systems award for Canary Venture Co.' },
	{ award: 'W3Awards', link: '', details: 'Gold Award  |  2013  |  SuperiorOandP.com website relaunch' },
	{ award: 'W3Awards', link: '', details: 'Silver Award  |  2010  |  PrimoWater.com website relaunch for 2010 IPO campaign' },
	{ award: 'W3Awards', link: '', details: 'Silver Award  |  2010  |  ForsythTech.edu website relaunch' },
	{ award: 'W3Awards', link: '', details: 'Silver Award  |  2009  |  TheBloomAgency.com website relaunch' },
	{ award: 'W3Awards', link: '', details: 'Silver Award  |  2009  |  TallgrassBeef.com website relaunch' },
	{ award: '32nd Annual Telly Awards', link: '', details: 'Bronze Medal  |  2012  |  Forsyth Tech, “What Can You Do?" animated video' },
	{ award: '31st Annual Telly Awards', link: '', details: 'Silver Medal  |  2011  |  Primo Water, "Some People Just Get It" animated video' },
	{ award: 'American Communication Professionals', link: '', details: 'Bronze Award  |  2009  |  Southern Community Bank 2008 annual report' },
	{ award: 'National Council for Marketing & Public Relations', link: '', details: 'Gold Award  |  2009  |  ForsythTech.edu website relaunch' },
	{ award: 'National Council for Marketing & Public Relations', link: '', details: 'Silver Award  |  2009  |  Forsyth Tech enrollment campaign' },
	{ award: 'Dove Awards', link: '', details: 'Nominee  |  2010  |  Short-listed for Mass Media Music Group artists E.A. Floe and 9th Wonder' },
	{ award: 'Web Marketing Association WebAward', link: '', details: 'Gold Award  |  2008  |  American Express Tiger Woods card microsite' },
	{ award: 'Web Marketing Association WebAward', link: '', details: 'Gold Award  |  2008  |  American Express Pairings.com microsite' },
	{ award: 'Logo Lounge 4', link: '', details: 'Book Feature  |  2007  |  Featured logo designs for Goin’ Donuts and JustOne Entertainment' },
	{ award: 'Sketchel Show', link: '', details: 'Artist & Exhibitor  |  2005  |  Contributing artist for Design Is Kinky x Jeremyville\'s "Sketchel" show' },
	{ award: 'Webby Awards', link: '', details: 'Silver Award  |  2006  |  IT Hardware & Software category for VerticalResponse.com site relaunch' }
];
