import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import { TransitionGroup, Transition } from 'react-transition-group';

import Project from '../components/Project';
import Footer from '../components/Footer';

import * as Animate from '../helpers/Animate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class About extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {
		// This will be ABOUT on enter
		this.props.pushHistory(this.props.location.pathname.toLowerCase());
	}

	componentWillUnmount() {
		// This will be whatever on ABOUT exit
		this.props.pushHistory(this.props.location.pathname.toLowerCase());
	}

	render() {
		return (
			<div className="error">
				<section className="container container--vertical">
					<div className="container__content">
						<h1 className="error__title">404 - Not found</h1>
						<p className="error__description">
							The link you were looking for has either moved or no longer exists. Try starting from the home page by {' '}
							<Link to="/" onClick={() => this.props.navigate('404', '/')}>
								clicking here
							</Link>
							.
						</p>
					</div>
				</section>
				<Footer />
			</div>
		);
	}
}
