export const clipReveal = (element, sizeInitial, sizeFinal, location, duration, isBrowserFirefox) => {
	let clipPathTimeline = new TimelineMax(),
		arrayInitial = [sizeInitial],
		arrayFinal = [sizeFinal];

	let clipPath = function() {
		let clipValue = `circle(${arrayInitial[0]}px at ${location})`;
		// console.log('clipValue', clipValue);

		if (isBrowserFirefox) {
			// alert('browser firefox');
			element.style.clipPath = clipValue;
		} else {
			TweenMax.set(element, { webkitClipPath: clipValue });
		}
	};

	clipPathTimeline.to(arrayInitial, duration, {
		ease: 'Mullins',
		endArray: arrayFinal,
		onUpdate: clipPath
	});

	return clipPathTimeline;
};
