import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class NoahBasketball extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'When installed on a basketball court, the Noah Basketball system communicates with local devices to record shot depth, arc angle, and shot consistency of each shot taken. These things help improve players’ muscle memory and shot performance.<br /><br />NBA championship teams like the Miami Heat and Dallas Mavericks are using the Noah platform, as well as 1,500 other high school, college, and professional teams searching for the perfect arc: a 45º arc angle, and 11” rim depth.'
					}}
					details={[
						{ title: 'Year', content: 2015 },
						{ title: 'Client', content: 'Pillar Vision' },
						{ title: 'Role', content: 'UI Design, Native Mobile App Design, Content Strategy' }
					]}
				/>

				<Spacer spacing={120} spacingMobile={12}/>

				<CaseStudyCollage
					imageSources={[
						'https://lh3.googleusercontent.com/pw/AM-JKLW4JFEdBkFfd3X7FIj7R0N8cdsGMAbWDaxHbXqt1gIgO7fuR5X0xX7Mn_pkU27eo3sZI5pxyaz-NUBAxlyAHKAdvbMF0o1Iu80fOQ8l5k9Sfk_JRTHKtYqN3SBETXh-LUYlt97nPliiP14aRX9ZANs4lg=w798-h901-no?authuser=0',
						'https://lh3.googleusercontent.com/pw/AM-JKLX5MU_Tb6ZWulrVqasgJxec3dpcGgtfeAg_Enr5kYonSnlllJ5zVz_8aEymmN12FlU-9gR4M7yzTkut1TP3QwEjYhGUuyodLwZDRkeQlzw3rvA_QkaYmblUvhH-zsZeBr-fsbF_VlKZ0EgSGSTn0805Ew=w1671-h893',
						'https://lh3.googleusercontent.com/pw/AM-JKLXpwqwk0YZw5K33SrRSHbWuKERslXC-fMlzINMVg7zuer4D6I-df7UGtHBTzL4NFJCk_X9clUbXNnIy26csDs-6vSWSlg8ByLCXNJqxDKzrkBpuFr4XujNkr_PSWx8aSJgcOwXM9zJlDFf8rH1OWIOxig=w1612-h876'
					]}
					alts={[
						'Noah Basketball microcontroller, the home for all ball, rim, and arc recognition AI and processing.',
						'Noah Basketball Ultra-HD camera, for spatial plotting and ball tracking.',
						'Noah Basketball NoahNet™ protected LAN for security of all PII data.'
					]}
					description={{
						header: 'Clockwise from left',
						content: 'Microcontroller, the home for all ball, rim, and arc recognition AI and processing; Ultra-HD camera, for spatial plotting and ball tracking; NoahNet™, protected LAN for security of all PII data.'
					}}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Building our in-house prototype"
					subtitle="One of the first problems we faced was taking outdated code for the mobile application and updating it to compliant, native code for iOS 10."
					reverse={false}
				>
					<p className="paragraph--small">In the early days, there weren’t many prototypes to go around. With the help of the Noah team, we built our own with a microcontroller, an HD webcam, and a Noah Net connection. Once we established system communication, we started testing the core code, and began making code-level updates to bring the platform into the modern age.<br /><br />Thanks to user research, we quickly established the first use cases to solve: synching session data from multiple users, from multiple devices, to the cloud.</p>
				</CaseStudySection>

				<Spacer spacing={100} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW386F3qw-ueT9JSogAxgVhgxjW9rqSSTPJL5gBhv8QtPz8_tKTHs8hYrz5TOs32FQOlkyqwBo-qoS-kOX9BJ8bb-CC1c8hYXKOa1p3h2bks4lxQC3-9pWklT0rlejJaHLmPba4AZF9J3fOSaCHffwVbw=w1624-h1274"
					alt="Graphic of Noah Basketball app screens displayed on three iPhones."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="Noah is an essential element of my warm-ups."
					person={{ name: 'Dwayne Wade', title: '3x NBA champion and future hall-of-famer' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Connect & shoot instantly"
					subtitle="Join your gym's local Noah box and start tracking your shot performance with Noahlytics™ reporting. Set the type of shots you want to practice, the number of shots to measure, and start recording your session instantly."
					image={true}
					reverse={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUvD34MELpSvm_FIDgiqnxVXlaneMgX1j1Zi0FjhUe1sYlRGo8ezeyE2pK3j8cSkOVhVUymRtYyKbh96BTx83NsO5QJsG48kGp5Zy_SO3nS_vKVUCm_NlXHe86jDuR96c92IhD2YPELTdWiX2rUyOT04w=w1776-h1773-no?authuser=0"
						alt="Sample screens of Noah Basketball app's connect and shoot functionality."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection title="Live feedback on all your devices" subtitle="">
					<p className="paragraph--small">Real-time feedback on tablets, smartphones, and wearables gives players audio, textual, and haptic alerts about shot performance.</p>
				</CaseStudySection>

				<Spacer spacing={120} />

				<CaseStudyImage
					id="noah-basketball__image--feedback-devices"
					source="https://lh3.googleusercontent.com/pw/AM-JKLXlaGS9G_Hfu43fLFgru1PK0dOrY1YKkt0-hTzchUv98zZiEI5G1gtZ9b2PeStSQwTIyagUnnCE3nPkWgahRtbvU-qKDjvxQQV6kf7bQmJqIsx_IxycyewViSbLcLIroUkCwXnPZc0iS3xzCOwWq5IUcg=w2218-h1569"
					alt="Sample screens of the Noah Basketball app experience displayed on an Apple Watch and an iPhone."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Share your sessions"
					subtitle="Dig into the critical metrics for a session, review sessions with your coach or trainer, or compare with friends to see how you stack up against your arc nemesis."
					image={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLV4xHlrH6h1CB3Yh-PBT8BX3oEDcKYAZ09FY4Mxa6BOjPrHhVcWtx-g0vCmGToQHIlqGUXBb5Sddp6hITyM7Z89I0q1601tk_RpsB6nXvMGdrPoJNshqxlDIxcnQgUSIBQHFJHHtmg0YSxCzlqqF-9etA=w1624-h2318"
						alt="Representative screen of a Noah Basketball profile screen on an iPhone device."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="The coaches app"
					subtitle="Noah was built for coaching and shot development."
				>
					<p className="paragraph--small">With 30+ unique features for team management, the Noah platform keeps coaches, trainers, and assistants at the center of each player training regimen with a unique tablet-based experience.</p>

				</CaseStudySection>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWGkBXF7rydoq64g2txDFxuKvdhnLHtJ0O2X_8bg_3CgW2Izkyx5RUYWEqNNiKb7nbifNu2Fz3T66It56LieelwL6OhCTBXlau_GG8QDZLll6Jcw-P_Apon2zfWPNhAFIi-DJo3SjesO_ST5HASCWihUQ=w2276-h1116"
					alt="Tablet mock-up showing the Noah Basketball app experience for coaches on an iPad."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Manage teams big and small"
					subtitle="Create, update, or retire players and teams with just a few taps. Managing one, or multiple, teams has never been easier."
					reverse={true}
					image={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUj39NvadyPtY386jEa758VSvNgXfF-yJJfBjgqXsw0_6P4DZKrov-xq59CRGTo0DP4I2RZK0uK9lMv1bgre7IGtTGBpqBadtk9m2OzsUu9ZV9-pCEUG4VQF0LX_9pFfkm5SZhaRRrB-EDongptQnf2JQ=w1120-h809"
						alt="The Manage Teams screen of the Noah Basketball coaches app for iPad."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection title="Macro and micro analytics" subtitle="">
					<p className="paragraph--small">Track every shot from every player. Pause a session to review a shot, or compare sessions to see performance over time. Noahlytics™ and the Coaches app combine to give coaches the insights they need to develop their teams.</p>
				</CaseStudySection>

				<Spacer spacing={120} />

				<CaseStudyImage
					source="https://lh3.googleusercontent.com/pw/AM-JKLXAbjK0r8Oys_GcgeoAgxQ-BSREdcdTSIkGvWfZHb5opMWrCnq0BRxYsvFRF1HK4U1es-banljlmaLhroqTUK9EGbiVcWs3tpyUX_cJDswOybGS7wE06Vs7agNXg1w-VptodQfnoQPS_7Ue4WYbhZUNWA=w1840-h1328"
					alt="Sample image of the Noahlytics™ screen from the coaches iPad app."
				/>

				<Spacer spacing={180} />

				<CaseStudyResults
					statistics={[
						{ number: 22, description: 'NBA professional and G-League teams' },
						{ number: 163, description: 'NCAA Division 1 basketball teams' },
						{ number: '1,417', description: 'Middle and high school basketball programs' },
						{ number: '2.1B', description: '2,172,824,901 shots tracked to date' }
					]}
				/>

				<Spacer spacing={120} />

				<CaseStudyQuote
					content="Noah expanded our team's toolkit of viable shot options."
					person={{ name: 'Erik Spoelstra', title: '2x NBA champion head coach' }}
				/>

				<Spacer spacing={180} />

				<CaseStudyTestimonies
					testimonies={[
						{
							quote: `"Noah gives my players real-time feedback every time they shoot — it teaches them to focus on getting the right arc and a perfect, repeatable stroke."`,
							name: 'Tony Bennett',
							title: 'University of Virginia, Head Coach'
						},
						{
							quote: `"The Noah platform has completely changed the way that I think about approaching shooting, and how I teach my students and players to train."`,
							name: 'Anthony Toliver',
							title: 'NBA hall-of-famer and trainer'
						}
					]}
				/>

				<Spacer spacing={180} />

			</>
		);
	}
}
