import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as ProjectsData from '../helpers/ProjectsData';
import * as Calculate from '../helpers/Calculate';

export default class Navbar extends React.Component {
	constructor() {
		super();
		this.state = { caseStudy: '' };

		this.refNavbar = null;
		this.refBars = [null, null];
		this.refContainer = null;
		this.refLogo = null;
		this.refContent = null;
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isDesktop: PropTypes.bool.isRequired
	};

	componentDidMount() {}

	componentWillUnmount() {}

	onToggleNavbar = mouse => {
		if (!mouse.metaKey && !mouse.ctrlKey && !mouse.shiftKey && !mouse.altKey) {
			this.props.setTransitionPoint(mouse.clientX, mouse.clientY);
			this.props.onToggleNavbar();
			this.toggleMenuIcon(false);
		}
		// alert('ontogglenavbar');
	};

	toggleMenuIcon = isTriggeredManually => {
		const colorOrange = '#FC3617',
			colorWhite = '#FFFFFF';

		console.log('Menu Icon Toggled!!', isTriggeredManually);
		if (!this.props.isOpen) {
			console.log('Menu Icon: Normal -> Close Icon');
			let colorBar = isTriggeredManually ? colorOrange : colorWhite;

			if (!isTriggeredManually) {
				TweenMax.set(this.refLogo, { fill: colorWhite });
				TweenMax.set(this.refContainer, { borderTopColor: colorWhite });
				// TweenMax.to(this.refLogo, 0.6, { ease: 'Mullins', fill: colorWhite });
				// TweenMax.to(this.refContainer, 0.6, { ease: 'Mullins', borderTopColor: colorWhite });
			}

			TweenMax.to(this.refContent, 0.6, { ease: 'Mullins', opacity: 0 });

			console.log('Hide ref content');
			let oneRem = Calculate.getOneRem();
			TweenMax.to(this.refBars[0], 0.6, {
				ease: 'Mullins',
				rotation: -45,
				y: (6 * oneRem) / 16
				// backgroundColor: colorBar
			});

			TweenMax.to(this.refBars[1], 0.6, {
				ease: 'Mullins',
				rotation: 45,
				y: (-6 * oneRem) / 16
				// backgroundColor: colorBar
			});

			this.animateOffsetScroll(-Calculate.getScrollPositionY());
		} else {
			console.log('Menu Icon: Close Icon -> Normal Icon', isTriggeredManually);
			TweenMax.set(this.refLogo, { clearProps: 'fill' });
			TweenMax.set(this.refContainer, { clearProps: 'borderTopColor' });
			// TweenMax.to(this.refLogo, 0.6, { ease: 'Mullins', clearProps: 'fill' });
			// TweenMax.to(this.refContainer, 0.6, { ease: 'Mullins', clearProps: 'borderTopColor' });

			TweenMax.to(this.refContent, 0.6, { ease: 'Mullins', opacity: 1 });

			// TweenMax.set(this.refBars[0], { backgroundColor: '' });

			TweenMax.to(this.refBars[0], 0.6, {
				ease: 'Mullins',
				rotation: 0,
				y: 0,
				clearProps: 'backgroundColor'
			});

			TweenMax.to(this.refBars[1], 0.6, {
				ease: 'Mullins',
				rotation: 0,
				y: 0,
				clearProps: 'backgroundColor'
			});
		}
		console.log('-----------------------------------');
	};

	animateOffsetScroll(scrollY) {
		if (scrollY != 0) {
			console.log('NAVBAR ANIMATE', scrollY);
			TweenMax.set(this.refNavbar, { y: scrollY });
			TweenMax.to(this.refNavbar, 0.4, { ease: 'Mullins', y: 0 });
		}
	}

	onEnterHome = mouse => {
		if (!mouse.metaKey && !mouse.ctrlKey && !mouse.shiftKey && !mouse.altKey) {
			if (this.props.isOpen) {
				this.onToggleNavbar(mouse);
			} else {
				this.props.navigate(this.props.location.pathname, '/');
			}
		}
	};

	setNavbarColor = project => {
		// console.log('SET NAVBAR COLOR');
		this.setState({ caseStudy: project });
	};

	render() {
		return (
			<nav
				ref={nav => (this.refNavbar = nav)}
				// className={'navbar' + (this.state.caseStudy ? ` caseStudy--${this.state.caseStudy}` : '')}
				className={
					'navbar' +
					(this.state.caseStudy
						? ` caseStudy--${this.props.location.pathname.toLowerCase().replace('/', '')}`
						: '')
				}
			>
				<div ref={div => (this.refContainer = div)} className="navbar__container container">
					<Link to="/">
						<div className="navbar__logo" onClick={this.onEnterHome}>
							<svg
								ref={img => (this.refLogo = img)}
								className="navbar__logo__img"
								xmlns="http://www.w3.org/2000/svg"
								width="48"
								height="36"
								viewBox="0 0 48 36"
							>
								<path
									d="M24.3808748,61.1276335 L24.3803874,48.8728565 C24.3803874,42.3155019 29.6677707,37 36.18995,37 C42.7126167,37 48,42.3155019 48,48.8728565 L48,73 L36.5713123,73 L36.5713123,48.8728565 C36.5713123,48.6610598 36.4006257,48.4894654 36.1904374,48.4894654 C35.9802492,48.4894654 35.8090752,48.6605698 35.8090752,48.8728565 L35.8090752,61.1276335 C35.8090752,67.6844981 30.5226675,73 24,73 C17.4778214,73 12.1904382,67.6844981 12.1904382,61.1276335 L12.1904382,48.8728565 C12.1904382,48.6610598 12.020239,48.4894654 11.8095626,48.4894654 C11.5993743,48.4894654 11.4286877,48.6605698 11.4286877,48.8728565 L11.4286877,73 L0,73 L0,48.8728565 C0,42.3155019 5.28738327,37 11.8095626,37 C18.3317419,37 23.6191252,42.3155019 23.6191252,48.8728565 L23.6191252,61.1276335 C23.6191252,61.3389395 23.7898118,61.5110238 24,61.5110238 C24.2101882,61.5110238 24.3808748,61.3389395 24.3808748,61.1276335 Z"
									transform="translate(0 -37)"
								/>
							</svg>

							{/* <img src="/assets/mullins-logo.svg" /> */}
						</div>
					</Link>
					<div className="navbar__content">
						<p
							ref={p => (this.refContent = p)}
							className="navbar__content__mission paragraph--small"
						>
							{!this.context.isMobile &&
								'Unraveling complex business problems through the power of design'}
						</p>
					</div>
					<div
						className={
							'navbar__icon' +
							(ProjectsData.isProjectRoute(this.props.location.pathname.toLowerCase())
								? ' navbar__icon--custom'
								: '')
						}
					>
						{ProjectsData.isProjectRoute(this.props.location.pathname) && (
							<Link to="/">
								<div className="navbar__icon__link" onClick={this.onToggleNavbar} />
							</Link>
						)}
						<div className="navbar__icon__container" onClick={this.onToggleNavbar}>
							<span
								ref={span => (this.refBars[0] = span)}
								className="navbar__icon__container__line"
							/>
							<span
								ref={span => (this.refBars[1] = span)}
								className="navbar__icon__container__line"
							/>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}
