import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Uberscore extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Sports fans love watching their teams compete live, but attending every game is expensive. Worse still, not every team has the tech to broadcast real-time boxscores. If you’re not at the game, you could be minutes (or hours) behind the action. Uberscore’s mission is to bridge this gap with an app that brings some of the in-the-moment magic to your phone, while helping advertisers put their brands within thumbs-reach of diehard fans.'
					}}
					details={[
						{ title: 'Year', content: 2013 },
						{ title: 'Client', content: 'Red Sock Ventures' },
						{ title: 'Role', content: 'Product Strategy, Naming, Branding, Research, Prototyping, Content Strategy, Mobile App Design, Web App Design' }
					]}
				/>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX-JYqY_odVJn5atTaLjuXI1LQJR3iQ4BFNqH5QJVbkJ6luX_9WnDvCr_Eq-Yt8gid-NRUvBoSzakDFtYCpWEtHgFX_HY1v_SCe5WJWPU2lQ3ihz8u2A-1rSGd9uYQWTpjqmqi6yvI4Q6bVVgmS4msF3Q=w1600-h1311"
					alt="Collage of Uberscore app screens."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="We set out to bring something new to sports tech and we succeeded."
					person={{ name: 'Jeff Rowley', title: 'Co-founder of Red Sock Ventures' }}
				/>

				<Spacer spacing={180} />
				
				<CaseStudySection title="Naming and brand identity" subtitle="" reverse={false}>
					<p className="paragraph--small">
						The founders placed a ton of trust in me to get their concept right. We started with the name and after a few days of research, I narrowed into two buckets of concepts: names that honored the culture of sports (ex: “Popfly”), and names that spoke to the spirit of where technology was moving at the time. Uberscore was a name that leveraged the equity of Uber’s growth in the market, and brought it to home plate with the addition of the word 'score'.</p>
					<p className="paragraph--small">The name was a hit with the founders, and we needed an identity just as bold. The founders were both huge college sports fans, both German, and both aspired to have a brand that felt premium. Using a black and gold palette was a quick winner, as was the use of a serif for the primary font. For the logo symbol, a rectangle with an ümlat punched out of the negative space created a crown.</p>
				</CaseStudySection>

				<Spacer spacing={60} />
				
				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW1ryZqpUjwe50XXaz3xVRjxxTF9iBxfaC_eJviH8lmc8Y3XHcqWQcCDqqblsAqnKApYh-R3lfDj6RslRfyqIXYcLEUGRqYcianYCk-Pj5Lt4T3nOyd0wmZBzUL37rS_dojYv3nGPh0tXYrUsRh53RtpQ=w2020-h2608"
					alt="Visual design assets representing the naming and brand system for Uberscore."
				/>

				<Spacer spacing={180} />

				<CaseStudySection title="Planning for first customer traffic" subtitle="It takes a lot of whiteboarding, sketching, and stickies to get to an MVP that attracts early adopters." reverse={false}>
					<p className="paragraph--small">Of the hundreds of feature ideas, less than 10 of them made the MVP cut. To get there, we created a series of three prototypes to test out different feature pairs. After testing with a dozen sports fans in the area, the most important user needs emerged: finding and following teams and rivals, viewing boxscores, and social features like content sharing. Interviewing and rapid user testing helped uncover the most simplified experience to grow adoption.</p>
				</CaseStudySection>

				<Spacer spacing={60} />
				
				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWJctnbxRFd31BSbF_5GtWq8aozdG4ZdlqyQkD7jQrhfhhxdAVlDgRAppd3R6NA9qXUbdagUxlRh5ErKDYDT7QJtt_vNVhO2TU9RPAWgsBQml3yDkeluejHIFpO54aYtAPLt5SnMqNT-fJl6S0wZN7c6Q=w2320-h1424"
					alt="Low-fidelity black and white wireframes and user flow diagrams for Uberscore."
				/>

				<Spacer spacing={180} />
				
				<CaseStudySection title="Search for favorites and rivals near you" subtitle="" reverse={false}>
					<p className="paragraph--small">Uberscore helps users follow the sports they love, find their favorite teams, and monitor top rivals. Once their content network is created, users can comment on related games, or post shots from the stadium to their social accounts. For advertisers, geolocation services allow them to attach their campaign art to the bottom of each shared post, helping spread their brand to a hyper-localized community of sports fans.</p>
				</CaseStudySection>

				<Spacer spacing={60} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU32FMuGPyjzwTEmbzxeJUVguuwNIvqPtPI8Dll56ON4HBQIXgjd05ssOIqOm7vyFzwJbIetP9N4e33OXw1WUe9j1U7VtbVuDEGUu1AXbzEqBqOWUhYTX05qHTpOsg3B1XjyNSy9Ctf030TgI0jt5Gv6w=w1840-h1164"
					alt="Image of a map-based search feature for Uberscore."
				/>
				
				<Spacer spacing={180} />

				<CaseStudySection
					title="Find and help build your community"
					subtitle="Many users said they wished they knew more about their team’s history and stadium, which was a new insight for the team. We saw this as a clear opportunity to give users what they want by bringing content in-app that was readily available from other services. <br /><br />Each team has an overview page with facts about their stadium, a live view of game shots, and an archive of content from previous seasons."
					image={true}
					reverse={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUVNDDsTYYHY-v-IL5x5nPdA79zadzpS6nwlG1ENq6aWY8z1e3O2JBgzTfeTs4m04vgO7fAFsJFISTe1ND6kFqc_ThH5h_tXtqWJ6lNsYBJMkaYkKZbs68Dr6FpMuy-xWwsM_scAW-4rGf5ES46gJja5Q=w1268-h1120"
						alt="Image of stadium overview and social sharing feature for Uberscore"
					/>
				</CaseStudySection>
				
				<Spacer spacing={180} />

				<CaseStudySection
					title="Track boxscores that matter to you"
					subtitle="One thing that almost every tester agreed with was that a good sports app needs to have boxscores. So we tapped into the ESPN Scores API to get national boxscores get served up next to user-generated content, which expanded ESPN’s content reach, and gave fans real-time scores for their favorite local teams."
					image={true}
					reverse={false}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUjCaWahL7iamd1juXMSJUAYBCMiVuvllJpJFe_jQzyY-QS-Py6ODUUX4frSb7r1aFiuHJsEBhvJBFwAGUXUjSL-qlUHZJ93HCb2xkQIizYFGR-cc6UGYk4GoYUCe0iz1t7_kMrTh1eJYPk0lQVtNu2CQ=w1268-h1398"
						alt="Image of Home screen and Boxscores view for Uberscore"
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Get uber cred when you share"
					subtitle="They’ll miss the popcorn, hot dogs, and long bathroom lines, but fans who can’t make it to the game can get a view of the live scoreboard, comment on games, and re-share content from fans in attendance.<br/><br/>For fans in attendance, they can do all of the same, while boosting their profile stats through content creation. Advertisers piggyback on creators by running sport-wide or geography-based campaigns that are attached as a graphical ad to the bottom of all content shared out from Uberscore."
					image={true}
					reverse={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUK7ZuYSiPB8RCP7Ek4D4gn3dNI12tZZtsnPFLw5KkwqsciXA8NLsH85UROBWGZcCPaKap4nF2U1m9NxsWMnaxUNoHVwornw0cyZcD2gnGW5lAcW_8TrQyp4WjB-4fIGdXCXNVZrgUrSgsPa2LPuAbWng=w1122-h1210"
						alt="Image of social sharing features for Uberscore"
					/>
				</CaseStudySection>
				
				<Spacer spacing={180} />

				<CaseStudySection
					title="An insights tool built for growth"
					subtitle="Advertising is of little value if you can’t track its impact."
					reverse={false}
				>
					<p className="paragraph--small">
						If Uberscore was going to provide massive value to advertisers, we needed a dashboard that could show it to them. But we also had a shoestring budget to name and brand this thing, design the app, and pull off a dashboard. To make it happen, we architected a small workflow for advertisers to customize and upload a proprietary XLS file, to which we could map system data to once users interacted with content related to their campaign. We created a master dashboard for aggregating campaign insights, and rounded out the effort with a UI kit that could be referenced for future feature enhancements.</p>
				</CaseStudySection>

				<Spacer spacing={60} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUBGd3Jmq2gAE5HM6ie202W2l6fc1XWRuK_FzfaVJyFvjQDORB9alrPW7FtQrbqIrlRISLnJvHNyMPYZ1TflVuM2kUVJEtJ_NXqKv1VnZswMxeCnfnmAYBhlAC-IIA2HSSFw8NKNJuIIUShX5NsuYDBSQ=w2076-h1698"
					alt="Image of social sharing features for Uberscore."
				/>

				<Spacer spacing={180} />				

				<CaseStudyResults
					statistics={[
						{ number: '17m+', description: '17,000,000+ fan interactions recorded in under 2 years' },
						{ number: '250k+', description: 'Unique users added in first year across 7 state market penetration' },
						{ number: '$2.8m', description: 'Total ad sales generated in first 4 quarters of trading' }
					]}
				/>

				<p className="caseStudy__reflection paragraph--small">Technology may never be able to truly recreate the magic of the in-stadium experience, but Uberscore users loved that the app brought them closer to other fans, and the advertisers loved how hyper-targeted the platform could be for their campaigns.</p>
				<p className="caseStudy__reflection paragraph--small">As a sports lover myself, I enjoyed being a part of this ambitious team using sports tech to explore emergent opportunities between offline and online sports experiences. I was excited to cut my teeth on industrial design in the next phase of work, but the founders ended up selling the business to a large media company in 2015.</p>

				<Spacer spacing={180} />

			</>
		);
	}
}
