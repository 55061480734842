import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default class CaseStudySuggestions extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	onProjectNavigate = (mouse, route) => {
		if (!mouse.metaKey && !mouse.ctrlKey && !mouse.shiftKey && !mouse.altKey) {
			console.log('ROUTE FROM TO ', this.props.currentProject, route);
			this.props.navigate(this.props.currentProject.route, route);
		}
	};

	Suggestion = ({ name, description, route }) => (
		<div className="suggestion">
			<h3 className="suggestion__title">
				<Link to={route} onClick={e => this.onProjectNavigate(e, route)}>
					{name}
				</Link>
			</h3>
			<p className="suggestion__description paragraph--small">{description}</p>
			<h6 className="suggestion__cta">
				<Link to={route} onClick={e => this.onProjectNavigate(e, route)}>
					View Project
				</Link>
			</h6>
		</div>
	);

	render() {
		console.log('SUGGESTIONS', this.props.suggestions);
		const { Suggestion } = this;
		return (
			<div className="caseStudy__suggestions">
				{this.props.suggestions.map((suggestion, index) => (
					<Suggestion
						name={suggestion.name}
						description={suggestion.description}
						route={suggestion.route}
					/>
				))}
			</div>
		);
	}
}
