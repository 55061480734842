import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class TenFastFeet extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'Started as a small SEO/SEM shop in 2010, as myself as two other partners joined the company, we grew the agency in four short years from 5 to 30+ employees, and grew agency revenues from ~$200k a year to over $6.2M. At Ten Fast Feet, I led design, research, content strategy, product strategy, agency operations, account management, janitorial services, and plenty of other roles. In 2014, we closed the agency, sold our book of business, and rolled our momentum into creating a tech accelerator for our own ideas: Canary Venture Co.' 
					}}
					details={[
						{ title: 'Year', content: '2011-2014' },
						{ title: 'Client', content: 'Ten Fast Feet' },
						{ title: 'Role', content: 'Naming, Branding, Design, Strategy' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVjFf4IWicmBWbtxTGNZ3zV1RFl93Ob8mpKzVhp-svuSsFV83ZGaML-OIm6W4--gzyqZK6UVrdauObcTNT67caWXPHZbDMbtZxyj8g34rXecwEjhq1ZzB-xKPqf6_DjtY30viMy6mOi5fPGROBF27sKDA=w1200-h403-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVh5vxfhmpYXaC1BUXdbg_GjvkvxnuNWHT-X_1qPkC1cmgjNnRaQJtC-FxqxmB7rCNTF8dsU6KgysDhM8P2cm-znCf_GpbCxhpMFBywRc4NIUR8uool2O9dv0X4HRIRIMqnzW2psgf2tJ4vG-4sneWVWA=w1200-h500-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW4BqekAV_88nTKAvGv_URrGdDbSJLWACZhJQN2QZij60_uq6-I1ApEqLHo5yjZaWH_qQu_8mPa7uNuj_wu1JoDhoSjuEOc7SmIEfvQ7gJZlqlrBthP5q098mOspQdezTK40yG84MMuahzXzn9VDN_GEQ=w1200-h384-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUQ7i3GjEO09Mzz-SSsHO7DXCOEdoT7LtViq_bLqF-_cqUNU21ulY0wLQuO9H0VEr9jt2ODdBh3AkVULCpVTS6YBzWvw5_QoxV49ChYjuGeWp_kg_uZaAmsXVtwKVa7vPsymMKa7lxmduuUuHoS5gdIeQ=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXg44nCc2GwrnbtIomtgbDw9qXFBadABJYP4z0Ld6rW5IfBq3YI5kuuX12JRvh5uk4k56ZfgiQlq086vyjnrwnWjOmIvmUMJv1qaS848-MpxOit46mmgBnHgAUoSUPdjaJhxS6MlfzM7tfVXV5C0oORAA=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVLqClFKA7Z_FvTQjIjrqEa6Ciasai1FebiBOd3YNyxanizCiPQ3ah-s7ZwTj5SS77ctNyTYhG2Jld1SVttnztfz9Fsy_pD6xY1uQF4zWwrOm-kyxMc3cY5DZJ2dpH_rkPyHAWMuDk22G9KkzoqQV0GWQ=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVR_d5pQluRtufSchuJIaU6EylXhRiGh4lJB_W2sbWyE3jcxT_IoXnFpW13-SFEPRDMUzfs9NVRdSS1fe0KIrWB6uPVB6FIuqIPuBkWozihrU0X7HhOyKoOzkbL2CSsi7i7q_WVzFxXhkYvFVKaQVPQTQ=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXJdfKSc44mYlAdU-L_CwB6yFgLZf8xOlRLBu94SXZgp1Fh65bEMQolYiRqMTiBR_bxTthGa70F4dFwlbE3Klnf7D5GN8R4eirtEMPpSmbewsOSGoeghmS9t2ss65MXG4XWtQKXLsGgZERYAYxTBfyqJg=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUG7K9QF3YcsXzhtLrifhIY9mbztbimscQ6D560JPGcck7fsa5tjXuDWam95K27Grjue0wkHOLbG1u6gboZoO6QW6c_0jzJA5fmN3cQygt7byjsQz7iL-0UwmTNYGQEzGdTv-nS1dLCwsnvllvdYqsxrQ=w1200-h687-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXRT0PmU_yTxxJ9QviFi-jg9x8_sCXOaf18pat5D1WDoBqjX6IHLRVwSeSPnA6hy14Zni0QI9b3LH5BUfo8GpwoaWmtQ0LDSbZcGh0eCX1q22HUjh_ZW5yfBPq2jez22hOuRBlF28QfaHVlJimhbVjRRQ=w1200-h675-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWKvwg-e-HQR1RZm0bvPU-k0UG-pSu1uK0_U7k09QzFZbdU-ztK5wpymv838slX1Nl_-JFBcziEBoD3mZLNROWDPPTg97UhkXJquHRFC6l63R4SVspiFmBuuggA84iD44SzEEwuK4A_9nYhBnY0qPTptw=w1200-h715-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXaoXxFLUJYr6AYpvR9nEF1mmGxt-sQIW4bg5Fyki-UG4GwVMYrsu6pSmtoSlzE_t6H_loQp-i-dayQ0x5dRpiRnmdu4Xwam8Kr1Mih1akTy19fyD2fNheAz2JHCgaiUw-3J5OIGSQNoiHeMcpViUZzAg=w1200-h750-no?authuser=0"
					alt="Ten Fast Feet sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

			</>
		);
	}
}