export const getOneRem = () => {
	return parseFloat(window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize);
};

export const getDistanceToFarthestCorner = (mouseX, mouseY) => {
	let screenWidth = 1680,
		screenHeight = 945;
	let distanceX = 0,
		distanceY = 0;

	if (mouseX <= screenWidth / 2) distanceX = screenWidth - mouseX;
	else distanceX = mouseX;

	if (mouseY <= screenHeight / 2) distanceY = screenHeight - mouseY;
	else distanceY = mouseY;

	return Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
};

export const getScrollPositionY = () => {
	return window.pageYOffset || document.documentElement.scrollTop;
};
