import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Built extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'From startup to $180M+ in funding, I named, branded, and designed the foundations for a new way to manage construction loans. Built was founded in 2014 with a mission to simplify the construction lending experience. Our vision was to create the new industry standard for construction lending software, streamlining the loan management process, and providing unprecedented portfolio insights, forever changing the way the world gets built.'
					}}
					details={[
						{ title: 'Year', content: '2015' },
						{ title: 'Client', content: 'Built Technologies' },
						{ title: 'Role', content: 'Naming, Branding, Research, Product Strategy, Mobile App Design, Web App Design' }
					]}
				/>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXnAlDsKt9uO9kBJoKuXWd11aBMIBdYauSpMveMpgDuF-GcgsqVYwoQtUUPDmJbmIfkoH4xs1K2YniWXDN-dh7xJqvQc7F9JYLY4Va5_MT1nmChgBrcJTqk30aZpSQ_4S3-cwxbjmRLWCAjdqshKpc2bg=w1176-h538"
					alt="Photograph of the Built founding team in a code review meeting."
				/>

				<Spacer spacing={180} />				
				
				<CaseStudySection
					title="Naming and brand identity"
					subtitle="From ancient figures like Arazu (a mythological god of finished construction) to names with more modern appeal, I had a lot of fun curating concepts, but when I pitched 'Built' to the team it stuck right away.<br/><br/>For the logotype, I took a structuralist approach, referencing Herbert Bayer’s 'universal' typography principles. The logotype relies on interchangeable geometric parts to produce a final rational solution, like many philosophies in construction."
					
					image={true}
					reverse={false}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLU7cEz5KiHDS0kSOMmfjC4ORq7tx8_15-k-coaMdXTHPW87xBTKZVQG12G0mvnB2JqGwr5PBrS23JZs0BqBETsf2ys0O2dwCG8s2zJ5URpDIeHkNJ7lvHkcNb2fK2qMBrinYgKcGjSw-2FqDBsTn-0PXA=w904-h544"
						alt="One-color version of Built logo displayed on a faint grid."
					/>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX1OUt6uSIc6jxPO5JeR5mzilFbOU31FqTdM7SHgSLNEgfEHPrxNAPqrjRCAMuPeFTDNeewgW7xtCKdffrCwJDbJjcFX0GyX0mYptnEPIJol-T2-cofxtpKa3IWUGE2lhD0aLTf_XLfYOZTJSHvz4a5ZA=w1577-h500"
					alt="Diagram of logotype construction for Built logo."
				/>

				<Spacer spacing={180} />

				<CaseStudySection title="Why does Built exist?" subtitle="" reverse={false}>
					<p className="paragraph--small">The financial recession of 2008 had a lot to do with housing. Long story short, homebuyers were buying houses and later defaulting on their mortgages, while builders and bankers were off building more new homes. After a deep trough of new starts through 2009, the market started to normalize to mean, but problems persisted.</p>
					<p className="paragraph--small">Perceived as risky, banks were now more cautious than ever about deploying capital into construction lending, but needed to do so to stay competitive in a space where 89% of lending institutions were still investing. Meanwhile, borrower trust in banks had worn thin, and no one was focused on improving the borrower experience.</p>
					
				</CaseStudySection>

				<Spacer spacing={60} />
					
				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWChSbL4N2ey9MIF9myYuJtThyvTf-2NpuWboLPxBu4bSmTgzNyUDOxq2IJ2TdwKm66Xpah8y7l7xaO5a-IUvFaKv8GZyLzbXDS5FoOhqZ0aBX8yCbjhPfFwsEjgQGKaoEgrqJyA6rLuy7jhZhvtzwhWQ=w2216-h586"
					alt="Diagram comparing and contrasting goals between the builder and buyer in a construction lending relationship."
				/>

				<Spacer spacing={180} />

				<CaseStudySection title="How does the platform work?" subtitle="" reverse={false}>

					<p className="paragraph--small">The Built platform puts the trust back in the construction lending relationship through transparency and efficiency. For borrowers, Built offers rapid, accurate assessment of their borrower capacity and quality through a highly differentiated borrowing experience.</p>
					<p className="paragraph--small">For banks, the platform is a streamlined tool for allocating capital into a construction lending portfolio, and uses technology and expert human capital to provide macro- and micro-level market intelligence for assessing borrower creditworthiness and risk.</p>
					<p className="paragraph--small">Everyone has a place in the Built ecosystem: title companies, inspectors, and developers alike each have an important role that they play in maintaining a healthy, thriving construction lending platform.</p>

				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW42D1ypSG0WlcRBcxGc_cGLYDExaLbs2oJx-c8zA4mGIv6mBEZ4hmxO6Ucd3K9r_v46Qa1lygwQ-k2vGdAydzecqx3ze1DHsbpkG2FQ9rOxV4SZjh8oG-tNLthOrdLvzinAlYiLpPvhkQSKdamFMduHA=w1712-h934"
					alt="Diagram showing the various stakeholders in a networked construction lending platform."
				/>

				<Spacer spacing={180} />

				<CaseStudySection title="Getting to first customer traffic" subtitle="" reverse={false}>

					<p className="paragraph--small">To capture first-mover advantage, we needed to act fast. With an untested idea and a product that didn’t yet exist, our first steps had to be precise. The <strong>jobs-to-be-done framework</strong> is my favorite tool for situations of uncertainty, because Jobs Theory transcends the making of superficial assumptions, and instead exposes the functional, social, and emotional dimensions that explain why people make the choices they do by talking directly to the end-user about their goals.
</p>
					<p className="paragraph--small">I kicked things off by setting up an interview tour with 28 bankers, title companies, builders, and inspectors to find out who they were, how they currently accomplished their jobs, and how Built’s platform could help them manage their construction loan portfolio. By the end of our research, we had a map of jobs-to-be-done, and began looking at patterns between user segments to start envisioning our MVP. This early research also helped fill gaps in our first user personas.</p>

				<br/><br/><br/>
				
				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWO6CpiW529ewyt8gPjDOXQ9bihAEq75rrOV8xK_g6bApYx5h9pVyl2ZhhZXdT3sjJLe_swNd2187NnEj3RoikN9OSgShqkTMHMtMlCscW0pvso8uAlXLUv9Ydfc_2mTUUATjSgRslgZXEnr045cphLOA=w1130-h412"
					alt="Diagram showing shared goals between Built's various user types."
				/>

				<br/><br/><br/>
				
				<p className="paragraph--small">Then something interesting happened. Almost all of these people, who were supposedly at arms-length with one another, were in total agreement on one concept: if Built could nail the Loan Origination process (aka, applying for a new construction loan), we would solve the most frustrating problem in construction loan management: paperwork. And that would be enough to start earning their trust.</p>
					<p className="paragraph--small">Now that we had a clear direction to head in, we immediately went back to our users. We picked 3 people from our core user segments (Builders, Banks) and began prototyping new UX concepts with them. By the time we arrived at a design-ready UX, we were outpacing the traditional loan origination process by a factor of 500x. What once took weeks, now took a few hours — sometimes minutes! We locked on a UX strategy, and started fleshing out the UI design.</p>

				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUoN9QZZE3YPsFlpS5ec5S8RT3-lPC1H0qr-O352P6cBKJZ4cH9_iWHog02Yk24gxWJXQZqd1Nn0yFAUgZ8NwPpPnwV10XNFMHiBPHzeDHNtFcycPDFMO3w_7UVHXfigNe88DEMMucB7Szu-jLILd3-EQ=w1854-h452"
					alt="Diagram highlighting the efficiency gains of the Built platform versus the traditional lending process."
				/>

				<Spacer spacing={180} />
				
				<CaseStudySection
					title="The design system"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">With the UX defined, it was time to start on the visual layer. When building design systems for large applications, I insist on following the principles of atomic design. In addition to front-end efficiency, having an atomic design system helps individual UI patterns scale into a cohesive, learnable UX for the end-user.</p>

				</CaseStudySection>

				<Spacer spacing={60} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUL_8UNq28_IN9fpAu00Mnbo4y6IoT2YuC_DY5r6fp5fj780Cfw95wJrI1FVo7e__JjpbkqyTJ9ZuyOf14sbmfV1xPTALIYVldtd3LQQo_KC8MJi0AbCgOpTDAk7bLrTU8lRhzi8_lfbLeyqJCvjpW2fg=w1100-h890"
					alt="Sample image showing various components from the Built platform's user interface library."
				/>

				<Spacer spacing={180} />				

				<CaseStudySection
					title="Scalable experiences"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">Users of a design system benefit from a consistent UI across all of their repeatable tasks, enabling a more learning-friendly interface. The full Origination product was built responsively, and adapts gracefully across any device; desktop, smartphone, or otherwise.</p>

				</CaseStudySection>

				<Spacer spacing={120} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU6PGnGuODNuRIs-H-Ymgol_u0Gj2c89PbMUYDCWEgHR9ZdMJE98f9_h2khVoHO89SSUSNfYWdRrOHZEXt2ApayRNubZhqzaAZ1Hjx83lQrEfxBEvMZKzdflYSOckIlXswpBmfSDDgFnRs6QVtf9xoLJg=w2228-h2608"
					alt="Various representative design screens from the Built platform."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Brand system"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">The preceeding assets were built on top of brand design work that was established early on in the venture. Most of the artifacts below remain in place today, helping guide the Built brand into the future. We ultimately ended up with two icon systems, one for in-product applications where speed-to-understanding is the most important, and a separate set of market-facing icons that touched on the more colorful and fun side of things to help round out the brand system.</p>
				</CaseStudySection>

				<Spacer spacing={180} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUWCXLYiE8Lky9mGUe84V1_8sI6ioDuu11bhEdy88hUs0FK1Jc1b6f0oNLQ9iYtq02zD-EtniIg1aWiY-2iqMnudVTQTJ4reU6kkif2eC2fndt20vvfvpufAQ1FMHvR8976A1OTEYnC7OZaN-hC2x5GOw=w2216-h2048"
					alt="Sample images of fonts, colors, andd apparel from the Built brand system."
				/>
				
				<Spacer spacing={180} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVsVC5srp4yykArs9XWzJzmP5UvgtnQzvBrdMegiKqyhBItEdYOMKQrgvuEsySoQ_RvZtEt1R1ymMZrdqR3rsKke0DRooxvGfHi_tyQTCR-yW9K_uieP017UJHcre8_D55-Vg7wP6lxaOqMZqurRoVwsw=w1250-h754"
					alt="Photograph of a hand drawing icons on an iPad Pro."
				/>

				<Spacer spacing={120} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWTT8irlO6FvD6KPGACxqg0a3qDZRfEk0K5CCChpneDa94k7ZSXJu41m7qjhPWYwwjJBWRKiCRcQhHqmdOVgxTddg62Ficl-HqUC-EL2FSPYx63c2qMUZ3SIGR9rlm1mH8ehMEDkPgt173KZ4ckcMOo5Q=w2000-h862"
					alt="Ten sample icons from the Built iconography system."
				/>

				<Spacer spacing={180} />								

				<CaseStudySection title="Marketing website" subtitle="" reverse={false}>
					<p className="paragraph--small">Last, but not least, the marketing website, affectionately known as the ‘dotcom’ asset. Featuring case studies, executive team bios, blog content, and a tool to help search for lenders, the new site helped catapult the brand into the market, and capture new leads.</p>
				</CaseStudySection>

				<Spacer spacing={100} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUaY2BtaA1iyf0IUMR4CSdjyIO8_ARw2lUPU3I0p2QAITOBI1AK2VI-H6lpLKEuXETPR5uk5UWOMNKNO_UnSvmjyk6yYst50H-hS6CFfGI2IPGJ19zkhAYqOjpNe-8_5tTCJWl3NaaFL6p4i5RQArnSQw=w1600-h2169"
					alt="Various design screens from the GetBuilt.com marketing website."
				/>

				<Spacer spacing={180} />

				<CaseStudyResults
					statistics={[
						{ number: '$1B+', description: 'Over $1B new start construction loans funded in first year of business.' },
						{ number: '$23M', description: 'Secured $23M in Series A funding in 2017 from Index Ventures.'},
						{ number: '130k', description: 'Processed 130,000+ new residential construction loans in first year.'},
					]}
				/>

				<p className="caseStudy__reflection paragraph--small">Winner of Housing Wire 100 award for 2018. FinXTech 2018 finalist: Digital Banking Judge’s Choice. AIGA Tenn Show best in branding 2015. Raised $21M in funding circa 2017, another $55M in 2019, and $88M in February 2021.</p>
				
				<Spacer spacing={180} />

			</>
		);
	}
}