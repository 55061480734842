import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import * as ProjectsData from '../helpers/ProjectsData';

export default class Footer extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.refFooter = null;
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isDesktop: PropTypes.bool.isRequired
	};

	componentDidMount() {}

	componentWillUnmount() {}

	render() {
		return (
			<footer
				ref={footer => (this.refFooter = footer)}
				className={'footer container' + (this.props.light ? ' footer--light' : '')}
			>
				<h6 className="footer__subtitle container__subtitle">&copy;2021</h6>
				<div className="footer__content container__content">
					{this.context.isDesktop && (
						<p className="footer__text paragraph--small" style={{ opacity: 0 }} />
					)}
					{!this.context.isMobile && (
						<p className="footer__text paragraph--small">All rights reserved.</p>
					)}
					<p className="footer__text paragraph--small">NeverNotWorking&trade;</p>
				</div>
			</footer>
		);
	}
}
