import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class CaseStudyQuote extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="caseStudy__quotes">
				<div className="caseStudy__quotes__quote caseStudy__quotes__quote--start">
					<div className="caseStudy__quotes__quote__mark">
						<svg xmlns="http://www.w3.org/2000/svg" width="39" height="30" viewBox="0 0 39 30">
							<path
								fill="#FC3617"
								d="M8.7 29.58c4.23 0 8.01-2.88 8.01-7.65 0-6.21-6.66-6.75-8.55-6.48.18-5.22 4.05-10.08 11.52-14.67C8.79 2.94.69 10.5.69 19.59c0 6.12 3.6 9.99 8.01 9.99zm19.17 0c4.23 0 8.1-2.97 8.1-7.65 0-6.21-6.66-6.75-8.55-6.48.09-5.13 4.14-10.08 11.43-14.67-10.8 2.16-18.99 9.72-18.99 18.81 0 6.12 3.6 9.99 8.01 9.99z"
							/>
						</svg>
					</div>
					{/* <img */}
					{/* 	className="caseStudy__quotes__quote__mark" */}
					{/* 	src="/assets/case-studies/quote-top.svg" */}
					{/* /> */}
				</div>
				<h1 className="caseStudy__quotes__content">{this.props.content}</h1>
				<p className="caseStudy__quotes__person paragraph--small">
					<span className="caseStudy__quotes__person__name">{this.props.person.name}</span>
					{this.props.person.title}
				</p>
				<div className="caseStudy__quotes__quote caseStudy__quotes__quote--end">
					<div className="caseStudy__quotes__quote__mark">
						<svg xmlns="http://www.w3.org/2000/svg" width="39" height="30" viewBox="0 0 39 30">
							<path
								fill="#FC3617"
								d="M19.86 29.76c10.89-2.16 18.99-9.72 18.99-18.72 0-6.12-3.6-10.08-8.01-10.08-4.23 0-8.1 2.97-8.1 7.65 0 6.48 6.57 6.57 8.55 6.57-.09 5.13-3.96 9.99-11.43 14.58zm-19.26 0c10.89-2.16 18.99-9.72 18.99-18.72 0-6.12-3.6-10.08-8.01-10.08-4.14 0-8.1 2.97-8.1 7.65 0 6.48 6.75 6.57 8.64 6.57-.18 5.13-4.05 9.99-11.52 14.58z"
							/>
						</svg>
					</div>
					{/* <img */}
					{/* 	className="caseStudy__quotes__quote__mark" */}
					{/* 	src="/assets/case-studies/quote-bottom.svg" */}
					{/* /> */}
				</div>
			</div>
		);
	}
}
