import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

import * as Calculate from '../helpers/Calculate';

export default class Project extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isiOSTouchDevice: false };
		this.linkRoute = '#';

		this.tags = '';
		for (let i = 0; i < this.props.tags.length; i++) {
			this.tags += this.props.tags[i] + ' / ';
		}
		if (this.tags.length) this.tags = this.tags.slice(0, -3);

		this.refProject = null;
		this.refActiveBar = null;
		this.refName = null;
		this.refTags = null;
		this.refDetailsOnMouseEnter = null;
		this.refDescription = null;
		this.refCTA = null;

		this.isProjectNavigating = false;

		this.animateHover = null;
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isDesktop: PropTypes.bool.isRequired
	};

	componentDidMount() {
		// console.log('USER AGENT', navigator.userAgent);
		// console.log('USER AGENT', navigator.platform.includes('iPhone') || navigator.platform.includes('iPad') || navigator.platform.includes('iPod') || navigator.platform.includes('iOS'));
		this.setState({
			isiOSTouchDevice:
				navigator.platform.includes('iPhone') ||
				navigator.platform.includes('iPad') ||
				navigator.platform.includes('iPod') ||
				navigator.platform.includes('iOS')
		});
	}

	componentWillUnmount() {}

	onProjectMouseEnter = () => {
		console.log('onMouseEnter', this.props.index);
		if (!this.isProjectNavigating) {
			this.animateHover = setTimeout(() => {
				TweenMax.to(this.refActiveBar, 0.385, { ease: 'Mullins', scaleX: 1 });
				TweenMax.to(this.refTags, 0.385, { ease: 'Mullins', opacity: 0 });
				TweenMax.to(this.refDescription, 0.385, { ease: 'Mullins', x: 0, opacity: 1 });
				TweenMax.to(this.refCTA, 0.385, { ease: 'Mullins', x: 0, opacity: 1 });
				this.props.updateProjectsOnMouse(this.props.index, true);
				// }, 150);
			}, 0);

			this.props.updateProjectsOnMouse(this.props.index, true);
		}
	};

	onProjectMouseLeave = () => {
		console.log('onMouseLeave', this.props.index);
		if (!this.isProjectNavigating) {
			if (this.animateHover) clearTimeout(this.animateHover);

			TweenMax.to(this.refActiveBar, 0.4, { ease: 'Mullins', scaleX: 0 });
			TweenMax.to(this.refTags, 0.4, { ease: 'Mullins', opacity: 1 });

			let offsetX = (40 / 16) * Calculate.getOneRem();
			TweenMax.to(this.refDescription, 0.4, { ease: 'Mullins', x: offsetX, opacity: 0 });
			TweenMax.to(this.refCTA, 0.4, { ease: 'Mullins', x: -offsetX, opacity: 0 });

			this.props.updateProjectsOnMouse(this.props.index, false);
		}
	};

	onOtherProjectMouse = onMouseEnter => {
		console.log('Other Project Hover', this.props.index);
		if (!this.isProjectNavigating) {
			if (this.animateHover) clearTimeout(this.animateHover);

			let colorName = onMouseEnter ? '#222222' : '#FFFFFF',
				colorTags = onMouseEnter ? '#222222' : '#666666';

			TweenMax.to(this.refName, 0.4, { ease: 'Mullins', color: colorName });
			TweenMax.to(this.refTags, 0.4, { ease: 'Mullins', color: colorTags });

			// FIX MOUSE SCRUB TO TRIGGER MULTIPLE ISSUE
			TweenMax.to(this.refActiveBar, 0.4, { ease: 'Mullins', scaleX: 0 });
			TweenMax.to(this.refTags, 0.4, { ease: 'Mullins', opacity: 1 });

			let offsetX = (40 / 16) * Calculate.getOneRem();
			TweenMax.to(this.refDescription, 0.4, { ease: 'Mullins', x: offsetX, opacity: 0 });
			TweenMax.to(this.refCTA, 0.4, { ease: 'Mullins', x: -offsetX, opacity: 0 });
		}
	};

	onProjectNavigation = mouse => {
		if (!mouse.metaKey && !mouse.ctrlKey && !mouse.shiftKey && !mouse.altKey) {
			console.log('ScrollPositionY', Calculate.getScrollPositionY());
			this.isProjectNavigating = true;
			this.props.updateRenderStates('caseStudy', true);
			this.props.setTransitionPoint(mouse.clientX, mouse.clientY);
			this.props.updateNavbarOnTransition();
			this.props.offsetScroll(-Calculate.getScrollPositionY());

			console.log('trigger point', mouse.clientX, mouse.clientY);

			setTimeout(() => {
				this.isProjectNavigating = false;
				this.onProjectMouseLeave();
				this.props.offsetScroll(0);
			}, 600);
		}
	};

	render() {
		return (
			<div ref={div => (this.refProject = div)} className="project">
				<div className="project__active">
					<div className="project__active__bar" ref={div => (this.refActiveBar = div)} />
				</div>
				<h1
					className="project__name"
					ref={h1 => (this.refName = h1)}
					onMouseEnter={!this.state.isiOSTouchDevice && this.onProjectMouseEnter}
					onMouseLeave={!this.state.isiOSTouchDevice && this.onProjectMouseLeave}
				>
					<Link
						to={this.props.route}
						className="project__details__name__link"
						onClick={this.onProjectNavigation}
						// onTouchStart={() => console.log('onTouchStart', this.props.index)}
						// onTouchEnd={() => console.log('onTouchEND', this.props.index)}
						// onTouchEnd={this.onProjectNavigation}
						// onTouchEnd={this.onProjectNavigation}
					>
						{this.props.name}
					</Link>
				</h1>
				<div className="project__details">
					<p className="project__details__tags" ref={p => (this.refTags = p)}>
						{this.tags}
					</p>
					<div
						className="project__details__active"
						ref={div => (this.refDetailsOnMouseEnter = div)}
						onMouseEnter={this.onProjectMouseEnter}
						onMouseLeave={this.onProjectMouseLeave}
					>
						<p
							className="project__details__description paragraph--small"
							ref={p => (this.refDescription = p)}
						>
							{this.props.description}
						</p>
						<p className="project__details__cta" ref={p => (this.refCTA = p)}>
							<Link
								to={this.props.route}
								className="project__details__cta__link"
								onClick={this.onProjectNavigation}
								// onTouchEnd={this.onProjectNavigation}
							>
								View Project
							</Link>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
