import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import Project from '../components/Project';
import Footer from '../components/Footer';

import * as Animate from '../helpers/Animate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class About extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired
	};

	componentDidMount() {
		// This will be ABOUT on enter
		this.props.pushHistory(this.props.location.pathname.toLowerCase());
	}

	componentWillUnmount() {
		// This will be whatever on ABOUT exit
		this.props.pushHistory(this.props.location.pathname.toLowerCase());
	}

	Client = ({ name, link }) => <p className="client paragraph--small">{name}</p>;

	Award = ({ award, link, details }) => (
		<p className="award paragraph--small">
			<span className="award__name">{award}</span>
			{'  '}
			{details}
		</p>
	);

	render() {
		const { Client, Award } = this;

		return (
			<div className="about">
				<div className="about__headshot" />
				<section className="about__about container container--content">
					<h6 className="about__about__subtitle container__subtitle">About</h6>
					<div className="about__about__content container__content">
						<h2 className="about__about__content__title">
							<span className="about__about__content__title__accent">
								NeverNotWorking&trade;
							</span>{' '}
							is a creative venture based in the heart of Atlanta. It’s a place I invented to
							do what I love: working with startups, businesses, and other creators, to realize
							their dreams through the power of design.
						</h2>
						<div className="about__about__content__details">
							<div className="about__about__content__details__column">
								<p className="about__about__content__details__column__text paragraph--small">
									For 2+ decades, I’ve worked internationally, in-house, and remotely
									for leading brands, agencies, and startups. I spent a decade as an
									individual contributor, followed by another decade of starting, building,
									and mentoring design teams. Recently, I led an international design, research,
									and content program at Expedia Group, and was responsible for the people, processes,
									and structures that helped our team craft first-in-class business travel experiences.
								</p>
								<p className="about__about__content__details__column__text paragraph--small">
									At Expedia, I led my team through a global rebrand, a multi-year
									re-platforming of our digital product, the launch of multiple
									new lines of business, and grew a small outfit of four into a 30+ member
									team distributed across America, France, and India. I interviewed
									thousands of designers and researchers, was a senior leadership team
									member, and I am most proud of helping generate career development for 100s of people.
								</p>
							</div>
							<div className="about__about__content__details__column">
								<p className="about__about__content__details__column__text paragraph--small">
									Previous to enterprise work, I co-owned a marketing agency that grew to
									$6.2M revenue in 4 years, before closing the books in 2014 to
									focus on our own ideas. Our first advertising startup was acquired in
									2014, follow by a second hit in construction lending with Built
									Technologies. In 2016, Built secured a $21M investment from Goldman Sachs
									and Index Ventures, propelling us to $20B in total loan volume by 2020.
								</p>
								<p className="about__about__content__details__column__text paragraph--small">
									Today, my experience helps Favrit.com leave its mark on the bar and restaurant
									industry through digital, contactless dining. I believe good design solves real problems, and that the labors of
									design must be measured and tested. I’m drawn to thinking critically about
									complex problems and then executing a series of experiments against a
									hypothesis. I provide a level of focus and energy around a given objective
									that’s often hard for a business to generate internally.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="about__clients container container--content">
					<h6 className="about__clients__subtitle container__subtitle">Clients</h6>
					<div className="about__clients__content container__content">
						{ProjectsData.clients.map((client, index) => (
							<Client name={client.name} link={client.link} />
						))}
					</div>
				</section>
				<section className="about__awards container container--content">
					<h6 className="about__awards__subtitle container__subtitle">Awards</h6>
					<div className="about__awards__content container__content">
						{ProjectsData.awards.map((award, index) => (
							<Award award={award.award} link={award.link} details={award.details} />
						))}
					</div>
				</section>
				<Footer />
			</div>
		);
	}
}
