import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class ArtSchool extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'Abbreviated portfolio of art school projects from my undergraduate degree at the VCUarts program of Virgina Commonwealth University.'
					}}
					details={[
						{ title: 'Year', content: '2000-2005' },
						{ title: 'Role', content: 'Student' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW4h1Yjs_LLVt8R_hPG4PbF-I_IS4ZoAKAVcpyae32bzCzzlReR93i_FGj90uSWxtL2Mbq2HZc8j1GO6TjjEaNaxroIPMy-xLY27hKYC0ITb34liMVWUDBn9BEEJSUMPH6wz5zJAPYomFdbg4s_oh49YA=w2560-h1764-no?authuser=0"
					alt="Figure study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV6Kl2g3hpxs3Fd6Hx6tgND2QIPp4bak5NcqZUqbno5EucslETUVDbA4XRnLH3Oletk_4Ie_bQ6hJEQO3b-rfutlnvmY9bRMCrMs6CU4FPmTuPMw4QjlS6SOoW_PxqMMQrv70R1fSq5HwQUzSZDOPoaqg=w2560-h1708-no?authuser=0"
					alt="Album artwork student work sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVEXvyxQEt06EFZpVhJoWbKKB3ZOWMV9yiG6HQrptqQJ8-jl1kbJtLu4oLRrf1NzA2xqgFZr9M6H0WX870sIJ3GK5AbM_1NAhjHqLaVIglNix0vorQoNaspdKz1cCdQkeIxGWtZfGtJos3OLtHaKEmtmw=w2560-h1708-no?authuser=0"
					alt="Crayon drawing of eggs, student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />				

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUWUFwqE6GsWDu6AfJIw0zi0PQIdAxGbRtXcNOc25h43Udr0NvyMIoCul7Mt4wRKPUOEYmljvkpoyiFfGGEfHcME0JKq6Bvc4E9U7Ky5P521Tf_TDf5KS5rWM8TPfVAVxJR0ss6ANyYt7rSqy7DDBu1tw=w2560-h1708-no?authuser=0"
					alt="Jim Morrison student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUQqBT8P_ovbZOdEmomraVolXFyqER23yFVbvzNeS0kGDoCvkAVdR1D1nui9iBmrIqU9s1R_C4SuU4Vq3sfK58IO7_q8X7LdU0MvkzTs_pkkIPGrORhmh3HI4VR9zIwMNrGzVvp91GiTueIzP2OQtsHHw=w2560-h1708-no?authuser=0"
					alt="VCU Symphony student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUmbgB3rg9n_Gw4EobnWcH4tr6mfeg_JoRMoFzfP8FX6sWmtRKCrvwiXr09iVBhtm2rnrZbjVOxv04IN8d75vppdo0rqoCQo_BCGVy8_7-Ik7HcMoeGpCOMTyiWT2Z5qk0DcSCMmK8IsCz8RRsKD0mCAw=w2560-h1708-no?authuser=0"
					alt="Client logos created throughout school years; artwork samples from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUvYVmf-XZm_TLtAhw6zcDugNPldB1rebc8BWjr7ffbLXtCY6879aw8T7oX9ra9LCzY9j7gzhmP22f3lnuqr7dPDPPCNVjkJH2eBIQzST4-wiJFihK8AnLl0mx-_uq1QiuEOkbpkt5fmKXjlU08kLFzGA=w3138-h2092-no?authuser=0"
					alt="Lasik poster student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWEcZHmXA5MweKUJpIm2ZqWHueAtPJLR0kc35IGviD4GnqRkYNatjTwJICuNtIetnYfsRCyr04_oMLPVOAtwd3phPw1vUBWNet2annX9Ayd5ONTv-6KpmW5bWbm199lNVgtzpAxRI1z2nRj62tq9Pe-Qw=w3138-h2092-no?authuser=0"
					alt="Design Rebels student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVMpth269qgOYQxoHgHq0oQ1HiIUP9cRbNWgIOFPl6HU7XVF0q123CZvQMWlfeq_nL1uxEyvpAV4zlBJ6ThHKAULn2suTg-9gSmVBReeUtOqZrBiL1SvFjMS6pTmPlfYKqabk-U0CJl0V29M8CXg5rA-w=w3138-h2092-no?authuser=0"
					alt="Evaporation word-based student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVwAfqDAr2hG5RPz_JxmtcHRfHVnrc-MIPJDX3QnJ-mgyoO65vxnYL8QXYBxG8SKOtfIEiGSwSbLM0KhwsDUO0WPMNDHx7e8_UNlqpDqxVzRq5SEPLkeOJSdJhD3LMA3mb35rJJYi151rpTy_cckT8mmw=w3138-h2092-no?authuser=0"
					alt="Björk student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUVXJua5f9kLOXIXPsVeVbnuJ6yRj4aYDhBXbKDOaU6xChh_PEV31D1jzKY4fVnfAzfdlhlzOym9zz16Fq8Bk0kcLGX6ub9qc1LwT6DOQ1XGQS6nRrGzskNiueRLoqlyji_N_ASuCgGFL-2ZVyRr_rwJQ=w3138-h2092-no?authuser=0"
					alt="Napa Valley word-based student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXcCys_nxmXmr8UdV7V08SDg52HRzNjm06HFgajX92XSHbzARQUMUjTr2HZ8NZCeJSQEZQCpq1nVDaxpfCqgF6Q-pEPzuvymdUONPsZ0jYMqjTulU7ao1KRPtApu5M24bbHehFH9y4f28k1sOZVCWokWQ=w3138-h2092-no?authuser=0"
					alt="Takashi Murakami student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU5mUn5200ON0q3BMWzC6X1gz8x7kV1GtgyvhuEGNP2YHpPzoIa4TH_M-yxAL19eubvaheaOwF2KQ_Aal8Gxw9h9k0gNoRPzy4i4qEt1_KmVukf_jUziBmHaKipJYdGGijS57fsb7Rgy6pBC24pVVzSJg=w3138-h2092-no?authuser=0"
					alt="Turntable needle student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVzoXpfe_iq30zPwe19S9XMrfcRpVB0a0TnHv-TCXWNT7fmy9CK-AFbYGYzrVjULWzej-FauX296esa135hypPDF1F5ErwR8CBHT5Zj6wLFRZaUbZ32dcy9retu9HOVgWH1L1S8PRiApM8ZObO1gDbxug=w3138-h2092-no?authuser=0"
					alt="Type design kit student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVjU4n4kFSdGyDaworLilbhsxYigF4nsWs93pqdUSxXbXmLSH6SOA7uHMPOWmvQcFNpmed5axOSOy0PjGxKjlzVnpTUzagLNMwAQLSLCkc93u6Y9tI9qkvTETt7hXoclxS4m9xtEhtLi9H-y0gO1wbI_w=w3138-h2092-no?authuser=0"
					alt="Dollars and Pounds client artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV9Wu8z7B_P2NRaBhGJgk0oi-wW1GSbSPFVAOwaGUrYS0FaUyrt6hchqTHG0gVkYtggRGdD49uoS59u7CzkCUnXf_xmx68MnvhH4q1U5ufAzuFvUwnWeXuTBpMNcO51PsoIQ0mviNVjZyi6J_cJB1DvvQ=w3138-h2092-no?authuser=0"
					alt="Student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWw97gHBYJDJBTFqZ2ont-3pcUJJtVyFaKAJu0l5auxBk1UblUfUprwsHG_XnM5x-TLC1Z7oJLanu2PVq9gfYjSoALBCmBaI2h2IIEU7H2QdAofFRwmTNd7jvHyfIjj3fB4134MMWJresg6kC-jpy-DfQ=w3138-h2092-no?authuser=0"
					alt="Student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVsls0OJ3Bd0yqJCIAMIidvTbzisPCwjxZbNlkE0K7eg4PMDh4VvdaPgbYCT3ZvNPB4GQOCw8tK7FkiT9M0ClhLmAXLj44NJx2szvob5nieaQlXnrJkOpdF-5PsuGOkhZ8zMkReyQAud-JhiKZH3c4bzw=w3138-h2092-no?authuser=0"
					alt="Blanket sketch student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUzSEYiGO7LXkIpq2k96IoUICuW9u29Q-8A9OHpltQJ-f_rDeczWOtV9Cj0Mczz-e84l4E99FtULgRriyeP0v90hdPXZMx-c3RXjLqgGUmrYREguzOp2OXglmCgSBeM0SY9ghD_PzX5i8AIfKtRK2cr_w=w3138-h2092-no?authuser=0"
					alt="Design systems student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUDCEnOVoE_VtptdW_mxhU33KYzXoWHVDVnccQdMbXAZlF-sp66wm8Lek7xRSOePLtnonIxUyuMln_kgvitIPNOFqYHA4TcrE5bNUtBTvMcOCsnKAtSI3NO7n_lWDgTUWn_b5BdIGqkNvCgzwVBWWj4uQ=w3138-h2092-no?authuser=0"
					alt="Pencil sketch student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWl_9TJW2zFj5a7-hHYFP_WsdwMKNcwSQfuToCASNtMXYa6tXL8qyvf6jO0i78xCE5nYzOGESzpb44P1Yd0Pi87HP-BKV3086eRB8zUzhkIqo89f5PIsnAGzuKhrlqVOCdzqqiADmaRA7dOZReIuB1hSg=w3138-h2092-no?authuser=0"
					alt="Design systems student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXozSCjDAM5VtmVAd2GE2Vzoy2xgpZgmTfLz57XkZ7zT4_beFhHRz3RGgNrhe8JAY7cn-3N5IyhUh4qd5rbA3D6w6DEi0QH9FxI5z3TtcjCGmp_3CO8enn9hvokizJB1CoYC7IDjZkX0z72EoSpYyCgtQ=w3138-h2092-no?authuser=0"
					alt="I heart Iraq student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVnLUhS7WLGYGLty2amejKSTqmBeffAyUZ-eyRNR9qDhJDpeoWidU-sbKnl90wcs4jqpXV6qdzEV-xrkCethdoL5es30z7Y1UYlHfK9rTDCWbQDJYdqxvH10bC0p8EAgCGCgoWtlmwVsjCdw4He4lPyBw=w3138-h2092-no?authuser=0"
					alt="Figure study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXzfNy0UW21jbqJBOsqqLh4xq1Xrlbr3a8diqJLy2WNNKCN92Hvsc1S3iO1uHK28P0r39UTfwFR1Y_j6rlMze4PHhVsmnl-HyyvF_Z1aOAMuClQHOOEZgeaXltC092fewNugnRUptnwHGxxU4qvx3ak0Q=w3138-h2092-no?authuser=0"
					alt="Type study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV7_1OSJF4db_zRT7K-cclUU56jU9RXE5vhaD9A6Hb51bQqnleif3qFf7dnFZaOcN1PVSW6AKPlrzZ0m97gb9tJjRa6USgnVQdN3H_KCL1AIt0BTRVZB6My93uOxE-coImX4BrogEzeTU68lwuTcrh4YA=w3138-h2092-no?authuser=0"
					alt="Type study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXpIG2VANPQ7f7tSEwgFs22KgndIdoPXsOrt0Ar09pCbzab2X_p7-xcfs4jGOWft6pbfYjXG7iXUxk8ifrkRmetdBbryIq7I7_2mMZgIuAVxBmUDFHIlfN-lx22Moh26kZ7SONHwu9TgnSVRVaqsUfKTA=w3138-h2092-no?authuser=0"
					alt="Color study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUHu62tY1y3hGcs7B_ZPMK3vwGKRfVy8r-AL0s6WDXdRKsHUn2VcAmLNSZ1Mj1Kt06kUGZsX7BchDVKAf_rULUuv6IsSfLGLacOinzsRVI7qeBO6F0baF-DgnGJOR0fN_jUGJZEblCqVson3TwpGxXjrA=w3138-h2092-no?authuser=0"
					alt="Still life study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXx4HAHkLfDfj7WVIswkFOUkfNLAzBxy9akiMxfV1RFSOFIBxNzXIi9u3pldsIlHdHY8Wldn6pUsDE0imc2Ap3Iw2zHn-adVc7ZcDleERKkam031zVWjJ7ZsgiWlIWUimlWZtarJboE98ALoDOJouRpTg=w3138-h2092-no?authuser=0"
					alt="Tangram student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXJ3pHiJJbmggk4GJGptcldCSCfT4IPuJ8RV-K0-y0L3IceGuc78zeAXfzxFwcIjlAbjN5wqtGoR1-aVBOjO_TeCIuuLtR4vl-sy2g2yQZr2JS9d9kyEmiuFj5s5dZrjtU9RqblXWwvrkxUCvZTEECjWQ=w3138-h2092-no?authuser=0"
					alt="Tangram student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUfeTbisyR9Ceeea6JMlSyZQCXTnMDd2uUlMFDA_jvtbj_DogNjOQ_ln9tCK_XbeVEyN5PhJVBdx3FEq4Tp9LgUPQ3H0LDFm16l2UZIBi77BesH-mhoXGZvxi78ex9IVku8XdyorB2oKpDGZjYeP7Su2A=w3138-h2092-no?authuser=0"
					alt="Monogram study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXW1Sde9xH4jOyKzUNE1OKdUd6-UuzAk9xITUwQNhttQaJea2OlJ8sMi7adbGKASWWXXonY2cwFB83-VhwXTY6lAK0AgVQPX0aJwWJpYwgKucPFtrEhe-gOO1_4AHBzh7BxciptEmZY13SwHzlLtNE1TA=w3138-h2200-no?authuser=0"
					alt="Design systems study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUDtaZAvz9UU1BLlOrFx9XPvyylzKuUP8OsJHXkNn5n2Wikykk6rejqXbq-3Zr8KO5bMIltaanECiR-S-SHOqgu9uY5FwxQIjoHMd6AOxRT6iZBt3l8lFdTbCSbswRAV6M1XJg1rIXbKkZW0zxhTft3Yw=w3138-h2092-no?authuser=0"
					alt="Design systems study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWGEC1I-JanWks9cw9TIilI4RPbHc_m2yY976cF2-NeeBOJ7UYZxWbvgdr8DSRLRfh0lsidjvynjbdCLwbhbJYcgvAepJeK2I1IdWpbAyK4oRBxexFrfH4qSYTaTNcJ2vHgBz2KyT9AltCIPrJm03-sKQ=w3138-h2092-no?authuser=0"
					alt="Design systems study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWkIuH0buji8msT38zS36tQaIjNvHB4-x0M5NMs2V6dY_ECCgV_eGUE2NQoVmWPddzqrn4LpRFAhmlDAojrfhfN_9zf5aeZa88gTj9F-tmnRNI24SrhnYEqdDcx4eFujyK_bgHYH17CbhIebI9oJhwZzA=w3138-h2092-no?authuser=0"
					alt="Monogram study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWQwOhAdUy63S1mUergJn-ApMLruwQD02B2sjmR3QWCN4lTVqc-C2F6uOHg5AOH6gRfewgTfuV8MjRjkDBQksZKfkdBvsYe5_7bCTVpRGDAuvULQrcS0T1MXJk63fkHU6eMfPGez8bCx0M2df0KDM19sQ=w3138-h2092-no?authuser=0"
					alt="Monogram study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWaHlKHTwg1ikUbp-jDyn-ArBGHiT-KpC9fMEDZ5O4kQxgBJLXFaFpaBBrpTDy9IAK4Vu04eC0tU8qlz-lHGdxNRrfQcAjYKN2kw-Fl5vueP7b2Y9108tuE1QPiJBVmXoMBnxtleOrM26Tb9NWAYUp6Og=w3138-h2092-no?authuser=0"
					alt="Blanket shading study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU7ofWnCmUQrO70BRJACxd_RUW45AckIp0LvaYuBsbS7Go_DMzSJrJN93T4YqjyDExm9-Kr7SRAJSEIoaHnDFPlsRQmRlFKEbsoWPiqHfhbOzgZJyD6fD9twh6S_-RUzbGWNEEnYxFFdOx1csbXidqzlw=w3138-h2092-no?authuser=0"
					alt="Deconstructed watch study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXzjkX-gVB2U5kCbZhDGitBwZhHHi4DVIPobFq5gyA1r9K4t8KtwzFXj_AkCEe1qEeFZ6WJ2Zq4mm2tRyZ71hxLHaAOHs2y8rDMKJy4hv3zjc_EaGMA-Wd_dBkEuTE_z5SbgqCRrfQ9H1XUwmv60lh2EQ=w3138-h2092-no?authuser=0"
					alt="Color study student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXGwzB8psc3P0LUc4p1fWUSrFNPtrWJLhcj_g-7dWddclDFdbq5QMDIb5B4tZ3qCqpyJ0vPWDbPZchUdrb370JShv-_h0nB-VKv2Oq25m69jzx73MerrDcra4D82ldPMdstnKpqnQxIHPEr912w3gh5Ag=w3138-h2092-no?authuser=0"
					alt="Marma75 student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUL28pTrwhjSV7MO7xDOsdPKUnGpC29iM0mWwkcIwPaBexicsC0bCZxd1Li45pF6DwCwN9oK7n0C5FOwGmsPp931AdzQkE2o8L-ItFOcDjR_CjCA_HAJNxozMyPIeBfpZrViGL5E8mh4CY63OKhEpAZfw=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXNmmCg6qI0c6Xf-CVIBX12DZCvKyE6hoQr7yVNJaDye5JNAK99SuGq7zFC1EFZt7x4V2fmNBf0C-8QHaJJaMgv5EgFF1YxStY8xzgNxXsWozGLBj6qQGYekBXTABEVTkvgItfgQsfxAFvN0JxQK6w4Sg=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWc6OHd-CiCYUc9tm8JB4WInUaH99wxpFyFfENvvL7PzQF28D0PkHy8XShRhWXUespXPyUGcURsrweiHw8u8X8kmosCIHXDQEF0r9rL3-NALsbpJxjv68M3puSx4wnkMDhbYcO46GA2X-AWep67MaBe-A=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWiytidfWNFOFDmvBIXLz0XX8sK1xlHLBkEdWWfnk08oBVbnl6R1CnVE35a47CguqMGljZ2ivIw07BbWC0s77hoX1yIZgafOCdtiS7iis12kfh3r451h2rMe2-RhMK6bh4xt4jhui6MQ9OORvAx7fCUTw=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVZem7gVZdw72U8t1ieUmc1dMftocl5aCr-wJjLMExFs6jgdbAv362ox8_bIRLpRvWZj6YuzciuUjU-fnbVfja9ZzyNIYZHMSR0Bdhy2N4qwn08KRyILFAVfiSpPPD7WaVB9AJPSR23BCQKqV6jQ7uGEg=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX_B-C6moYkAF0uUfSdfp_47O5iNIw42-eR59_YJm0fhfOkAM-2SHuFJDmYsdog8n2c-D6ne4X3Cq55YPsCZ2bG-7Z-kvhDdeP8UbjyHu11Ooic8AEaF8wfaAgjrD8D6iUnsSASmQmN-BbVuxWHRdGa8A=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW3Yx7Oy3UGUBX_gl4_Q6-CfTJlrcvPh3T2nrfxMI5DUdioDdDJodFgdr-jtksBvRsWUOtnY6U--Vti8OxtarJE4gFrX7VV0AOJ9Xkf8mfW5rTwToskpEV0nx_BTY_KHJWZMu5U4H0klubUAnB6Ehm4uQ=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUFFg9qZrButIiHDoN04b8uLdhCNQYkXM29HvrNGaovV0CaNZHFV1I8_RiGhWf_cmPX0ErBjJ8Q74uQlRblJ1a6sV14Gjgnm--eBLfe2xP3b8bupqiHeyTDHC9Lo07Pugd4qHPn9af_te9RsL9l1ne2wA=w3138-h2092-no?authuser=0"
					alt="Slow Education magazine student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVXWqRp_kjdISu8jX0BOzFLz-x_XRfVqrc9nxdkVeAixMSqFM_N3NngFMe_SQbEfMnJgTUwBYomjJylDpL0VgD5jIvC4Q9xffkNCzjwlFE3jU7AHhIdCEJLfMa2_YPm9iukHpz8SuNArmeSu0Hup_EDzQ=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUJW5KwVkdv7oxUEF5ZcsOVoFcTB68eM7jlZfHVlLPTqSxHwbG-TveMQYTXLvx3w6Y6ipcD6ZCKFFhPMe3CRKzA7ABYnrpWMtYjUoO1jZ1oQakC7Ly5vZp27EEm1cDJqbqMYs__NoIURaeXh3DzC0Lohg=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXKI9FUJMA0hn38rfbVdFpDHYFZtZGWLyaCq73CgPStxV-H-gmiFDbUm6CmHRjWoOoc7_fW-MYcb2zSIBHdIvvQWJf3AVvSKiFJynQxD9yl0sYmA6Nm8TGVdVXFjl5iMRq_W1EsBGhvFGjYACOYNZn0Ew=w3138-h2234-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUEHtk5KMbHKQdKD5AWUynmk3_7y4tDT2NginObX64JqY0WXbtRRsyC3fwt80PYzlQPVJDkkoEfktnhZwmeZexJMzESwJf1esfK_QI4VZnC5AgQsbaB6JnWsNXgZJOZyfKNcIq_fuE_U8sT8CMmHV2fVw=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV-P5zfMGK03Es1xlHS8dhXGrJB-cD902GAMrldVzlZ4PpVqPyXARw02htDHPrcjC7Lj4hdlUPuS7qsiWtJM875YQtj9VuSe53B-kulIhIBQcTTBm1xwXv46tlZYaZn0o-mbex8dzgM2TCVmDViGyGTlQ=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXPk12AFqgzS4yJhd7Xwdtc5oGpq70ptA8mKtK514AxVc-2DQdUUQBZPZftUwdEZa8qUO5merV-hiMD8aJMS74_i4mwGNhBpcfJ-foXF8l18qqwO_oSWRDyX1YPepTxrCuV6dt8LJDL0ROwfw3dIdrVjw=w3138-h2162-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXZTp2FpE2vkEIhPQ2oITQ8XmWAD1TDwfuyNiwGl3JSKv5fIpyylossAQzr3NtgPZVK9VoLBFtm0c07HtMRxESzAeMueH5R-IZi1LsVY9HE_uWBi4riEYf38eUE_L07mcue6rB6eeju_3oe-arxUFLLMQ=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUSUJ1LJXziHl0643H3O4ZF1blsofAKUuWHfWLav9pzhtTz-_J487CYtvM0fVmnBL0hJk_6GpWrjNTIh3j_UKLGtROEAviF6UrnYC97SC-u-MKCP0pxznGksnmPAHNzJuJa46X_TbqNUAknXyLiMxyDwA=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXaDBaFTeBfa66drvPDMPSYTjCpML1wfyaPWAQv5_jSjnoWO6arR1f6b1V2Hz7SrNdB4IYqOkf4tulQ5p-bf1lnbJtUJhCnyZx1BwLM2C10b0h_ZA5BFlPX_kBXA8GcpCM1MRUEJMPsINzb4p_5blf9Yg=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUaTv1YiQD39V3ULa2sTfO_vL1Suwp5nf-rNyjYz9JMzIBzrhg7ympAYckyKO2eGUGZvzc8HYHt9mFC8PC-lrzg_OViOjFmm5tSXRPTkwYVJ52wHLbell_D8e89GmPl-SWKAKDcCYHIFdaZLoWIyh_sLQ=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXRSmz_xdFMleCQGfx0350X8-NHWC3A0e_Q5fvCL4HpzIVZxV_jQiRDh5fXAvNen3yS9kKx9EZrWPI_YeRn6i3u8mVGGLbRPQJu1nVs5kIjQmlb5z30RUjJOcYLBvMMmyYl8Hvxxomp_xX3uI0xyaxh5g=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWKo2IIbt7UCM8c2SZUSa7Z9cCmMdy13nkHAVjtSGZ0CrU5iQrICCf1A4TtBqkGowlVfpmwRHvur3sItRwrf0aS4kfxHfPR_uDIr8FIUsZFQDP-tBacEeUVu8UnEJLTFwzSI-k1wQdVUVZPa1VfTqaSwQ=w3138-h2092-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUwR_W6hNvT41AC4S9khS0yYqCy8Cuvrnz2WhcE5-PWNyOAqeWEXzdKSo7MHrFJQuo8SikFzA6DbtQYiZMq0sNsgEr594I45N7ckCuIJsdUchEv3xXgC8QY4m5iGT-cplNVdPclh1p-UpiSKVeJ6GAv9Q=w3138-h2202-no?authuser=0"
					alt="Poster design student artwork sample from Dylan Mullins."
				/>

				<Spacer spacing={90} />
			</>
		);
	}
}
























