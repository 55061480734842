import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import CustomEase from './plugins/CustomEase';
import _ from 'underscore';

import Navbar from './components/Navbar';
import Menu from './layouts/menu';
import Home from './layouts/home';
import CaseStudy from './layouts/caseStudy';
import About from './layouts/about';
import Error from './layouts/error';

import * as ProjectsData from './helpers/ProjectsData';

require('../styles/styles.scss');

export default class Index extends React.Component {
	constructor() {
		super();
		this.state = {
			renderStates: {
				loader: false,
				menu: false,
				home: true,
				caseStudy: false,
				about: false,
				error: false
			},
			navbarStates: {
				isWhite: true,
				isOpen: false
			},
			isMobile: window.innerWidth <= 720,
			isDesktop: window.innerWidth >= 1024,
			history: []
		};

		this.location = null;

		this.refLoader = null;
		this.refLoaderBar = null;

		this.refNavbar = null;
		this.refHome = null;
		this.refMenu = null;
		this.refCaseStudy = null;
		this.refAbout = null;

		this.isBrowserFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		this.transitionPoint = [0, 0];

		this.isPopNavigating = false;
		this.isFirstLoad = true;

		CustomEase.create('Mullins', '0.64, 0.04, 0.35, 1');
	}

	static childContextTypes = {
		isBrowserFirefox: PropTypes.bool,
		isMobile: PropTypes.bool,
		isDesktop: PropTypes.bool,
		isPopNavigating: PropTypes.bool,
		isFirstLoad: PropTypes.bool
	};

	getChildContext() {
		return {
			isBrowserFirefox: this.isBrowserFirefox,
			isMobile: this.state.isMobile,
			isDesktop: this.state.isDesktop,
			isPopNavigating: this.isPopNavigating,
			isFirstLoad: this.isFirstLoad
		};
	}

	componentDidMount() {
		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
			history.scrollRestoration = 'manual';
		}

		console.log('Location', this.location);
		let renderStates = {},
			pathname = this.location ? this.location.pathname.toLowerCase() : '';

		if (pathname == '/' || pathname == '') {
			renderStates = {
				loader: false,
				menu: false,
				home: true,
				caseStudy: false,
				about: false,
				error: false
			};
		} else if (pathname == '/about') {
			renderStates = {
				loader: false,
				menu: false,
				home: false,
				caseStudy: false,
				about: true,
				error: false
			};
		} else if (ProjectsData.isProjectRoute(pathname)) {
			renderStates = {
				loader: false,
				menu: false,
				home: true,
				caseStudy: true,
				about: false,
				error: false
			};

			this.updateNavbarStates('isOpen', !this.state.navbarStates.isOpen);
			this.refNavbar.toggleMenuIcon(true);
		} else {
			console.log('ERROR 404');
			renderStates = {
				loader: false,
				menu: false,
				home: false,
				caseStudy: false,
				about: false,
				error: true
			};
		}

		this.setState({
			renderStates: renderStates,
			history: [this.location.pathname.toLowerCase()]
		});

		TweenMax.set(this.refLoaderBar, { scaleX: 0 });
		let timelineLoad = new TimelineMax({ paused: true });
		timelineLoad.to(this.refLoaderBar, 1.2, { ease: 'Mullins', scaleX: 1, delay: 0.2 });
		timelineLoad.set(this.refLoaderBar, { transformOrigin: '100% 0' });
		timelineLoad.to(this.refLoaderBar, 0.4, { ease: 'Mullins', scaleX: 0 });
		timelineLoad.to(this.refLoader, 0.4, { ease: 'Mullins', opacity: 0 }, '-=0.2');
		timelineLoad.set(this.refLoader, { display: 'none' });
		timelineLoad.play();

		// There is some delay until caseStudy properly mounts
		// Best solution would be to set isFirstLoad false at the end
		// of componentDidMount() for every page but that seems unnecessary
		// 100ms is too fast for people to navigate away
		// and if it does, it is just extra loader again
		// (which makes sense because none of the page would have even loaded)
		setTimeout(() => {
			this.isFirstLoad = false;
		}, 100);

		window.onpopstate = () => {
			this.onPopstateWithSpamHandler(0);
		};
		window.addEventListener('resize', this.onWindowResizeHandler);
	}

	componentWillUnmount() {}

	onPopstate = () => {
		let origin = [...this.getHistory()].pop(),
			destination = this.location.pathname.toLowerCase();

		this.navigate(origin, destination);
		this.pushHistory(destination);
	};

	onPopstateWithSpamHandler = popstateSpamDelay => {
		if (this.state.isAnimating) {
			setTimeout(() => {
				this.onPopstateWithSpamHandler(100);
			}, popstateSpamDelay);
		} else {
			this.onPopstate();
		}
	};

	onWindowResizeHandler = () => {
		if (window.innerWidth <= 720) {
			this.setState({ isMobile: true });
		} else if (this.state.isMobile) {
			this.setState({ isMobile: false });
		}

		if (window.innerWidth >= 1024) {
			this.setState({ isDesktop: true });
		} else if (this.state.isDesktop) {
			this.setState({ isDesktop: false });
		}
	};

	updateRenderStates = (target, value) => {
		let newRenderStates = this.state.renderStates;
		newRenderStates[target] = value;
		this.setState({ renderStates: newRenderStates });

		// if (target == 'caseStudy' && value) {
		// 	this.updateNavbarStates('isWhite', false);
		// 	this.updateNavbarStates('isOpen', true);
		// }
	};

	updateNavbarStates = (target, value) => {
		let newNavbarStates = this.state.navbarStates;
		newNavbarStates[target] = value;
		this.setState({ navbarStates: newNavbarStates });
	};

	onToggleNavbar = () => {
		// PROJECT
		if (ProjectsData.isProjectRoute(this.location.pathname)) {
			this.refCaseStudy.onExit();
		}
		// HOME, ABOUT, ERROR
		else {
			if (!this.state.renderStates.menu) {
				this.updateRenderStates('menu', true);
			} else {
				this.refMenu.onExit();
			}
		}

		// if (this.location.pathname == '/' || this.location.pathname == '/about') {
		// } else {
		// 	// this.updateRenderStates('menu', false);
		// 	// this.updateRenderStates('caseStudy', false);
		// }

		this.updateNavbarStates('isOpen', !this.state.navbarStates.isOpen);
	};

	setTransitionPoint = (x, y) => {
		this.transitionPoint = { x: x, y: y };
	};

	getTransitionPoint = () => {
		return this.transitionPoint;
	};

	updatePositionHome = position => {
		if (this.refHome && this.refHome.refHome) {
			TweenMax.set(this.refHome.refHome, { position: position });
		}
	};

	updateNavbarOnTransition = () => {
		this.updateNavbarStates('isOpen', !this.state.navbarStates.isOpen);
		this.refNavbar.toggleMenuIcon(true);
	};

	closeNavbarOnNavigation = (mouse, destination) => {
		this.refNavbar.onToggleNavbar(mouse);

		if (destination == '/') {
			this.updateRenderStates('home', true);
			this.updateRenderStates('caseStudy', false);
			this.updateRenderStates('about', false);
			this.updateRenderStates('error', false);
		} else if (destination == '/about') {
			this.updateRenderStates('home', false);
			this.updateRenderStates('caseStudy', false);
			this.updateRenderStates('about', true);
			this.updateRenderStates('error', false);
		}
	};

	offsetScroll = scrollY => {
		this.refNavbar.animateOffsetScroll(scrollY);
	};

	getHistory = () => {
		return this.state.history;
	};

	pushHistory = route => {
		let currentHistory = this.state.history;
		if (currentHistory.length > 0 && [...currentHistory].pop() != route) {
			currentHistory.push(route);
			this.setState({ history: currentHistory });
		}
	};

	navigate = (from, to) => {
		this.isPopNavigating = true;
		if (from != to) {
			if (from == '/' || from == '') {
				if (to == '/about') {
					// from HOME to ABOUT
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: true,
								error: false
							}
						});
					}, 0);
				} else if (ProjectsData.isProjectRoute(to)) {
					// from HOME to PROJECT
					let projectIndex = ProjectsData.allRoutes.indexOf(to);

					if (projectIndex < ProjectsData.projects.length) {
						let projectObject = this.refHome.refProjects[projectIndex];
						let projectBox = projectObject.refName.getBoundingClientRect();
						let triggerPoint = {
							clientX: projectBox.x + projectBox.width / 2,
							clientY: projectBox.y + projectBox.height / 2
						};
						projectObject.onProjectNavigation(triggerPoint);
					} else {
						let triggerPoint = {
							clientX: window.innerWidth / 2,
							clientY: window.innerHeight / 2
						};
						this.refHome.onArchivedProjectNavigation(triggerPoint);
					}

					// Hacky solution, but works solid
					// There's a function that does this exact function probably somewhere
					this.updateNavbarStates('isOpen', false);
					this.refNavbar.toggleMenuIcon(true);
					this.updateNavbarStates('isOpen', true);
				} else {
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: false,
								error: true
							}
						});
					}, 0);
				}
			} else if (ProjectsData.isProjectRoute(from)) {
				if (to == '/' || to == '') {
					// from PROJECT to HOME
					let projectIndex = ProjectsData.allRoutes.indexOf(from);

					if (projectIndex < ProjectsData.projects.length) {
						let projectObject = this.refHome.refProjects[projectIndex];
						let projectBox = projectObject.refName.getBoundingClientRect();
						this.setTransitionPoint(
							projectBox.x + projectBox.width / 2,
							projectBox.y + projectBox.height / 2
						);
					} else {
						this.setTransitionPoint(window.innerWidth / 2, window.innerHeight / 2);
					}

					this.refCaseStudy.onExit();

					this.updateNavbarStates('isOpen', true);
					this.refNavbar.toggleMenuIcon(false);
					this.updateNavbarStates('isOpen', false);
				} else if (ProjectsData.isProjectRoute(to)) {
					// from PROJECT to PROJECT
					this.transition(() => {
						this.setState(
							{
								renderStates: {
									loader: false,
									menu: false,
									home: true,
									caseStudy: false,
									about: false,
									error: false
								}
							},
							() => {
								this.setState({
									renderStates: {
										loader: false,
										menu: false,
										home: true,
										caseStudy: true,
										about: false,
										error: false
									}
								});
							}
						);
					}, 0.55);
					// }, 0.45);
				} else if (to == '/about') {
					// from PROJECT to ABOUT
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: true,
								error: false
							}
						});
						this.refNavbar.toggleMenuIcon(false);
					}, 0);
				} else {
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: false,
								error: true
							}
						});
					}, 0);

					this.updateNavbarStates('isOpen', true);
					this.refNavbar.toggleMenuIcon(false);
					this.updateNavbarStates('isOpen', false);
				}
			} else if (from == '/about') {
				if (to == '/' || to == '') {
					// from ABOUT to HOME
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: true,
								caseStudy: false,
								about: false,
								error: false
							}
						});
					}, 0);
				} else if (ProjectsData.isProjectRoute(to)) {
					// from ABOUT to PROJECT
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: true,
								caseStudy: true,
								about: false,
								error: false
							}
						});
						this.updateNavbarStates('isOpen', false);
						this.refNavbar.toggleMenuIcon(true);
						this.updateNavbarStates('isOpen', true);
					}, 0.45);
				} else {
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: false,
								error: true
							}
						});
					}, 0);
				}
			}

			// FROM 404
			else {
				console.log('FROM 404 NAVIGATE');
				if (to == '/' || to == '') {
					console.log('TO HOME');
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: true,
								caseStudy: false,
								about: false,
								error: false
							}
						});
					}, 0);
				} else if (ProjectsData.isProjectRoute(to)) {
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: true,
								about: false,
								error: false
							}
						});
					}, 0);
				} else if (to == '/about') {
					this.transition(() => {
						this.setState({
							renderStates: {
								loader: false,
								menu: false,
								home: false,
								caseStudy: false,
								about: true,
								error: false
							}
						});
					}, 0);
				} else {
					// 404 to 404...?
				}
			}
		}
		this.isPopNavigating = false;
	};

	transition = (transitionActions, delay) => {
		let timelineTransition = new TimelineMax({ paused: true });
		timelineTransition.set(this.refLoader, { display: 'block' });
		timelineTransition.to(this.refLoader, 0.4, { ease: 'Mullins', opacity: 1 });
		timelineTransition.add(transitionActions);
		timelineTransition.to(this.refLoader, 0.4, { ease: 'Mullins', opacity: 0, delay: delay });
		timelineTransition.set(this.refLoader, { display: 'none' });
		timelineTransition.play();
	};

	setNavbarColor = project => {
		if (this.refNavbar) {
			this.refNavbar.setNavbarColor(project);
		}
	};

	ComponentNavbar = ({ location }) => {
		return (
			<Navbar
				ref={el => (this.refNavbar = el)}
				location={location}
				isWhite={this.state.navbarStates.isWhite}
				isOpen={this.state.navbarStates.isOpen}
				onToggleNavbar={this.onToggleNavbar}
				setTransitionPoint={this.setTransitionPoint}
				navigate={this.navigate}
				getHistory={this.getHistory}
				pushHistory={this.pushHistory}
			/>
		);
	};

	RouteMenu = () => {
		return (
			this.state.renderStates.menu && (
				<Menu
					ref={el => (this.refMenu = el)}
					location={location}
					getTransitionPoint={this.getTransitionPoint}
					updateRenderStates={this.updateRenderStates}
					updatePositionHome={this.updatePositionHome}
					updateNavbarOnTransition={this.updateNavbarOnTransition}
					closeNavbarOnNavigation={this.closeNavbarOnNavigation}
					getHistory={this.getHistory}
					pushHistory={this.pushHistory}
				/>
			)
		);
	};

	RouteHome = ({ location }) => {
		return (
			this.state.renderStates.home && (
				<Home
					ref={el => (this.refHome = el)}
					location={location}
					setTransitionPoint={this.setTransitionPoint}
					updateRenderStates={this.updateRenderStates}
					updateNavbarOnTransition={this.updateNavbarOnTransition}
					offsetScroll={this.offsetScroll}
					getHistory={this.getHistory}
					pushHistory={this.pushHistory}
				/>
			)
		);
	};

	RouteCaseStudy = ({ location }) => {
		return (
			this.state.renderStates.caseStudy && (
				<CaseStudy
					ref={el => (this.refCaseStudy = el)}
					location={location}
					updateRenderStates={this.updateRenderStates}
					getTransitionPoint={this.getTransitionPoint}
					updatePositionHome={this.updatePositionHome}
					updateNavbarOnTransition={this.updateNavbarOnTransition}
					navigate={this.navigate}
					getHistory={this.getHistory}
					pushHistory={this.pushHistory}
					setNavbarColor={this.setNavbarColor}
				/>
			)
		);
	};

	RouteAbout = ({ location }) => {
		return (
			this.state.renderStates.about && (
				<About
					ref={el => (this.refAbout = el)}
					location={location}
					setTransitionPoint={this.setTransitionPoint}
					updateRenderStates={this.updateRenderStates}
					updateNavbarOnTransition={this.updateNavbarOnTransition}
					getHistory={this.getHistory}
					pushHistory={this.pushHistory}
				/>
			)
		);
	};

	RouteError = ({ location }) => {
		return (
			this.state.renderStates.error && (
				<Error
					ref={el => (this.refError = el)}
					location={location}
					navigate={this.navigate}
					getHistory={this.getHistory}
					pushHistory={this.pushHistory}
				/>
			)
		);
	};

	RouteApp = ({ location }) => {
		this.location = location;

		return (
			<>
				<div ref={div => (this.refLoader = div)} className="loader">
					<div ref={div => (this.refLoaderBar = div)} className="loader__bar" />
				</div>
				<Route path="/" component={this.ComponentNavbar} />
				<Route path="/" component={this.RouteMenu} />
				<Route path="/" component={this.RouteHome} />
				<Route path="/" component={this.RouteCaseStudy} />
				<Route path="/" component={this.RouteAbout} />
				<Route path="/" component={this.RouteError} />
			</>
		);
	};

	render() {
		return (
			<Router>
				<Route path="/" component={this.RouteApp} />
			</Router>
		);
	}
}

document.addEventListener('DOMContentLoaded', function() {
	const root = document.getElementById('root');
	ReactDOM.render(<Index />, root);
});
