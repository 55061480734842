import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class CaseStudyCollage extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="caseStudy__collage">
				<div className="caseStudy__collage__grid">
					<div className="caseStudy__collage__grid__image">
						<img
							className="caseStudy__collage__grid__image__image"
							src={this.props.imageSources[0]}
							alt={this.props.alts[0]}
						/>
					</div>
					<div className="caseStudy__collage__grid__image">
						<img
							className="caseStudy__collage__grid__image__image"
							src={this.props.imageSources[1]}
							alt={this.props.alts[1]}
						/>
					</div>
					<div className="caseStudy__collage__grid__image">
						<img
							className="caseStudy__collage__grid__image__image"
							src={this.props.imageSources[2]}
							alt={this.props.alts[2]}
						/>
					</div>
				</div>
				{this.props.description && (
					<p className="caseStudy__collage__description">
						{this.props.description.header && (
							<span className="caseStudy__collage__description__header">
								{this.props.description.header}
							</span>
						)}
						{this.props.description.content}
					</p>
				)}
			</div>
		);
	}
}
