import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class JobClocker extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'The brainchild of a 30+ year veteran of the residential and commercial construction industry, JobClocker was a digital product to help construction project leaders create and manage their jobsites, track company inventory like power tools, and monitor inventory of project materials. For workers, daily and weekly timesheets are managed by clocking in and out of the product via on-site QR codes. JobClocker was rebranded to JobTracker in 2013, and later sold by the owner to a large competitor.'
					}}
					details={[
						{ title: 'Year', content: '2011' },
						{ title: 'Client', content: 'JobClocker' },
						{ title: 'Role', content: 'Naming, Branding, UX Design, Copywriting' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVtD5iPvoWGtz8n6NL1yrt63klVLaKaD6cX1mHs0Lw6teVgPZ6bTb-i1LsG_1bmqRzFiqSxrJsh6CdpL6b_CZVJuyjpwAXFE2ypyH_0J8aPu9QFkGeTw6Ttym5sU3Q5o5DXwknJUslmQtv351yfYLWlnw=w1600-h965-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXELlvZBSZh8wU0Uuwpf2OBG6SjfL0gq2Gx4Waem39tZia6TTE4zET65YsMbgF_mcztO2DtfSm75rqGWIgBpzGXV96YvQR3Grb5Yin6sWdcfzBTft69lYIgp1F43PSC7V8QjWrvb1SG6xyIfO4XAiveXA=w1200-h700-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWkAyEf-XPFi1AQObzRzagLCbJuYbr0zZs4yiFkvi5cwmn0iShzJ3fMjzL0vUYl0kXnUDTk7itN-zRZCP2LKmcbYSLp8-iBox9SRAH1l13AgrXxHyHzO9uJJdG_Sajlw0RQx8msjBs9rBbG9C-MSN28ag=w1200-h675-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVe9FibjgJz7o9DnrwPGcHcuRkxJDfzbLBY9pFizJwAsI0RpKA1cf-0u8EPLcA4gpIsvfq87jU2swUXvWNjvULeroYRw41wtUtgH7_bWoAOn4MYUmRiV4GFWGfMATa18whLaaj7o5tMDxF5iXtPmze0pw=w1200-h600-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXxTxTAq8BB3-9g_7Jay-JJNclHKCuUfLh9hBy6zQ_W6T7EiMUb7-Me79beAsR9nsFtzapS3kxbUGRVbmQcHnwCDRE5zN0Ec-bjTQeVXTVrFV-u8ILnWGohw-d5QjVCbO25ERd4Q3ApJU6aGCFZF6Qcqg=w1200-h591-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXFXqcT8gF1ZtYpoaR-tZN5Y2gnJghfLi-e_4PCqw0DzT3aNnciF5HeSxp7I4Qo6OB82_rWJxrvRMDpnxemekFv-PlVzdTP2tZSauXW90p1RzXt5E0RpW9BodgIdLkbMYH76vPtMt56WwazekAeh4ajdw=w1200-h700-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />
				
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU3LKQ_dgyb6CXeGd8F3RjIX5GlLOpyByU5ndLu45arheqY4U_CNRoiNofMtfxaxV4hlrW_14UWNpYsWQOlZRYJFVwydc-jn6j1ntD64r4-tUC-TXH1RW2yQYYNbAzQZUouygHV1BR-Kdxk5ljKyrGgAw=w1200-h744-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVISbG5yV_NSIIcg7AWgH9_P7aG3QXlRaf6IqF6yr44hnoNBWopLjdl02IU6sWicPF5-gXQqqYk8HSRmVzVKLOhDN_t-MaJgsfPLoGe3Ar6cGlOhqdJxMjHgKs9ytx62WWGF3wdPlRKXn6H1i9UczFyIg=w1200-h1130-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX418oxyfvpy5WM-Fjb9kaIDD_B6rD0Zo3GjIt1dQlIHOL_ytVyIe015YKRUDkrZoNb8uWmGC_Qu9s2BqKq-o6sJQpDzYmRGDyQrUaOqHQQkkIxqT8wVwoJwI_4y1Etiu2Q4TyQPVVXRQJesnUP89EZmw=w1200-h1330-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUyjfKYE54ZoSxJcY0dn_olUrepjxkqpgGlEUbOSs7Q4SxTEORnwwoEiDpB6_qPlCz66WOXdWttaCa3QLn1B9wQYCqwVhRz6EKaZ7WVFjSV39gLBDqa3S48oEg4vrEXTsO5sWa2PljsGPkUzCSn6HyaXg=w1200-h630-no?authuser=0"
					alt="JobClocker sample design by Dylan Mullins."
				/>

				<Spacer spacing={70} />	

<iframe src="https://player.vimeo.com/video/84063810?h=31e9a08ee0" width="1100" height="720" frameborder="0" allow="fullscreen" allowfullscreen title="JobClocker, Case Study Video"></iframe>

				<Spacer spacing={70} />

			</>
		);
	}
}