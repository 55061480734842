import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Buffalo extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'When Covid-19 hit the world, bars and restaurants were no stranger to changing consumer needs. As consumer expectations rapidly evolved to new service models, the team at Buffalo Wild Wings worked to launch a web-based contactless product to put the power of service choice in their hands. With the Bring Your Own Device (BYOD) product, guests scan a QR code to browse their local menu, order food and drinks for dine-in or to go, and when they’re done, close and pay their ticket without waiting on a server.'
					}}
					details={[
						{ title: 'Year', content: '2021' },
						{ title: 'Client', content: 'Inspire Brands' },
						{ title: 'Role', content: 'Design Sprint, Mobile Web, App Design, Product Strategy' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXu6pdYi4Z_L2Xsnt0tm38mz55p4nAutU8dg8NV9Sah7TfGuW6DFqf1tinq2YfkqTYEI8Pk8_VYjn4SqL9MvQYhIJFoZSRoQ3gBbkfAuYNIBVw4I-5a5u_AxeafNqHpRgOJHJFKNs9_C9x3KsUT4yy8vA=w2904-h2122"
					alt="Stylized image representing the Buffalo Wild Wings BYOD webapp experience."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="In a world of Covid-19, we needed a robust omni-channel sales strategy."
					person={{ name: 'Suzanne Grow', title: 'Sr. Director of Digital Strategy, Buffalo Wild Wings' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Design sprint meets design system"
					subtitle="An MVP saddled with many fast-follow ideas meant we’d need a design system to scale up."
					reverse={false}
				>
					<p className="paragraph--small">To bring the product from concept to MVP in under 2 months we needed a rapid decision making framework to define the work for executive approval. We used a week-long design sprint (and multiple guest research sessions) to pare down scope, the result of which was to cut profile and loyalty features. After wireframing and prototyping together the remaining key pages, we took it back to the field for feedback before flowing final inputs into higher-fidelty page designs built from a design system. As fast-follow features came up, the design system allowed us to build them out quickly.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXqQumt57B9Em3usZuVaWyDWEalplprzzJ67_k46PN6FyFdEb6Dvm5NXM8QTi5aunvnm1EehozhGAMi5UJ766y8wqDUKVioujoMt0JWd_9cGiZu6kW2XJwn9OfZ56BexlpSdA6EFHoMVmqlq2K31m4MJQ=w2760-h2403"
					alt="Image of example UI components from the Buffalo Wild Wings BYOD design system."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Simplified device verification"
					image={false}
					reverse={true}
					subtitle="A core tenet of the project was to build as “low-tech or no-tech” as possible. QR codes were already a part of our tech stack, so we were able to use this existing service to authenticate and bind guests to specific sportsbar locations.<br/><br/> For guests with a phone number saved to their existing loyalty account, we recognized their profile and synced purchases across products so they could see their purchase history on any device."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUSsBCh6p5EIewQqTQpHaFskVUeo6mx27BxFg1oYocdxLFx8jdFLSnGRdkwtAxhn5sRHbfbtFCk-d0GjNjS836aRjetv7C2MLEQaDPTfdKAgu5yPuMLL2pZpWFZVZLZRXGwI0n8Hln2rjtKqE9MRbc2Jg=w1809-h1974"
						alt="Example screen designs for the SMS verification flow for Buffalo Wild Wings BYOD."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Pay and leave when you're ready"
					image={false}
					reverse={false}
					subtitle="One of the top frustrations shared during research was having to wait for the check when you’re ready to leave.<br/><br/>Given the contactless nature of the product, adding digital payment was an obvious must-have. Guests who preferred to pay with cash could do so, while guests with credit or virtual cards get the full end-to-end sportsbar experience minus a waiter."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLW8TFWp7vGDCoWXZhNtHgNisd_szgikD38QH_s40K_RSjaIUn94VSR3ev3yXSQQxhpcnbjTBzeranze8dTSCqLXq7_mDlIEUSf0Rg1tWeM2a1IfaFUvv8wR6CmxRgLRMHdkq0uxUA03wWwAzMIi8LUtlw=w1620-h1803"
						alt="Example screen designs for the payment flow for Buffalo Wild Wings BYOD."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Tuned and tested ordering"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">Launching the MVP was just the beginning: as more guests used this new part of the Buffalo Wild Wings ecosystem, we gathered more data on the in-bar experience and could surface personalized recommendations to guests based on new dimensions, such as daypart or takeout vs. dine-in habits. When individual guests place subsequent orders on the BYOD product, recommendations are further customized based on their purchase history, frequency, and recency.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVjIQNfEhs87DuhimAAc-eLA-4Ijme8D8JbhKyg--2aB0s8J4lzGSnx0d_8rgCU13BwTcyh6QBw33YZneDBse_tEAjBML1rJajtcSfXCC8vC0OIWH3wuKQsozAGiS2ddXedrzCzMw2_yOBsRTPalWPu9w=w2570-h2608"
					alt="End-to-end shopping design screens for the Buffalo Wild Wings BYOD experience."
				/>

				<Spacer spacing={180} />

			</>
		);
	}
}
