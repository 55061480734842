import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class KentMaggard extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Kent Maggard is a former pitcher for the Cleveland Indians. Unable to fight off the yips any longer, Kent was forced to leave baseball for good. He transitioned into the world of banking, where he quickly emerged as president for several large banks. Throughout his storied career, Kent has seen a lot of fastballs and has mastered the curve. These days, Kent is a motivational speaker and leadership consultant, working with brands the world over to build their best starting lineup, and an even stronger bench team. To help launch his new brand, Kent needed a short video he could share with prospects to get them excited about his story and the experience he brings to leadership development.'
					}}
					details={[
						{ title: 'Year', content: '2014' },
						{ title: 'Client', content: 'KentMaggard.com' },
						{ title: 'Role', content: 'Videography, Script Writing, Storyboarding, Post Production' }
					]}
				/>

				<Spacer spacing={60} />

<iframe src="https://player.vimeo.com/video/58265546?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1100" height="720" frameborder="0" allow="fullscreen" allowfullscreen title="Kent Maggard, Storytelling Video"></iframe>

				<Spacer spacing={120} />				

			</>
		);
	}
}
