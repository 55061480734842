import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class CanaryVenture extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'Canary Venture Company was a start-up accelerator formed in 2013 by myself and 2 other partners, each with unique backgrounds in building digital products and bringing them to market. The premise of our brand kit was to build on stylings and ephemera found in mining operations of early American mining, to drive home the concepts of discovery and cultivation.'
					}}
					details={[
						{ title: 'Year', content: '2013' },
						{ title: 'Client', content: 'Canary Venture Co.' },
						{ title: 'Role', content: 'Naming, Branding, Web Design' }
					]}
				/>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUY32PH45NjWfMYajPV7ak33GpFTA0OSsJ8q5aAv3ab1129wVThF08n7qmlhAYK8Jm0FOeUzDJsHAaCC2BN8xXhB7e-GKQM-xDjxW9IVPDiBFFW1CS5inMKAdxGvSx7OzAlMcRnYa9zDWp0rVp8jx8wyA=w1400-h1010"
					alt="Styled image representing the Canary Venture Company brand."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Hand-crafted stationery" subtitle="" reverse={false}>
					<p className="paragraph--small">In keeping with the overall bespoke aesthetic of the brand, we worked to rely as little as possible on modern printing techniques and opted instead for tools of the trade that were found in the era we hoped to mimic.
</p>
					<p className="paragraph--small">Rubber stamps, ink pads, and hand-cut envelopes meant more manual labor for the team to produce the stationery system, but also resulted in a handmade finished product that recipients appreciated.</p>				
				</CaseStudySection>

				<Spacer spacing={100} />

				<CaseStudyCollage
					imageSources={[
						'https://lh3.googleusercontent.com/pw/AM-JKLXXdUURvigHZEhDkjc6GTs0bjm-iKesg7F7YavZLk832aOGlqEzP1RtXMKSTIclj_-PHrX4O10y-sLpKYwG3XaDQ38je07AkT2cwG6bVbquFXBHrKfZ6CrMtvqg27YTMAL3u-aX-56chp7T7vGGPFH_Rg=w800-h900',
						'https://lh3.googleusercontent.com/pw/AM-JKLXVUpUsFPX60zkMlwwQPsEOsWBXEvtV7B6i7UmXaji5RaQEq-2QU9ebzB0-egRuwTUFsfWQyoTzkJsNWSsWeMvlOUbTUBo3Pf9DaxKSDAhmcT5HsClFvHBM4bun1fQydUV7vatzSoAAJKSOfdW3bnafMQ=w1600-h900',
						'https://lh3.googleusercontent.com/pw/AM-JKLU56k-Ozs1kKG2WAfGuGXyPEYRuus1NnJqeEibIgjEIg3fNMjchH1f98ykkjC4DkoBmez3wPVRYlJgoTk5qr8XSE90Mxu7uycIcWPBjaNZahVjqGVkX-QgJ6Np0bCYub-Z4cpje9eYRAQZaoOrtHZjNnA=w1600-h900'
					]}
					alts={[
						'Photo of the Canary Venture Co. rubber stamp for the brand logo symbol.',
						'Photo of the Canary Venture Co. rubber stamp for the primary brand logomark.',
						'Sample photo of the Canary Venture Co. stationery system.'
					]}
					description={{
						header: 'Analog with a touch of digital',
						content: 'Mohawk 70% reclaimed white fiber paper, rubber hand stamps, and manual-press ink pads were accented by digital press printing supplied by AMP Printing in Nashville, TN.'
					}}
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="As idea nerds, we needed a brand that matched our spirit, and we got it."
					person={{ name: 'Nate Miller', title: 'CEO of Canary Venture Co.' }}
				/>

				<Spacer spacing={180} />

					
				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWOrlBD2VhgrYoKeT6Lej-vUXK847ukpGH6Kqh6XT2cEuCAhBQmTQvqpACU2h2SykJoLBplMXw7EieDOLExVGX8PWxZgkNG51nwsLN2B5WzcxZ6Q5oFcNmQjhvGOz0f0hDOClNX7KIrpPpf8S1lJD4aQg=w1161-h775"
					alt="Photograph of the complete Canary Venture Company stationery system."
				/>				

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXzOvTq2IUvX6LWpM-5m5kVip71he7kxYgAeJXOpDXw762_azppr6oNBHItOp6bR0Y6mzq0wQ7m2MQ25NrxyxK4t5ti-OL-HjpBrzDf00C2dukJRg_3IpLPCf_qVN936IyNAEHgEOn4BLhzs3--B1GW-A=w1161-h775"
					alt="Photograph of fronts and backs for the Canary Venture Company business card design."
				/>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVaz67gQtTyU9w12mCb9ADG8CtEvzxw_A8KBu22-ciY0LAt1qSJd5jY7J3W4xWDu887AjOuvOtnnBSA-uNimVkiBwS8iAp2d127u_Zh5XuGzzV5XVzJeMfv1T0vjI8KBLi5yilN7ZDg31Oh2YcPO_ZHNQ=w1200-h715"
					alt="Sample image of the Canary Venture Company website."
				/>

				<Spacer spacing={120} />

				<p className="caseStudy__reflection paragraph--small">After two back-to-back start-up successes with Speakable
and Built, Canary closed its doors for good as myself and the other partners moved into new roles within our two new companies. The
brand system for Canary Venture Co. earned a number of awards, including a "Gold Medal in Branding" from AIGA’s 2014 “TENN Show” in Memphis, Tennessee.</p>

				<Spacer spacing={120} />
				
			</>
		);
	}
}
