import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Speakable extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Speakable was not only my first start-up, but also my first start-up success. The idea was simple: when will I ever get time to read those hundreds of articles I have bookmarked Knowing the answer was never, I pitched the idea to our team at Canary Venture Co., and within days our developer and I were heads-down building a plug-in to take text from a webpage, transcribe it, and serve it back to users as narrated audio. For publishers, I believe like this would be a revenue gamechanger: translate billions of web articles into audio, then charge advertisers for pre-, mid-, and post-roll ad segments. Within a month we had secured licensing with the largest text-to-speech service provider in the world, within 2 months we had a product to sell, and within 6 months we sold Speakable to the 3rd largest web publishing company in the world.'
					}}
					details={[
						{ title: 'Year', content: 2015 },
						{ title: 'Client', content: 'Canary Venture Co.' },
						{ title: 'Role', content: 'Strategy, Design, Development' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWsNb_5F86Ja7WoPCZDgr065Ic76xnbH2FDxDcL2YinvKV9OK5nNySnfepIjKJQckUMjdzEdtSDdMCaHvNwwP6uBxtk6jfOu-tyeIoq_ANE4AWbmTaD_jnwX_yP4i1LnT4GN-PwkeM3SJVXDoWpKvsA9g=w960-h610-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXOUOINTpXF2cOC9pUjzMxiJkVqW8GrMHsDhsW2jOLmTVZmWcXl3WGo0TDuWwq8PtEFukxF9CLIgspL2Cx2giXTha70S9UQFcYQ9JYx634FdSD-kzUvF21Tw2fUHy7xViwx8YPa5kYJ-YqSCki989TJRA=w960-h540-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXvgI8VdDJ5u1ewexnMzuWPvGCze4OZsjk8EeRkK81eSc3EBT8cD8IfUUB46b4ooYZF--L1s887jL2If4NIC_fhi1M4AQ07HbawvbNANyqvuwS9KpDsxA4kRRsvaGmwTZt_H-n2wKJxxKye2QUlxRJ2GA=w960-h560-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWzJuytdGEjLL9QPz2a320iI95Dwvwzn0hpGTP7JiYhM-F3P74qKPjXHyEBDyL_Uhj0Xz7soh4jT688UBVrBqz1ZmOxWbeN03jPSK-yPDkKxLW0PGcqw0IAMIqBaxVUge85QDdXC7-GtFNWUMr3bvJnQA=w1200-h675-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWNUL2-daUECRReZjqg4QpecDmKLJEC1IQ165wxZH4SzmNwu3pGo9JpFrdV74L_6SWzHfpw2Iv0r_YivahnGwaeVjBcxLSCPxfgteCekxueDEbfHcWuOebqA2DB8nr0igsVlHYmcABubttNa1Eg0kqjiQ=w1200-h675-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUFre3vFVLZoIfNpboh_IZjq7OsuhZFga89OyTjSBhjNcZXZ_xCdFSLenbMNZctlswwA4mVGFFwS_4DX4A6GgQCofhJRCovsAVNM_sJDH5AohgWy06BTFvf7mkCc_Xtk_ct8ptWPhyVllP0TCNF_U4bfg=w1200-h675-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXe8Zn512JnZ2WSoJLmNa7JY39IlPvLqdl7jVPH9PpEugnKV3c6fV7RGNH2_Oia3CyDEuMqryist6gzljtRAhccVfTMUT7gxLbbLP26YJBrTpQGLrbSM4B_cFbWqchCcYRPQ41gbVf9hoqRHKVqVdmQBw=w1200-h675-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVZMwusDlL29Vx4Zum7KrGT_ef267U9Ani3lQs0XHUlmFscvlBtGh4cV0mObaYt7iVbEKtpX5ptUNyn-JAgKjjxlHv1ejO8uXFVr-1oc9FrUr7b8CHXNKiMAOS_PW6p1jQsedtCm0q7wLabU5vHQcEZPg=w1200-h715-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVD1CXUni6qlZc0L0ybIRH5umOXU9ydMYTSx13FxAXCbaqC04Pau4jBUsamqZnDcWzv3ypCWKPJmkqyjQGikrYau8LeLJkcuB2zMqZDCxcSYeIaf-MnN-_ErHkY7xLBw1zSylMiei20ZuPlzRlU2y8FMw=w1200-h1328-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXRvehrc2f2OqpfPbgt6k5W8RHDa7MiOCQ3L4GDpHGyac3goQOz4Xk6TBpBkcohwI5BsZubL_R7xHicJmNTlfXfCIr99P3N_EwALKfQtWkfQhn2QPv5LtU_Ut-VNT8cwIwv894NeOdj5ifb6y0RolWz3w=w1200-h1024-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVc0AKXExjIPc8Hs-KN8e0bGRpcZU9DUWkfSIyd6TaKF3n1sY5V4RlPIrTHpr6VTDDiFXG-7sOMuuJXghjA8DtItOdMWy8ytZEAB63mGR0gRmhRgZzED4ccZVxCn0TsuJkasSjLRmFM9MQ04fdXZt4Vog=w1200-h800-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVecSVJ6yq1t1MO8yv6_g-heA0BdTDE6mQFWXzeXw7LHUgMwxu4eQb8C92vaopO11iLWZSVar6l5EoAgmUnMXmAUmbqfXg3Ye3sJSfXhUtF3ZY6pf9Tjlzbl5IiVjlfNm_X6iMfQc1JswOiQSuWdKeHaQ=w2546-h2082-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUiI_JN_Io_QM-K_DGR40vvDKYTPKhq1tLGyX1F_EA8H4-wD524421y1mKl4_fgN6YYSTpJfmE-Xqg2Nui8EKPeMWNLsOnGZ5zuEvvZNxAV-mbbk8IVkn5aodZQUnzgplzNFKj5DnElV7D2IGZceykuvg=w1800-h555-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUWqX-N53DVpnF7SYKrKu5vEbMLdCvrPyumTPrZEJ6bSBYHzNAPDSAaq6-kKXQOTev8PdfPHl25NJKcux3p5L8YBxWcaa4wpsknqCNmyCt_sF-MHlpBWowCuGGnCGd1-4WxhKvy1qGZHAhWYI3FwWgxgg=w1800-h1430-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUNeB_0KCokTO1obIEG_0NDCn1zlWnsEzhB2EWtdFI9GsXfmIdBee5IYr72-2M3kpyKyYDpcNAc1hrXAI2awFzQoi-U-NSgIPEDj6MB5b0656DPrk7FA_AUncFDhhPsCTUbjKQo1YZ3p0I9sx5M4BUB9w=w1200-h900-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUfn-Wc9koSbQ8LqyUHXAAzhUpt8Y38PwUJBXtKFSuxK5plQjA2kRAgKwU1jbVrAuNbya74hH1s7-ikBZA8PpvfjnsF0Gdc1-243_Rq_E1_PybL1ZRhCUncYWWmIghZlpmd6aYl2tS-R6fSZWajdo4O2Q=w1200-h906-no?authuser=0"
					alt="Speakable sample design by Dylan Mullins."
				/>

				<Spacer spacing={90} />

			</>
		);
	}
}








