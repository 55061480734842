import React from 'react';
import ReactDOM from 'react-dom';

export default class Spacer extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div
				className={
					'caseStudy__spacer' +
					(this.props.spacing ? ` caseStudy__spacer--${this.props.spacing}` : '') +
					(this.props.spacingMobile ? ` caseStudy__spacer--mobile-${this.props.spacingMobile}` : '')
				}
			/>
		);
	}
}
