import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class CaseStudySection extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div
				className={'caseStudy__section' + (this.props.reverse ? ' caseStudy__section--reverse' : '')}
			>
				<div className="caseStudy__section__header">
					<h3 className="caseStudy__section__header__text">{this.props.title}</h3>
					{this.props.subtitle && (
						<p
							className="caseStudy__section__header__content paragraph--small"
							dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
						/>
					)}
				</div>
				<div
					className={
						'caseStudy__section__content' +
						(this.props.image ? ' caseStudy__section__content--image' : '')
					}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}
