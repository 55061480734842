import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class CaseStudyImage extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div
				id={this.props.id}
				className={
					'caseStudy__image' + (this.props.extended ? ' caseStudy__image--extended' : '')
				}
			>
				<img className="caseStudy__image__image" src={this.props.source} alt={this.props.alt} />
			</div>
		);
	}
}
