import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class ChumashMagazine extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'The Chumash Casino and Resort is a top destination in Southern California for gaming and entertainment. Over the course of a year, I worked each monthly issue of its customer-facing magazine, including carrying responsibilities in art direction, design, photography, and copywriting.'
					}}
					details={[
						{ title: 'Year', content: '2005-2006' },
						{ title: 'Client', content: 'Chumash Casino' },
						{ title: 'Role', content: 'Art Direction, Design, Copywriting' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWTrnKPs8zc5VAu92FR7UFKNXB3Xjn2V_Ml3DGv9pI88KDmRvqJlIMrTrWIsGhnivB1tckbTdDwXH6fhOoxJj88IjjzOhTb_n6-qlYCGhgXbB2L_BP45ulbPP3V7XtEsWitRfyh6lcfzPRH6gG0D-2AWQ=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWqBr4X5RjoL3FdBTYDPfKvZjL7Ud4gZWXEltiS9cbUmAvZtJAvCRyJfP2Ba5QiA9AwFEb1EUm-nn-vj7ghKNK5WhUcDFlDWpZZK-mmYlAmIObwS1f-OLUlm8Un0D8y8eTG9_UCiVUTkagDAg-icJ_59w=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU4acxmIauKqjIS0zhTDQ_0eqNoJ3m791YTONImnr3CjjZnJ_jGI_hT7m4JTP2hmnnuaB5joPquPZFkpVhYrEXNwYVEBE8dKb09YefI1IMGZUFG7P2eO8Bfw_sFEEl4AEJ1h1rV_85nbXDn-GSc9oVp3A=w1045-h691-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLX158M0BU_MlqMHBojJEfqooJ7z4XxzmodNdwWIS3ORoN97zbI39-eV7AxDcP_el7ZrtjBKYj9nPS06k3cXQTEm89c4aSOVu8sYB5zrgYo-KusATK0fwmgC4xfR_2PFCoP_KNUeP4UIzMi_0l3WTN5hkg=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU87jLIdMI3OCWCdAestX3YmQR-u8hZzuTI1nuXU3uNZ9nWxE1walFCcihE3C_PF59kHat5y4pW2k_j71qf4z2Y2_yBP5lOpB_mUhYyKEHp7D8fE2eS993NQH_XJEg8P7WiWtgNaVJu8Zt_p0-haR4aSQ=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVTD8AlskxBm5jFrcIRMmrnx5emG5KX-qetS9D2iMNf2VmXIv3bHhaVWBaMHKB1ac1VoW8CaMdyVuZ9XfkmqUAiAoxnxCBojQHY92l7Tlp8bLG4xUAmL7sNeQCYbZaWWIabTainyJnW3syCJCEFFhic0w=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUbMDgTX0fsQHysarp1dqMlHwgP2aKae4lQ6xVLnI47I6q8n72Ii-Kk3AIV1MMAp8dLCA1MirhUWwoOHjEFkP1uGnQdgMsz9moBJau1zAXpvAy2d3XbwMhb1h5k841TA4XGPwW-Ez_qFzMFWlcnUMtthQ=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWH_KKukAmxkcZ-qt6RAo8kjasi2jblH4HFLk8A69ESJyDtjfjutmpnMoYoEr_LCs8vy7RXrh39yTbc3eXskP4xkY1gaWpu53kROJJ8IPhT1E-lUgCJZ6kSj6CH4hE0dnzKkFLRFERXr5MeDA-fMH0qaQ=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />		

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWC8CYDSH60bqp5NHY6xcJfftPHEwyPhlD-nqGuyihAswtDxWlmGxT5HgFFyhL-4GtzF69kK5DikWgFyQtp8q2MT_QEM61eG2u-g0QrkIpB8pdcfD5PJVlwshvRuS3gONunTUio19S63OwKn0lxvMA1SA=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />		

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVqYybzMv1-lngOuFcsVGvmsv2Nhn-lX4JUwwESqoqBXU7bXkGkUhPoet8RgHPBwInY1Vls9UmLaG-BsuIeae6FB1xH79ATTcojLF0z1YrNHAyQGpr6BoP3d7jdW-bCdviLCVVKmwZqvc7DHgqQvwdJZA=w960-h615-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />					

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLURGvKxFs1okNzmr6uNvnjP_kvIZ6-qAt-iAl73XgIhCcd4h17GBzhuojvDpOfq1UNghOIyLWbJEu7V2BFhZyTYRf1KD0FHecBVRlsx4NSMCyFsstmrMvrORIEt4ePEo4A7hENhBRhqBrwU54KAKX2idQ=w1200-h675-no?authuser=0"
					alt="Chumash! Magazine design samples by Dylan Mullins."
				/>

				<Spacer spacing={90} />					
				
				
			</>
		);
	}
}