import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';
import Scrolllock from 'react-scrolllock';

import * as Animate from '../helpers/Animate';
import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class Menu extends React.Component {
	constructor() {
		super();
		this.state = {};

		this.refItems = [null, null, null];
		this.refMenuCircle = null;
		this.refMenu = null;
		this.refContact = null;
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired
	};

	componentDidMount() {
		let transitionPoint = this.props.getTransitionPoint();
		let radius = Calculate.getDistanceToFarthestCorner(transitionPoint.x, transitionPoint.y);
		let offsetX = (40 / 16) * Calculate.getOneRem();

		let timelineMenu = new TimelineMax({ paused: true })
			.set(this.refMenuCircle, {
				top: transitionPoint.y - 2000,
				left: transitionPoint.x - 2000,
				scale: 0,
				opacity: 1
			})
			.set(this.refMenu, { position: 'fixed', opacity: 0 })
			.set(this.refItems[0], { opacity: 0 })
			.set(this.refItems[1], { opacity: 0 })
			.set(this.refItems[2], { opacity: 0 })
			.set(this.refContact, { opacity: 0 })
			.add(() => this.props.updatePositionHome('fixed'))
			.to(this.refMenuCircle, 0.6, {
				ease: 'Mullins',
				scale: radius / 2000
			})
			// .set(this.refMenu, { position: 'relative', opacity: 1 })
			.set(this.refMenu, { opacity: 1 })
			.set(this.refMenuCircle, { scale: 0, opacity: 0 })
			.to(this.refItems[0], 0.6, { ease: 'Mullins', opacity: 1 })
			.to(this.refItems[1], 0.6, { ease: 'Mullins', opacity: 1 }, '-=0.45')
			.to(this.refItems[2], 0.6, { ease: 'Mullins', opacity: 1 }, '-=0.45')
			.to(this.refContact, 0.6, { ease: 'Mullins', opacity: 1 }, '-=0.45');
		// .to(this.refHeaderTitle, 0.6, { ease: 'Mullins', opacity: 1 })
		// .to(this.refContent, 0.6, { ease: 'Mullins', opacity: 1 }, '-=0.45')
		// .to(this.refHeaderLabel, 0.6, { ease: 'Mullins', x: 0, opacity: 1 }, '-=0.45')
		// .to(this.refActiveBar, 0.6, { ease: 'Mullins', scaleX: 1, opacity: 1 }, '-=0.6');

		timelineMenu.play();
	}

	componentWillUnmount() {}

	onExit = () => {
		let transitionPoint = this.props.getTransitionPoint();
		let radius = Calculate.getDistanceToFarthestCorner(transitionPoint.x, transitionPoint.y);

		let timelineExit = new TimelineMax({ paused: true })
			.set(this.refMenu, { position: 'absolute' })
			.add(() => this.props.updatePositionHome('relative'))
			.add(() =>
				Animate.clipReveal(
					this.refMenu,
					radius + 1,
					0,
					`${transitionPoint.x}px ${transitionPoint.y}px`,
					0.6,
					this.context.isBrowserFirefox
				)
			)
			.to({}, 0.6, 0)
			.add(() => this.props.updateRenderStates('menu', false));

		timelineExit.play();
	};

	onMenuItemMouseEnter = index => {
		for (let i = 0; i < 3; i++) {
			if (index != i) {
				TweenMax.to(this.refItems[i], 0.4, { ease: 'Mullins', opacity: 0.1325 });
			}
		}
	};

	onMenuItemMouseLeave = index => {
		for (let i = 0; i < 3; i++) {
			if (index != i) {
				TweenMax.to(this.refItems[i], 0.4, { ease: 'Mullins', opacity: 1 });
			}
		}
	};

	MenuItem = ({ index, to, label, external }) => (
		<div ref={div => (this.refItems[index] = div)} className="menu__list__item">
			<h1
				class="menu__list__item__text"
				onMouseEnter={() => this.onMenuItemMouseEnter(index)}
				onMouseLeave={() => this.onMenuItemMouseLeave(index)}
				onClick={mouse => (external ? {} : this.props.closeNavbarOnNavigation(mouse, to))}
			>
				{external ? (
					<a href={to} target="_blank">
						{label}
					</a>
				) : (
					<Link to={to}>{label}</Link>
				)}
			</h1>
		</div>
	);

	render() {
		const { MenuItem } = this;

		return (
			<>
				<Scrolllock />
				<div ref={div => (this.refMenuCircle = div)} className="menu-circle" />
				<div ref={div => (this.refMenu = div)} className="menu">
					<div className="menu__list">
						<MenuItem index={0} to="/" label="Work" external={false} />
						<MenuItem index={1} to="/about" label="About" external={false} />
						<MenuItem
							index={2}
							to="https://medium.com/@dylanmullins"
							label="Notes"
							external={true}
						/>
					</div>
					<div ref={div => (this.refContact = div)} className="menu__contact">
						<h6 className="menu__contact__label">Contact</h6>
						<p className="menu__contact__email">
							<a href="mailto:hello@dylanmullins.com">hello@dylanmullins.com</a>
						</p>
					</div>
				</div>
			</>
		);
	}
}
