import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Skurt extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Skurt makes the luxury car driving experience accessible for all through on-demand luxury car rentals. Their user base had exploded, but customer lifetime value was in decline. To find out why, they needed a partner to dual-wield research and design. <br/><br/>After conducting remote user interviews, I flew into sunny Los Angeles to run an on-site app usage study, triangulate new opportunities, and add muscle to a week-long design sprint that captured repeat revenue via a new rebooking experience.'
					}}
					details={[
						{ title: 'Year', content: '2017' },
						{ title: 'Client', content: 'Skurt.com' },
						{ title: 'Role', content: 'Research, Product Strategy, UX Design, Prototyping, Mobile App Design' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWfMxPB3GWX4NpyHlXPhgT_-l1NodA72pXniyxOCWMt0-8g1zYLHlQ-FAUjaQSXhnihEZ-5o9C-aCaplTYaW7APpE-bYq8BQUbG-NQjOUebNafKdQRPaKcxYRtHVF7Sg6xh1ndENdAiNcrZLX8uJGegTw=w2298-h1674"
					alt="Four screens of the Skurt app displayed on various iPhones."
				/>

				<Spacer spacing={180} />				
				
				<CaseStudySection
					title="Form-fitting to a  native experience"
					subtitle="Most of the app UI was custom coded, forfeiting key features like app scaling. I proposed we start by updating key flows to take advantage of native code.<br/><br/>Investigating analytics with the team, we identified the top flows, and related jobs-to-be-done, to determine where to start: search, purchase history, and checkout.<br/><br/>The migration created 60%+ code efficiency and 100% experience cohesion across the pages that adopted native UI standards."
					image={true}
					reverse={false}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXCWNHlHY_CYawaSkylouYLo1wxBHUW8NRmHxnkGw_nsn0DSvZje1rEfCHVyp2zZ-pC2yIB5naF4g-x6p2d9Tf2FtMA6TNvnxkFHTQgpxjS2_5ph5bgzdQibZPEW9tET4MAMEDqL3kvqrIEsX5o0sAFsQ=w1102-h1292"
						alt="Before and after view of Skurt's reservation history screen."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="Without researching the needs of our users, we had been making some big assumptions."
					person={{ name: 'Aaron Packard', title: 'Chief Design Officer of Skurt' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Defining a product strategy for growth"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">Off-site research uncovered user feedback was populating around three topics: reservation details were hard to find, the post-purchase flow did little to bolster confidence, and navigating the app was confusing. At the same time, analytics told us repeat buyers were the highest-value segment, so we began digging into repeat buyer flows.<br/><br/>We created sitemaps and flow diagrams to visualize the experience and help brainstorm optimization veins for the rebooking flow. Another layer deep into the data we found that repeat buyers most often began window-shopping from their reservation history page. This insight created the starting point for the rest of the sprint.</p>
				</CaseStudySection>

				<Spacer spacing={40} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWWvEUhkDlv9A1DBKrJZ-qv-GYdFvXaaxX7y88-PFzyINeJ4yPCvC6l0Xr4QsqtsKH1cv_kd4GAvPbOQO1gt0_RwQNb8h79KTd-oNUmb6M14ydl3bWBjkOHaUk-0TXNPmzRw7YCUP3JPKUSTFocaF52cg=w2320-h776"
					alt="Image of a sitemap and user flow diagram for the Skurt app experience."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Reservation history overhaul"
					subtitle="The reservation history view was the top ingress point for repeat buyers. Car images had recently been added, eeking out small upticks for app engagement and purchase conversion. Following trend, I proposed an overhaul to the content and layout of these views as tests for conversion.<br/><br/>Content was stripped back to minimum viable data for each view, and window shopping views were upgraded with bigger car photos, and a more visual, product-centric approach."
					image={true}
					reverse={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWpFMALKPMIgYlUU8DGNx72juKMekNWNMu9KrAOp8CCOdOsxmyX97BTUAst62O6IeT5ILtlCypqj83-rpqUCp_cGDNO5YgOjbPy1RAkLAZU2bgFU5JPBFNBjI7EkEQAC4UiimUd_b46brJ-xTw9ClxRdQ=w1114-h1118"
						alt="From left, samples of Skurt's reservation history and reservation details views."
					/>
				</CaseStudySection>
				
				<Spacer spacing={180} />

				<CaseStudySection
					title="Dashboard & app navigation"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">Getting around the app’s various features and views was confusingly inconsistent. We prototyped three different wayfinding conventions, but users responded best to a Google-esque floating action button concept we tested. It worked across any view, could be easily added throughout the app, and post-launch analysis showed users were spending less time struggling with navigating the app.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWDNNVoRGyd70KeIb9JYNkH8v0AaOci1p1dIGSqzFrOyjWg1RyMBjilBqtWByvF2telrSerz7WzRbT0OCyFIcEh9eS-IttP0gMGV_NVti4epwlChGEwUtd1QDXKL5n2_waAcfnv2Cb2sZ-pxm4Rdomk3Q=w2320-h1270"
					alt="Frorm left, samples of Skurt's reservation details, global menu, and reservation history views."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Designing clearer confirmations"
					subtitle="While other work was being validated through R.I.T.E. method research, we pivoted to the next bucket of feedback: bringing new clarity to the post-purchase flow.<br/><br/>New confirmation views sacrifice beautiful car images in favor of clear language, and obvious next steps. The modal pattern plays up Skurt's brand voice with short, quirky headlines aimed at helping users overcome inattentional blindness."
					image={true}
					reverse={false}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXXOX8PPhvpjI05pFQ7YUXJC3ALQBTLaWE6T5M-eoY54PJWbPJyUeUyg0-mcdZf5-ouTEdK8bgDAfILmO0Fs417USJylad8PJxD0p3hAe4C22kB8-qlys7rw1B8uehrDzpchfsHzqK_agFHIEDy_YUKNw=w1122-h1326"
						alt="Sample image of Skurt's confirmation screens."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="A fresh spin for user onboarding"
					subtitle="With extra time left at the end of the sprint, we updated the onboarding screens to round out the app’s design system.<br/><br/>The new templates rolled out with the next app release, communicating the enhanced designs and features to Skurt's users."
					image={true}
					reverse={true}
				>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX4izpGYfSuNckRbKb_s3KNb3UlCzfpLiVQ4h3GL6lMC189s8mOwZ5h_NqnRWakWEQzs20hT5g90SLrkDReu1pHWlOxOTT0UVG72trfHs5YgweprMt-KaZSuqd4j7pqZJ5BS_hY9avJoE2sGOB7SvoSug=w1120-h904"
						alt="Onboarding and feature discovery screen templates for Skurt."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudyResults
					statistics={[
						{ number: '+17%', description: 'Overall conversion rate increase for repeat buyers during 3-month rollout.' },
						{ number: '-14%', description: 'Total support call propensity reduction of -14% during rollout period.'},
						{ number: '+3min', description: 'Average increase in engagement time with the iOS mobile app.'},
					]}
				/>

				<p className="caseStudy__reflection paragraph--small">Working with data, optimizing existing experiences, and getting hands-on with real users is the type of multi-disciplinary work I enjoy most. Even more exciting were reports from the Skurt team as the positive analytics trend unfolded with more users getting their hands on the new app experience.</p>
				<p className="caseStudy__reflection paragraph--small"><em>Postscript:</em> Skurt was acquired in 2018 by Fair.com &mdash; congrats, team! Extra special thank you’s to Aaron Packard, Garvin Mehrshawd, Timi Svejld, the entire team at Skurt, and all of their users that let us bug them with questions, and wire them up to strange research machinery.</p>

				<Spacer spacing={180} />
			</>
		);
	}
}
