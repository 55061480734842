import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class NAHM extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'The Santa Ynez Reservation is located in Santa Barbara County and was established and officially recognized by the federal government on December 27, 1901. Today, the Santa Ynez Band of Chumash Indians remains the only federally recognized Chumash tribe in the nation. Each year the the Santa Ynez Band of Chumash Indians holds an annual Native American Heritage Month event that uplifts those who foster the well-being of future generations and promote events throughout the month that honor the traditions of their past. In 2005, I designed the event invitation and related printed mailers.'
					}}
					details={[
						{ title: 'Year', content: '2005' },
						{ title: 'Client', content: 'Santa Ynez Band of Chumash Indians' },
						{ title: 'Role', content: 'Art Direction, Design, Copywriting' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUHLVJi-8suYWOVNaqj7m3x-8ayXC8RjcXf1f36IhiU-_8ULRI93THBWkD3xtYtGLHLQUU7klyw8EM3ZRcDPmWWnpepw-4LkaR7mFpzeIb-4CU8awNHyvG3RucN6_x7x_Q5hOH7aeuCY0QAZgOul8U3lw=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUF8PeqkZLd4FXBIlnTvU3Wmv8NA_7sCApS77fHR4RYf7863M0SsBNEYJI3FWGty5AQ4I_vyq19dim_DxcCQyomJW0s5ZDnm16nblcOo8oy12XxeRJm9pBacllkBn9HoUB2vHap7BFFqbXx1MujNEk16A=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV_ph0lEJ_8ARbjyggl6IMRMKq3iH0qYIMxPwMpRLZyI-anVVRlE1oK0mRpCPGIlcuo-yFxtEF4XaUKCJ6FZOc8KQbB_a9an_5FISFm0lC9MkL-scTh0p6pJfMJJwg6nPdQlXUNKib3TdM7PePgwb1ZfQ=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVE7fALzkgK87OI5fAZow6sqRTvktlW9TpOBIVhFbdK8Yn2rhGNOSgFcU7xcOvjtxHt7v1lb7PFT0CdBMOw8grPQ0fzOJEXjWyh64RCyFkhFrP3ec6m1sj0666L2QYmRt4DkuyYrGt9DWqT1bfPuYLGfA=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWYjufgoBuYcZ-AuqCVszVHqmJQqxmW4iWsdJi4Dp-Go5KRjrwZ9kgj1hEbtCV_L1xGuaDyHqFqlCX_q-X0n0M9qF7fRKFuTN97dqpR4dkMzvuEarAE29KQdUopjXzDcNpeYyr8rFLiDMkwYr3IXL3XYw=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUT7LtaS7ETj0vUMD0dztV4nCAtxuQHJQ4hZ5jmBlnkWhSQ-9IB0aOnbo8pXWN1jlBApnUSX_b9sui9-bWTI77zhqDYQln78g60Ih3PzPH9l37qLlxhPREBCuTKR-exoUhN9TSanRfB3OWsxleG9LCQag=w1200-h675-no?authuser=0"
					alt="Native American Heritage Month event invite by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

			</>
		);
	}
}