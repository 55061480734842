import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Edmeades extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'Annual report and investor relations printed material for Edmeades Winery circa fiscal year 2005.'
					}}
					details={[
						{ title: 'Year', content: '2005' },
						{ title: 'Client', content: 'Edmeades Winery' },
						{ title: 'Role', content: 'Art Direction, Design, Copywriting' }
					]}
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLV7Rb0bEYD9W8Pzg6iBIFe_qNmbGjNSeOf8al0o-HL-gKC-9ZsNAWDUBgbuZ_UsSa7rmnYbe3oR7zvTW_TTlafToOjJahXDWTHk1Qrg1ZR5ktEIDGSVxbFr_TZFIiZtB17l8KDlZ9G-uRrPrkZ0q97Row=w1200-h700-no?authuser=0"
					alt="Edmeades annual report sample by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyCollage
					imageSources={[
						'https://lh3.googleusercontent.com/pw/AM-JKLVYvWWI7ypVkJagKrGhQGKkL8gWBf9HfcvHGCwJUXRWwEECjFkZI-QK-tbgyokSPprpl4ZkNsRdgAOSK7yhjcs5TNvoqebYDxLEnrEwgZj0C7zWzNYg648DcycI3CaG93FePjm8VHy--0pOOpUo2oKi0g=w798-h901-no?authuser=0',
						'https://lh3.googleusercontent.com/pw/AM-JKLXU73s6XmJMHzyIJOiM-jzajuSW90vvZk-odORd-gsy2b2kJ5rYSBce5g0wtDgQMzstzR9dUFwns7dcL-9e6sW4GIDHOW5oUUG80HrNvgfjtOtYlO7N2FcJRZaPLh2N-UOoCz1eraWKyRurOX8sfQZs9w=w1671-h893-no?authuser=0',
						'https://lh3.googleusercontent.com/pw/AM-JKLXfDVJjACQhjP29wqvIJr5zKaEbEi5aGMCVP1DNxkbHznOz3eiA8yq0NQv4WjsAGcEbYA1jnddhxMXOoZt1u811_Gb5dlikTuA9yLJSCL_-fmp8C58Np3WHV_9aS_lZsJvO6UazF3tOxZP76PaQTitqHw=w1612-h876-no?authuser=0'
					]}
					alts={[
						'Annual report cover.',
						'Annual report interior folded page effect',
						'Annual report feature illustration with gravure effect.'
					]}
					description={{
						header: 'Clockwise from left',
						content: 'Annual report cover; interior page featuring folded feature card; spot illustration with engraving effect used throughout the report.'
					}}
				/>

				<Spacer spacing={90} />				
				
				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLWWJvwJgeq5cLgLbEDFSwXZZQv2RRAM9jy42fe0rc6sHqvIKqRh1lmQxkw6hAE2o_Ze_y1I9eJKTQU3NJIQYASQOME2_LMS33zLM7RC1g_bsEDxlckzpw2IJXHq7yJdkbMwlMXD5CZ3u0cSPxXKcaA1LQ=w1200-h800-no?authuser=0"
					alt="Edmeades annual report sample by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUNlOofTUQ4vl7kficCbYVBcK0c8h1qb_8NY72xkwGImQrek8iKD0fmvlentLTVCRDam7geS8agHti1n5IFcFHIRfDTSnlM9ngUNO8CDNezd6RfOu2V1yVDpop7D-Xj5NCrWcJ9jUXfAGOSi2KuOmh42Q=w1200-h800-no?authuser=0"
					alt="Edmeades annual report sample by Dylan Mullins."
				/>

				<Spacer spacing={90} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUeDmhmoGFDZ-Jsj0trAu3NttQWFUIawe0X0QJiajY7qgpbnZQqBeoeQNR_X8gdkWLCx966xAypUDlEzpaThg2TIeZE1XnGD20z7CIHKVSQJK5jYfAhNn8D7JmSH-AMCVxwZ6NaYO61c_bi02pm8DTF1g=w1200-h800-no?authuser=0"
					alt="Edmeades annual report sample by Dylan Mullins."
				/>

				<Spacer spacing={90} />	

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUW-d8FeCBoCaDYuxjNZf3LCY1ZZT3bTj-MZvVPY2IoUl2abBzHFOUFgH01tFFgOOS5IMHuHx9MseNH-pr69ym0UHjdR2dk_udVtOINqfAgxT66m-q5CW6Iy10ROFtt2lamxNxs5ayeShf8-r9xi7WEyQ=w1200-h800-no?authuser=0"
					alt="Edmeades annual report sample by Dylan Mullins."
				/>

				<Spacer spacing={90} />	
				
			</>
		);
	}
}