import React from 'react';
import ReactDOM from 'react-dom';

export default class CaseStudySummary extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="caseStudy__summary">
				{this.props.paragraph && this.props.paragraph.__html ? (
					<p
						className="caseStudy__summary__descriptions"
						dangerouslySetInnerHTML={this.props.paragraph}
					/>
				) : (
					<p className="caseStudy__summary__descriptions">{this.props.paragraph}</p>
				)}
				<div className="caseStudy__summary__details">
					{this.props.details &&
						this.props.details.map((detail, index) => (
							<div className="caseStudy__summary__details__detail">
								<h6 className="caseStudy__summary__details__detail__label">
									{detail.title ? detail.title : 'Title'}
								</h6>
								<p className="caseStudy__summary__details__detail__text">
									{detail.content ? detail.content : 'Content'}
								</p>
							</div>
						))}
				</div>
			</div>
		);
	}
}
