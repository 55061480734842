import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import * as Animate from '../helpers/Animate';
import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

import Highbrow from './caseStudies/highbrow';
import Buffalo from './caseStudies/buffalo';
import Skurt from './caseStudies/skurt';
import NoahBasketball from './caseStudies/noahBasketball';
import HealthShift from './caseStudies/healthShift';
import Built from './caseStudies/built';

import Inspire from './caseStudies/inspire';
import Egencia from './caseStudies/egencia';
import DataFi from './caseStudies/dataFi';
import Uberscore from './caseStudies/uberscore';
import CanaryVenture from './caseStudies/canaryVenture';
import KentMaggard from './caseStudies/kentMaggard';
import ApexWorks from './caseStudies/apexWorks';
import SuperiorProsthetics from './caseStudies/superiorProsthetics';
import SkullsRainbowRoom from './caseStudies/skullsRainbowRoom';
import Speakable from './caseStudies/speakable';
import LonelyPlanet from './caseStudies/lonelyPlanet';
import Pathrise from './caseStudies/pathrise';
import NurvStudio from './caseStudies/nurvStudio';
import TenFastFeet from './caseStudies/tenFastFeet';
import ChumashMagazine from './caseStudies/chumashMagazine';
import Oath from './caseStudies/oath';
import CollinsLawFirm from './caseStudies/collinsLawFirm';
import Edmeades from './caseStudies/edmeades';
import Emergynt from './caseStudies/emergynt';
import JobClocker from './caseStudies/jobClocker';
import JumpOnIt from './caseStudies/jumpOnIt';
import NAHM from './caseStudies/nahm';
import NDC from './caseStudies/ndc';
import HiddenHolsters from './caseStudies/hiddenHolsters';
import WaypointTherapy from './caseStudies/waypointTherapy';
import Posters from './caseStudies/posters';
import ArtSchool from './caseStudies/artSchool';
import Logos from './caseStudies/logos';

import CaseStudySuggestions from '../components/CaseStudySuggestions';
import Footer from '../components/Footer';

export default class CaseStudy extends React.Component {
	constructor() {
		super();
		this.state = {
			projectIndex: -1,
			nextProjectIndex: -1,
			previousProjectIndex: -1
		};

		this.refCaseStudy = null;
		this.refActiveBar = null;
		this.refHeaderLabel = null;
		this.refHeaderTitle = null;
		this.refContent = null;
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired,
		isPopNavigating: PropTypes.bool.isRequired,
		isFirstLoad: PropTypes.bool.isRequired
	};

	componentDidMount() {
		let delayLoad = this.context.isPopNavigating ? 150 : 0;

		setTimeout(() => {
			window.scrollTo(0, 0);
			
			let currentProject = ProjectsData.allRoutes.indexOf(this.props.location.pathname.toLowerCase());
			console.log('CURRENT ROUTE', this.props.location);
			console.log('CURRENT PROJECT', currentProject);
			if (currentProject >= 0) {
				document.title = `${ProjectsData.allProjects[currentProject].name} — Dylan Mullins`;
			}
				// console.log('ON CASE STUDY MOUNT', currentProject, this.props.location.pathname);

			this.props.setNavbarColor(this.props.location.pathname.toLowerCase().replace('/', ''));

			// this number is always 1 less than total featured projects
			if (currentProject >= 0) {
				this.setState({
					projectData: ProjectsData.allProjects[currentProject],
					projectIndex: currentProject,
					nextProjectIndex: currentProject == 0 ? 5 : currentProject > 5 ? 0 : currentProject - 1,
					previousProjectIndex:
						currentProject == 5 ? 0 : currentProject > 5 ? 1 : currentProject + 1
				});
			}

			let transitionPoint = this.props.getTransitionPoint();
			let radius = Calculate.getDistanceToFarthestCorner(transitionPoint.x, transitionPoint.y);
			let offsetX = (40 / 16) * Calculate.getOneRem();

			let timelineCaseStudy = new TimelineMax({ paused: true })
				.set(this.refCaseStudyCircle, {
					top: transitionPoint.y - 2000,
					left: transitionPoint.x - 2000,
					scale: 0,
					opacity: 1
				})
				.set(this.refCaseStudy, { position: 'fixed', opacity: 0 })
				.set(this.refHeaderLabel, { x: offsetX, opacity: 0 })
				.set(this.refHeaderTitle, { opacity: 0 })
				.set(this.refContent, { opacity: 0 })
				.add(() => this.props.updatePositionHome('fixed'))
				.to(this.refCaseStudyCircle, 0.6, {
					ease: 'Mullins',
					scale: radius / 2000,
					delay: this.context.isFirstLoad ? 1 : 0
				})
				.set(this.refCaseStudy, { position: 'relative', opacity: 1 })
				.set(this.refCaseStudyCircle, { scale: 0, opacity: 0 })
				.to(this.refHeaderTitle, 0.6, { ease: 'Mullins', opacity: 1 })
				.to(this.refContent, 0.6, { ease: 'Mullins', opacity: 1 }, '-=0.45')
				.to(this.refHeaderLabel, 0.6, { ease: 'Mullins', x: 0, opacity: 1 }, '-=0.45')
				.to(this.refActiveBar, 0.6, { ease: 'Mullins', scaleX: 1, opacity: 1 }, '-=0.6');

			timelineCaseStudy.play();

			// This will be project-route on enter
			this.props.pushHistory(this.props.location.pathname.toLowerCase());
		}, delayLoad);
	}

	componentWillUnmount() {
		document.title = 'Dylan Mullins';

		this.props.setNavbarColor(' ');
		// This will be home route as it exits
		this.props.pushHistory(this.props.location.pathname.toLowerCase());
	}

	onExit = () => {
		let transitionPoint = this.props.getTransitionPoint();
		let radius = Calculate.getDistanceToFarthestCorner(transitionPoint.x, transitionPoint.y);

		let timelineExit = new TimelineMax({ paused: true })
			.set(this.refCaseStudy, { position: 'absolute' })
			.add(() => this.props.updatePositionHome('relative'))
			.add(() =>
				Animate.clipReveal(
					this.refCaseStudy,
					radius + 1,
					0,
					`${transitionPoint.x}px ${transitionPoint.y}px`,
					0.6,
					this.context.isBrowserFirefox
				)
			)
			.to({}, 0.6, 0)
			.add(() => this.props.updateRenderStates('caseStudy', false));

		timelineExit.play();
	};

	render() {
		if (this.props && this.props.location) {
			console.log('PROJECT INFO', this.state.projectData);

			return (
				<>
					<div ref={div => (this.refCaseStudyCircle = div)} className="caseStudy-circle" />
					<div
						ref={div => (this.refCaseStudy = div)}
						className={
							'caseStudy' +
							(this.state.projectIndex != -1
								? ` caseStudy--${this.state.projectData.route.replace('/', '')}`
								: '')
						}
					>
						<div className="caseStudy__container container container--container">
							<div className="caseStudy__header">
								<div className="caseStudy__header__active">
									<div
										className="caseStudy__header__active__bar"
										ref={div => (this.refActiveBar = div)}
									/>
								</div>
								<div className="caseStudy__header__content">
									<h6
										ref={h6 => (this.refHeaderLabel = h6)}
										className="caseStudy__header__content__label"
									>
										Case Study
									</h6>
									<h1
										ref={h1 => (this.refHeaderTitle = h1)}
										className="caseStudy__header__content__title"
									>
										{this.state.projectIndex >= 0 && this.state.projectData.name}
									</h1>
								</div>
							</div>
							{this.state.projectData && (
								<div ref={div => (this.refContent = div)} className="caseStudy__content">
									{this.state.projectData.route == '/highbrow' && <Highbrow />}
									{this.state.projectData.route == '/buffalo' && <Buffalo />}
									{this.state.projectData.route == '/skurt' && <Skurt />}
									{this.state.projectData.route == '/noah-basketball' && <NoahBasketball />}
									{this.state.projectData.route == '/healthshift' && <HealthShift />}
									{this.state.projectData.route == '/built' && <Built />}

									{this.state.projectData.route == '/inspire' && <Inspire />}
									{this.state.projectData.route == '/egencia' && <Egencia />}
									{this.state.projectData.route == '/datafi' && <DataFi />}
									{this.state.projectData.route == '/uberscore' && <Uberscore />}
									{this.state.projectData.route == '/canary-venture' && <CanaryVenture />}
									{this.state.projectData.route == '/kent-maggard' && <KentMaggard />}
									{this.state.projectData.route == '/apexworks' && <ApexWorks />}
									{this.state.projectData.route == '/superior-prosthetics' && (<SuperiorProsthetics />)}
									{this.state.projectData.route == '/skulls-rainbow-room' && (<SkullsRainbowRoom />)}
									{this.state.projectData.route == '/speakable' && <Speakable />}
									{this.state.projectData.route == '/lonely-planet' && <LonelyPlanet />}									
									{this.state.projectData.route == '/pathrise' && <Pathrise />}
									{this.state.projectData.route == '/nurv-studio' && <NurvStudio />}
									{this.state.projectData.route == '/ten-fast-feet' && <TenFastFeet />}
									{this.state.projectData.route == '/chumash-magazine' && <ChumashMagazine />}
									{this.state.projectData.route == '/oath' && <Oath />}
									{this.state.projectData.route == '/collins-law-firm' && <CollinsLawFirm />}
									{this.state.projectData.route == '/edmeades' && <Edmeades />}
									{this.state.projectData.route == '/emergynt' && <Emergynt />}
									{this.state.projectData.route == '/jobclocker' && <JobClocker />}
									{this.state.projectData.route == '/jumponit' && <JumpOnIt />}
									{this.state.projectData.route == '/nahm' && <NAHM />}
									{this.state.projectData.route == '/ndc' && <NDC />}
									{this.state.projectData.route == '/hidden-holsters' && <HiddenHolsters />}
									{this.state.projectData.route == '/waypoint-therapy' && <WaypointTherapy />}
									{this.state.projectData.route == '/posters' && <Posters />}
									{this.state.projectData.route == '/art-school' && <ArtSchool />}
									{this.state.projectData.route == '/logos' && <Logos />}
								</div>
							)}
							{!this.state.projectData && (
								<div ref={div => (this.refContent = div)} className="caseStudy__content" />
							)}
						</div>
						<div className="container container--extended">
							<CaseStudySuggestions
								suggestions={
									this.state.nextProjectIndex >= 0 && this.state.previousProjectIndex >= 0
										? [
												ProjectsData.projects[this.state.nextProjectIndex],
												ProjectsData.projects[this.state.previousProjectIndex]
										  ]
										: [
												{
													name: '',
													description: '',
													route: this.props.location.pathname
												},
												{
													name: '',
													description: '',
													route: this.props.location.pathname
												}
										  ]
								}
								currentProject={this.state.projectData}
								navigate={this.props.navigate}
							/>
							<Footer light={true} />
						</div>
					</div>
				</>
			);
		}
		return 'CaseStudy';
	}
}
