import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class ApexWorks extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'ApexWorks is a roofing service agency based out of Tennessee partnering with homeowners and insurance companies to simplify roofing repairs resulting from weather-related damage. I workshopped with the owners in their first days of building the business to name the company, then created their visual brand by conducting competitve and SWOT analyses that helped guide the initial business strategy.'
					}}
					details={[
						{ title: 'Year', content: '2011' },
						{ title: 'Client', content: 'ApexWorks' },
						{ title: 'Role', content: 'Naming, Branding, Strategy' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUCThfo7UTEIizBHtHPD0ATL_Um3kOVmNboipIbJ1asp9ZWYPj6lAwiPYNSK5qsOWjDw_v6hQJ4fLuRwFLfF0ldpmplbID8LZePDzzh2-89XCCvJXAa6iw2SeIvNkGFuEpAr6y3IOsVaj6o5AWnCOqZMQ=w1200-h778"
					alt="Image of printed business cards for ApexWorks."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="Pun intended: the team really nailed the name and brand."
					person={{ name: 'Josh Stockton', title: 'Founder and CEO, ApexWorks' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Simplified brand standards"
					reverse={false}
				>
					<p className="paragraph--small">We created a simple set of brand standards to guide some of the additional marketing assets our team would create, but also the various signage, t-shirts, and other items the ApexWorks team would ultimatley source themselves. After developing out the brand foundation, the primary task was applying the new brand to stationery, apparel, van wraps, and a brochure the company would use to promote the ApexWorks brand during their sales campaigns.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLU2L_gzrky6C67tUfpOlo9gRr13pAq5kSHjY4pLTvbxXbTF3MRFkDUqenB_O1goDHaBjWxcEMq2lQVuM_3A10pjzz4C-CflmxEPff1Rz9aL4FJDgqbV4bEZaeaO7bpw8I6DRssxSyw_mRUcoI2ZwkI-VQ=w1200-h1696"
					alt="Sample image of the brand standards for ApexWorks."
				/>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXMfpzF_AHX19qzvkYuOK729rVzlk8JlUAoCuZt7eazlJd3hB78sWbToCpPukoAgDQAMFClKIq2TVgRBicESAEsfpF-ku8uZhxRtkPXxWjAt-Ot64x6SvdmmSLL9kn9FaFMEtKzx4aRyEsu8xGezJX9rw=w1200-h800"
					alt="Example printed business card design for ApexWorks."
				/>

				<Spacer spacing={120} />

				<p className="caseStudy__reflection paragraph--small">In 2019, the company needed to rebrand to match a new service model that included most major categories of water damage remediation. I was happy to help them with the brand again after so many years, and everyone was pleased with the new name, AccuWorks. In their new model, AccuWorks runs multiple offices in the Middle Tennessee region, boasts 30+ employees, and enjoys the revenue growth from their expanded service portfolio.</p>

				<Spacer spacing={120} />

			</>
		);
	}
}
