import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Highbrow extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>

				<CaseStudySummary
					paragraph={{
						__html:
							'After experiencing the difficult loss of his mother to cancer, Highbrow founder David Trujillo set off on a mission to teach the world about the unsung benefits of cannabis and medical marijuana for those dealing with illness. Our work together began by creating the building blocks of his new brand: a name, a slogan, a logo. After setting the foundations in place, we researched, designed, and launched a web store focused on helping people find holistic methods and natural products to support their health needs.'
					}}
					details={[
						{ title: 'Year', content: '2019' },
						{ title: 'Client', content: 'Highbrow Group' },
						{ title: 'Role', content: 'Naming, Branding, Web Design, E-commerce, Social Media, Business Strategy, Print Design, Packaging Design' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUX5qHUbrHmwfeeCdXnvYHjRCjuAxacdJBECAVBCQdMFWaMf4AL9bJ-6iv5QSfn8D_caTA_vVH6T4-Rsu5ssfHbdq1D3PQ4DdDPSDRvOxv2GAd6cbe_UjbAFLiEDH_H5cH875LxO80w5T8gLjtkN6D_1Q=w3480-h1875"
					alt="Stylized image of balloons with the Highbrow logo on them."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="Real people, real stories, and real healing: that’s all we strive to be about."
					person={{ name: 'David Trujillo', title: 'Founder of Highbrow' }}
				/>
				
				<Spacer spacing={180} />

				<CaseStudySection
					title="Establishing a name and brand identity"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">I arrived at the name Highbrow as a ploy for wordplay, hoping to pull off a sly wink to the CBD and MMJ space, while staying true to the knowledge-centric mission of the company. The brand that followed spirits the same enthusiasm: a curious fox as its figurehead, a youthful color palette that bucks worn-out aesthetics found in the industry, and a straight-forward slogan that stays honest to the brand’s core calling: “Real people, real stories, real healing.”</p>
				</CaseStudySection>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXSnBsY7qTrp5k-asCxVKH01cYhZXV1TMnm-ms18m4at_vjr7RLQQqA8ZRXDL5zGDXOwdbTpRipa3a6w8qX1hGK25f3uaEpq17aXF7TUJZuIMOZn97-8k1YG18jU_ljcW9cM0iNPxenF3Tsy_27GlIX0g=w2760-h1500"
					alt="Sample image of the Highbrow brand standards manual."
				/>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLW6tu6-K-WsRpEfik0tRIj5GH983o8FhvFZr9CBMZ1CAYZ8OhDtr-4p2wURoQASWc3DPT5IJUySHSYO76Mnfu45krHnRyTAMQRxx81G6YhhmWhz9QqqKscfWOJGpDZ9fpO1Da9oppBNQVyJETWxbu7UFQ=w2760-h1908"
					alt="Graphic styles and logo components from the Highbrow brand identity platform."
				/>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUa28A23ICgxNJE9mekBHzcfdDryDL47qAHn6tBaGOeAphYiiDFoUUP-xaZZd4zG1VDWpWNAwhr2zHUfaVl-AUS04aXVViPqz3AvmTjO7KLCzI9Oav0cwngCaqatJOAz2KeGtk5GGVx2i_YatZJjsfH_w=w2760-h1803"
					alt="Various samples of the Highbrow stationery design system."
				/>

				<Spacer spacing={180} />
				
				<CaseStudySection
					title="E-commerce strategy & design"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">After user-testing a short-lived web community, we found that while members liked the community-sourced stories about CBD- and MMJ-based symptom management, they preferred for those insights to be attached directly to products they could buy. After a few months of design, development, copious legal research, and additional user research, we launched a webstore of 3 product lines: Soothe, Calm, and Uplift.</p>
				</CaseStudySection>

				<Spacer spacing={120} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLVMuvYOVYPG6Osbt61dg0koPDzRWKM3eIyPTkJPIMaBbiyJP51wpqEqenEKfY45IvZOXh3tigagwb8WDAfHHBuDPC0hmV2Zsgb8-S0wj61sLil29D51jRQs-13dZ4oRK_sF8fVnH65M8JIb0KK8ev67KQ=w1562-h2608"
					alt="Three sample images of the Highbrow e-commerce website design."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Consumer packaged goods"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">In parallel to building a new webstore, we needed to actualize the evolved brand with a tangible product offering. Through partnerships with CWI Consulting and our sister company, Healing Harvest Growers, we spent a full quarter conceptualizing, producing, and packaging a suite of CBD- and MMJ-enriched goods. While not all products shown were produced, these, and other samples, demonstrate the company’s product ambitions.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUxhkNWRFk6QsStkqUOZHQp8vG53zktec_lFOmTgRkrbhmBuMnj-NNZVu5_WIuUhJlPMROU2nQgMKlzuNHi-NhKllOEmX29l-ecfLFqpSZoRmBWst57hxYxsJlCnrYkkPsIhy9ZeyicnR68KLiHpOq6Qg=w2084-h2608"
					alt="Six sample images of various Highbrow consumer packaged goods such as gum, drinks, and breakfast bars."
				/>

				<Spacer spacing={120} />

				<p className="caseStudy__reflection paragraph--small">The aggressive upstart ambitions of the Highbrow team were temporarily put on hold in 2020 due to the Covid-19 global pandemic. As 2021’s harvest season comes into view, and demand from Highbrow users continues to grow, the team expects a full return to operations by 2022. In the meantime, the team is exploring partnerships with other growers and processors, and keeps the fire for knowledge burning through their online community and the various social media channels they maintain.</p>

				<Spacer spacing={120} />

			</>
		);
	}
}
