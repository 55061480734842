import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import { TransitionGroup, Transition } from 'react-transition-group';
import _ from 'underscore';

import Project from '../components/Project';
import Footer from '../components/Footer';

import * as Animate from '../helpers/Animate';
import * as Calculate from '../helpers/Calculate';
import * as ProjectsData from '../helpers/ProjectsData';

export default class Home extends React.Component {
	constructor() {
		super();
		this.state = {};

		this.refHome = null;
		this.refProjects = [];
		ProjectsData.projects.forEach(project => this.refProjects.push(null));
	}

	static contextTypes = {
		isBrowserFirefox: PropTypes.bool.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isDesktop: PropTypes.bool.isRequired,
		isFirstLoad: PropTypes.bool.isRequired
	};

	componentDidMount() {
		for (let i = 0; i < this.refProjects.length; i++) {
			if (this.refProjects[i]) TweenMax.set(this.refProjects[i].refProject, { opacity: 0 });
		}
		TweenMax.set(this.refArchive, { opacity: 0 });
		if (this.refContact) TweenMax.set(this.refContact, { opacity: 0 });
		TweenMax.set(this.refFooter.refFooter, { opacity: 0 });

		let loadDelay = this.context.isFirstLoad ? 1.8 : 0;
		let timelineLoad = new TimelineMax({ paused: true });
		timelineLoad.to({}, loadDelay, {});

		if (this.refProjects[0])
			timelineLoad.to(this.refProjects[0].refProject, 0.4, { ease: 'Mullins', opacity: 1 });

		for (let i = 1; i < this.refProjects.length; i++) {
			timelineLoad.to(this.refProjects[i].refProject, 0.4, { ease: 'Mullins', opacity: 1 }, '-=0.3');
		}
		if (this.refArchive) timelineLoad.to(this.refArchive, 0.4, { ease: 'Mullins', opacity: 1 }, '-=0.3');
		if (this.refContact) timelineLoad.to(this.refContact, 0.4, { ease: 'Mullins', opacity: 1 }, '-=0.3');
		if (this.refFooter)
			timelineLoad.to(this.refFooter.refFooter, 0.4, { ease: 'Mullins', opacity: 1 }, '-=0.3');
		timelineLoad.play();
		// Animate.clipReveal(this.refHome, 0, 500, '75% 75%', 2, this.context.isBrowserFirefox);
	}

	componentWillUnmount() {}

	updateProjectsOnMouse = (activeIndex, onMouseEnter) => {
		for (let i = 0; i < ProjectsData.projects.length; i++) {
			if (i != activeIndex) this.refProjects[i].onOtherProjectMouse(onMouseEnter);
		}
	};

	offsetScroll = scrollY => {
		TweenMax.set(this.refHome, { y: scrollY });
		this.props.offsetScroll(scrollY);
		// this.refHome
	};
	// updatePosition = position => {
	// 	TweenMax.set(this.refHome, { position: position });
	// };

	ArchivedProject = ({ name, route, hasCaseStudy }) => (
		<div className="archivedProject">
			<h3 className="archivedProject__name">
				{hasCaseStudy ? (
					<Link
						className="archivedProject__name__link"
						to={route}
						onClick={this.onArchivedProjectNavigation}
					>
						{name}
					</Link>
				) : (
					<a className="archivedProject__name__link">
						{name}
					</a>
				)}
			</h3>
		</div>
	);

	onArchivedProjectNavigation = mouse => {
		// this.isProjectNavigating = true;
		if (!mouse.metaKey && !mouse.ctrlKey && !mouse.shiftKey && !mouse.altKey) {
			this.props.updateRenderStates('caseStudy', true);
			this.props.setTransitionPoint(mouse.clientX, mouse.clientY);
			this.props.updateNavbarOnTransition();
			this.offsetScroll(-Calculate.getScrollPositionY());

			setTimeout(() => {
				// this.isProjectNavigating = false;
				// this.onProjectMouseLeave();
				this.offsetScroll(0);
			}, 600);
		}
	};

	render() {
		const { ArchivedProject } = this;

		return (
			<div ref={div => (this.refHome = div)} className="home">
				<section className="home__projects container">
					{ProjectsData.projects.map((project, index) => (
						<Project
							ref={el => (this.refProjects[index] = el)}
							location={this.props.location}
							name={project.name}
							tags={project.tags}
							description={project.description}
							route={ProjectsData.routes[index]}
							index={index}
							updateProjectsOnMouse={this.updateProjectsOnMouse}
							updateRenderStates={this.props.updateRenderStates}
							updateNavbarOnTransition={this.props.updateNavbarOnTransition}
							setTransitionPoint={this.props.setTransitionPoint}
							offsetScroll={this.offsetScroll}
							getHistory={this.props.getHistory}
							pushHistory={this.props.pushHistory}
						/>
					))}
				</section>
				<section ref={section => (this.refArchive = section)} className="home__archive container">
					<h6 className="home__archive__subtitle">Archive</h6>
					<div className="home__archive__content">
						{ProjectsData.archives.map((archivedProject, index) => (
							<ArchivedProject
								// ref={el => (this.refProjects[index] = el)}
								name={archivedProject.name}
								route={archivedProject.route}
								hasCaseStudy={archivedProject.hasCaseStudy}
							/>
						))}
					</div>
				</section>
				{this.context.isDesktop && (
					<section ref={section => (this.refContact = section)} className="home__contact container">
						<h6 className="home__contact__subtitle">Contact</h6>
						<div className="home__contact__content">
							<div className="home__contact__method">
								<p className="home__contact__method__text paragraph--small">
									<a href="mailto:hello@dylanmullins.com">hello@dylanmullins.com</a>
								</p>
								<p className="home__contact__method__text paragraph--small">
									<a href="tel:1-615-972-5200">+ 1 615 972 5200</a>
								</p>
							</div>
							<div className="home__contact__method">
								<p className="home__contact__method__text paragraph--small">
									<a href="https://unsplash.com/@dylanmullins" target="_blank">
										Unsplash
									</a>
								</p>
								<p className="home__contact__method__text paragraph--small">
									<a href="https://vimeo.com/dylanmullins" target="_blank">
										Vimeo
									</a>
								</p>
								<p className="home__contact__method__text paragraph--small">
									<a href="https://www.linkedin.com/in/dylanmullins/" target="_blank">
										LinkedIn
									</a>
								</p>
							</div>
							<div className="home__contact__method">
								<p className="home__contact__method__text paragraph--small">
									Atlanta, Georgia 30022
								</p>
								<p className="home__contact__method__text paragraph--small">
									United States of America
								</p>
							</div>
						</div>
					</section>
				)}
				<Footer ref={footer => (this.refFooter = footer)} />
			</div>
		);
	}
}
