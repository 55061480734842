import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class Logos extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'Since 1996, I’ve had the pleasure of working with a wide variety of startups, established companies, musicians, and other creators to help name and/or brand their entity. After 50+ names, 300+ logos, and 20+ years later, below are some of my all-time favorites.'
					}}
					details={[
						{ title: 'Year', content: '1996 - 2018' },
						{ title: 'Client', content: 'Various' },
						{ title: 'Role', content: 'Naming, Branding' }
					]}
				/>

				<Spacer spacing={120} />

				<CaseStudySection
					title="Built Technologies"
					image={false}
					reverse={false}
					subtitle="The world’s first construction lending platform. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVkyvLqXsvndQg1TkqHDHdsLi-QJkFkZdcPlzElk6CzbaMHBF_4M6rDb5URczltakm_EIzxUXAzkzzecZzEfAFNUWsKqWUMyWkLxcMpCwk-weOlulJeMvQWanvNBy7W-uB5KyDi5r49XKRUX7OXGmnlhA=w1120-h322-no?authuser=0"
						alt="Logo for Built Technologies."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Sidecuts"
					image={false}
					reverse={false}
					subtitle="Tapas and mixology watering hole featuring live DJs. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVT9gnhGPOlgRuRSs_5Cem_tRr87BJ3W9-GDCatpYtLCP4L4IWCgSFG06guwPiXDrHmKAIGs1xf3ciEWEbMHXZnCIZMJm_WCYrMhipheL0C_OIHbnrB6b0ygbHcCzd6dAC5ifn3JkuqEYSfe27xteNccQ=w1120-h322-no?authuser=0"
						alt="Logo for Sidecuts restaurant and bar."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="XOYO Clothiers"
					image={false}
					reverse={false}
					subtitle="All-natural, handmade apparel from Japan. Circa 2016."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUgBBZiZYNNGVE9y8Uou9qpSAA1eddx5_KdS8c2B_7FTkkDkyxfV5m2xHctc5rHPah7oYdsBGvzXzwpN36u4RMrQ3YUM7Fqq_HWNLb62IQ4zgawmF6hwpK-7LK5IHgSZFD1yOlTvw6D_y5-pG3l0JoiSw=w1120-h322-no?authuser=0"
						alt="Logo for XOYO Clothiers."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Canary Venture Company"
					image={false}
					reverse={false}
					subtitle="A business accelerator created by startup veterans. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWKrU2ndv6LmmCAqFkXm1GL4_NPvsfsbPkPFYKSwWmkkyTEhQeId5Fz9oKN7k1xD5uXNQoUAExG-_qpBGjjHCpRfV3UitzkPGdGFjd-Th1IbHJ0wc3QP-QiqYNvrlgzoskg4p2m2XjcmZiAEK34w4mOFA=w1120-h322-no?authuser=0"
						alt="Logo for Canary Venture Company."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Signal Path"
					image={false}
					reverse={false}
					subtitle="Agency focused on video, motion, 3D, and audio production. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXe8NQdnEn6figa66WZd8RT98Z0W956jXcoWJkdUbNtI40csr9KpQf-6bCLvr-HhOjXsWCNIrzKYa2Tma4JhZjoS-GeGi6BzO-DcOIDrBLlddGJIMbgRp4543kYkH15iBW96T4YFwL7gnoRYK5VRynZpw=w1120-h322-no?authuser=0"
						alt="Logo for Signal Path."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Personal"
					image={false}
					reverse={false}
					subtitle="Logomark update for my freelance ventures. Circa 2016."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWMR8CEeEKhQsH9IhWQSJmTpLGDprvUsksLbL5rdu6uzwYva8D2yEL8t0Vurft9sjungb2C_HbXDKDhmLCjU626XW-_T1ONLiaS6CMJPwmmBKLFVZUkUVEJ2D9KwnhwHMqI1WWL6Jr43avHFuyfV9VXyg=w1120-h322-no?authuser=0"
						alt="Logo for Dylan Mullins."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Filter Communications"
					image={false}
					reverse={false}
					subtitle="GSA-listed disaster management and emergency communications consultancy. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWcyPE-qmZ0Sc_9NO-caE_-wy2I_5XOp8dQ_2bTkyj94ct-OYezRIyb8mkngNFjTQ9jN_uDvRn7zyn7XisUE4wEZVpSZMz3P6ANbXJGNY43mF8S5xENAjLB0djoXuZolLP87S-ZHeNFyhcgS1DghbrWPQ=w1120-h322-no?authuser=0"
						alt="Logo for Filter Communications."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Amhurst Bakery"
					image={false}
					reverse={false}
					subtitle="Small batch baked goods from Athena M. Hurst. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLW7AXGg0ZKEw145ZGbScdPfl-2YJyzvrx75kaA9rVNqR0IEjxdr1gxLn71vtgiWjQMM2gD9_GQ0nF8trFsuAc30x9wI22fACt-0zULgoCwgnHtcpv6zurxJEQW4Bk-o6qTs-zsaus3iD5N34y3vn2CLQA=w1120-h322-no?authuser=0"
						alt="Logo for Amhurst Bakery."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Emergent Environmental"
					image={false}
					reverse={false}
					subtitle="Solid waste and recycling programs consultancy. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWnPgzNDE_lbyRudrCYpRrr-d46OM5YVPDBLGWS7h_iEhIgbsTHwl6eQD7DjZLEDcSi0t2NuK5QMkXiMMkY5l7R0TaUq_V89bYCsz9DR5zaAh9_AJaGwbjzoCQo3ikGCyBw6ZNs_NZ-tBEMl6nm-BVwDQ=w1120-h322-no?authuser=0"
						alt="Logo for Emergent Environmental."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Thirst Restaurant"
					image={false}
					reverse={false}
					subtitle="Small plates, mixology, and regular live music on 3rd Street in NYC. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXD2Nkcrpiw8FgxIQL6k3iUpDSDr1cNd6gBkbkb3gyHStA0q8AO1Gdm6s4zhh-B1yiKxgdiUJJZVpCm015Yn4JAd45SKeRIzkx4VsYMLf-Pq8Y1H38CRnmfeglIvtQ7-v-yqfO2cwZB7Lyr_U187eA8sQ=w1120-h322-no?authuser=0"
						alt="Logo for Thirst Restaurant."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Weathertite Roofing"
					image={false}
					reverse={false}
					subtitle="Custom tin roof fabrication, machining, and installation. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUa3KJE18b1aMvEITb7vC8gojz3G3LRuT5--_x5YJ5xW2S6FQcCITS4EiC5_zz7ERDMosNX0ep9v78dmH65qDosR56EOOD-TlXc6pS5TQBECen-chdbk4QPuzjFxCMX-Tpy_3j8xUG9Ybd7V0OTwqb7Dg=w1120-h322-no?authuser=0"
						alt="Logo for Weathertite Roofing."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Onetribe.net"
					image={false}
					reverse={false}
					subtitle="Organic body jewelery and body modification e-commerce site and community. Circa 2004."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXVraHUQxBO7IpOtArLd7vjKiFHBwotaMdDqoGCOMs-N7SBnsCuRBsVyMvm3C1jLkIEMMtxw2wIrDHph_8rOtDGqT3wSdd9QneEHc87VN01NTP8R8yojxajczF_MZpl9pc6rBVz_yJvt-SqLJNB_KYX4g=w1120-h322-no?authuser=0"
						alt="Logo for Onetribe.net."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Ted's Creative Company"
					image={false}
					reverse={false}
					subtitle="Marketing agency with a blue-collar personality. Circa 2005."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUi8Wb6r_PhzYnA6i5iC1GU2tPS7nW6cAplKHcDKrCO2wUUD6g30SGDkpqDN_fsT49QQSEh3jqza88NK3qrvxYhSnzN9B2Yi4p0PskMvc59e_Uyv7iESmoTTlWWl1yKoGSiWRw22QAU2wcwI3L5Ay8-Iw=w1120-h322-no?authuser=0"
						alt="Logo for Ted's Creative Company."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="American Morning"
					image={false}
					reverse={false}
					subtitle="AM radio station featuring jazz and political chatter. Circa 2007."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLU5WiMfqHaIL-Eve-LchOb9tCY8b2EqaYVeAYtuHYWMQntljNYVMJ6GfYtzq2Y4hUn0Fwl2OqxMTBtiUrF_-gT1Nxlzy6CJfKXVoLISXjBE9o40YJ4vEH6gcmL9YKyHLIQE9HUIg2xEWBiY8u8VtKsyhQ=w1120-h322-no?authuser=0"
						alt="Logo for American Morning AM Radio."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Mitre Consulting"
					image={false}
					reverse={false}
					subtitle="Leadership training and strategic management consulting. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXvOOtjQvvxt4g8LE_gYzE-JqliUrv87YfHYJFQni_jkiA-IwpM2tIOADbvSRATxxhRIpAhgpiDKKBFb-QwPyxObddQeIjJHzDX1dpalp4HkYXq3XsYv7vS6igphZC4iTTSlXk8uk3aKI3_qmL2yKYIXg=w1120-h322-no?authuser=0"
						alt="Logo for Mitre Consulting."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Ten Fast Feet"
					image={false}
					reverse={false}
					subtitle="Full-service marketing agency with a focus on digital. Circa 2012."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX-gUchmT3cL6kPxd_g5LIdcvFEArxMW0gEvnlfqVIaTqWB3qSQykJBZINGUI6rmPjSJ6G_uDYsjXsGX1SNzyvEyLJQqlEb7k_5jJJ98Hlbx2nA7W9ibzBihJhADHC8GkM0e4vpxVC00eoomm47rdHJ9Q=w1120-h322-no?authuser=0"
						alt="Logo for Ten Fast Feet."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Dragamar Elixirs"
					image={false}
					reverse={false}
					subtitle="Hand-extracted essential oils with zero-waste packaging. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUK657h1WZA_IoRJqb3mDxjghNs-VitXdHDNPPmDCdrKSUw1unlDLiH1rDKBYd5tCxa7DpfslxnSQwJDowQfzHpTAjt5tDIocrlFQqaFmHIJd4LEsrQqqyCtm6ugY-vHeOo_bJppqDlN0Uw8dmJwpmLsQ=w1120-h322-no?authuser=0"
						alt="Logo for Dragamar Elixirs."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Airtite Industries"
					image={false}
					reverse={false}
					subtitle="Titanium fastener design and machining services. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX1lfP65lWXTqVxv1tFnoZsAD7cAabZEHlUu-I-him8INlEoz9ZGXRw-i_vZbL0W0KjIuNSP_3oA1Xo64fp07uEFQxxh_Ds5Qkw81pLp5ikjdRgG0u06Z3AY8RMIdnm28LumuncxXBDPyJTLCYswvmW2A=w1120-h322-no?authuser=0"
						alt="Logo for Airtite Industries."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Ektithas Gas"
					image={false}
					reverse={false}
					subtitle="Indian natural gas provider and equipment servicer. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXhFox6-MBJvOmaYs3-HqyfLFlf0Fmf1o5zkjnBPjeMK_zsNM3bwP6-ncFzj8C8tfL5Q0mfCianIsNZvfLvIXRejq8eZngDb8vSOkHKOPz29ezcg5sv7FSXXPJqWMO0MHdBlf3ddOgT4tbBjeTPFYGiew=w1120-h322-no?authuser=0"
						alt="Logo for Ektithas Gas."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Drucker Electric"
					image={false}
					reverse={false}
					subtitle="Licensed electricians serving the greater Nashville, TN area. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWK79YLM8LoPY0d7ArkKVjpR_OFies5tsfVB_dhYUY5DXtvx6RQXFMPaNz0GG8d08RKYISEyN08a_q-EG-cUG4VI5vY9lnBxbmERbP6K_DkOmgsNVxg00GuFbBspZuwoKFNe7sALFxHevQqHsmpsLSAxQ=w1120-h322-no?authuser=0"
						alt="Logo for Drucker Electric."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Uberscore"
					image={false}
					reverse={false}
					subtitle="Real-time scoreboard watching app for middle and high school sports. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUCafZQFtBL1ketAgUX2pdGvDASURH0nHCTv8V9XmAOHXSxT6_TLNzmOGewvqgdNbfv_hC_avz85FCqzUL2GRLTUMl5U3uvvsIY0jQ-nGPyREY61713sZ1ONHyGFGYSrZpxcnlkYvgR-xfJEOyU8QSyYQ=w1120-h322-no?authuser=0"
						alt="Logo for Uberscore."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Goin' Donuts"
					image={false}
					reverse={false}
					subtitle="Donuts, coffee, and other delights served fresh daily for local college community. Circa 2004."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUy9fbcxTxY1koB6g4yA-liC5RMgp0uWXdBNyXNfYcHNEmEw1DRKKN66vd_HfcvZwiOdEwWDJYM6X2y6oqpAMxBqtlmLKXqWLaAovr0abny5cAwCdDKZi8xFxZtENVueCDxlefLivYnruUXiYg-zPPyIw=w1120-h322-no?authuser=0"
						alt="Logo for Goin' Donuts."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="NDC Incorporated"
					image={false}
					reverse={false}
					subtitle="Medical and dental supply chain provider, National Distribution Corporation, Inc. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLW6ZYjFzUXB0MdeHcKqBUzDKPh3QRKNWt8bv3Z8oEE_AJ3s8cjLn7UDBtdqxBh5r29ar13n7TJOqmaoWTder7_qeEtKHqMYjyK3WuWauljqyWiTwnKfP-HC3zrklVOAqCSJ-EAIseC4jb_ZF-MU_dYyow=w1120-h322-no?authuser=0"
						alt="Logo for NDC Incorporated."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Omniware Studio"
					image={false}
					reverse={false}
					subtitle="Canadian mobile game studio with a focus on sci-fi. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLV69oSJCz_eEiP3WTJiPy3HuKLbVt9lBGkIvQuuBMgAYv3k83oSvmNASeW7OsmJQs9D30L-rfkDP9_ePnWSf0XeJwCDW3opsPteL9D2xSgFZ1iK30SA1XIBPubRRSVrha9hXOgWHmuyLCrgGn8HoHBYLA=w1120-h322-no?authuser=0"
						alt="Logo for Omniware Studio."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />


				<CaseStudySection
					title="Double Eagle Vending"
					image={false}
					reverse={false}
					subtitle="Vending machine supply and repair from two brothers with the last name Eagle. Circa 2011."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWYTduZyx8FhdkGI6weKaAjUY51Lj7jLzKDPZDNpNLkc94s5anGqHWCZy395HHEkAzOf7KkmpGbWV6QNjEiD7Xm_hco0ZFvaqeE7Nav6Ulg4ab09e_XJSOuP1tXd-Bw9Yi8Ir-JyZr6uo0RX8-jNymy9Q=w1120-h322-no?authuser=0"
						alt="Logo for Double Eagle Vending."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Animotive Studio"
					image={false}
					reverse={false}
					subtitle="Modular brand system for animation studio. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUG6nnvfAz3XCVe_NxeF_tGbeI9eIxVX4l65ZKJR-8XqUH-sIsbirjEuuBs5_dRmS10eo9-ANGvT0YRQJwxdjZixXi084fD-9cNlBBnAATcqxPuP7c-fbigvqi5YbJLpb_Q5lNLkBZsaYz53FUmOpBA2w=w1120-h322-no?authuser=0"
						alt="Logo for Animotive Studio."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="EastWest PR"
					image={false}
					reverse={false}
					subtitle="Content strategy, copywriting, and PR freelancer. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVAfBg4fslqdk3p4JPv0iC9GQjbC9o_5GjmWoEZjWtWD6OjGZ70v0hUjLnVDtXhIpGvCzweCG6P8xbNLV3Qv-4d5xBExa-dnq9rtWvTjIRmLBnTBJnKbBUl6kzcPZFlli0bISUFQ4EV5PfuBKZE9z7f8Q=w1120-h322-no?authuser=0"
						alt="Logo for EastWest PR."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="The Dump List"
					image={false}
					reverse={false}
					subtitle="An online message board for haters and curmudgeons. Circa 2007."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXZ2BSAepGYyf-iz1Q8X518etU1W5KJ9scGvh3cQ8-26PL_gsDpMInZ8yQVY9BuXrEbNMoRpzj_I-NwLfd5LqNX9iPHLX9KnFeMUCvxt4lYHV-2aYeqUCGRkIQFJS0UifPSFyjTFCHUxQpAiq9wKL5pRA=w1120-h322-no?authuser=0"
						alt="Logo for The Dump List."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Envirolog"
					image={false}
					reverse={false}
					subtitle="Logbook and journaling iPad application for geologists. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXZjcRs5zWF_wdF4PZEpRQKqMQ3VkjSofo2JpEnM3P6JrXNCabnisHWcJSF9dWP4VLAU4FRY5alDQcWahBu6ZwPbOTDldHo9OkUVa0qGvdSOsuuBIL3PVABGBGSBwTq1OiFK2AEe4SKlmMesamL728RCw=w1120-h322-no?authuser=0"
						alt="Logo for Envirolog."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Basecamp"
					image={false}
					reverse={false}
					subtitle="Company intranet and internal tools hub at Expedia Group. Circa 2016."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUlEJoi4Rn5xnH4635xjo-4vuRuEzEyQ4xjOHLe7260LnkikA1jtnbExu3xwdhwDokxRAbU1zZX_COYjqcRZSHO-Zf2YjENHYmjwYxvzz95X-8Y73X0Gyn0b5tBiSw83_g9FGmAxwrfuOxETcbnA7TUNg=w1120-h322-no?authuser=0"
						alt="Logo for Basecamp Intranet from Expedia."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Bowtie Strategies"
					image={false}
					reverse={false}
					subtitle="A well-dressed gentleman and business consultant. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVQbf0KHNVQDDSy57FUa6_czBTEZftJ3N_NwuL4LccL9bB0d9ymgsJnEJq3A7bQEg54lcYGJ9HAf2wGveUNsALvtu2khpbIeOao7A5TR-qnrwF7y3sPpzZrxpzkHGJtDGTrRqfBnj41SNzF6fNyMZ1A4Q=w1120-h322-no?authuser=0"
						alt="Logo for Bowtie Strategies."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Bloom Agency"
					image={false}
					reverse={false}
					subtitle="Full-service traditional marketing agency cultivating long-term client relationships. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUxoBVM5TUA-nCp32_bqBVpVzSWUMPe12L48bVpzMJHE7IYwt-3Q1jDStcRlaTRA-ci3F3SoPrlKAQOiw6PUUvoZGCZVYht9xZC6S3a5Xqv1G8SP8SZR5DWakehatbhSoQ2sgXPPhZ9DIDsUJroXrPdLA=w1120-h322-no?authuser=0"
						alt="Logo for Bloom Agency."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Shape Builders"
					image={false}
					reverse={false}
					subtitle="Residential home construction and custom woodworking. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUMsgYcdqushvS2o-YJdowQ3vKGPQPN7f3QbCkssS93aECXGGMRft_CwnwSZQSX_wQxPG9jDyFZhoIxRl0Pw-wml8DUfmVF9JnTgJaRs4SZb_qiuVWgjigd5JnQIpEhA6yz5dk1KHlQ-KYxRpxnmLziOA=w1120-h322-no?authuser=0"
						alt="Logo for Shape Builders."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="North Partners"
					image={false}
					reverse={false}
					subtitle="Management consultants focused on helping small businesses start up. Circa 2002."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVeAj8Dmy_wwQTrzEa9M0B0muu4X4iD60ZJ7BogbXsXRfZy1E_F08LOa_fFxOB5xhJOPcfXY6He9am-RgteigA4-KRK1gaDhts4_4CKBS5CXOTGT6A8rco3lUFtN_aI6ZYg2O-g7HjpCbEMd4YBs3a-gw=w1120-h322-no?authuser=0"
						alt="Logo for North Partners."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Vivant Ventures"
					image={false}
					reverse={false}
					subtitle="Start-up incubator for health and wellness companies. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVRXLyeU-U2aXcNgu-3fsvT_Nr5jBCdY1qXgC_QznDIImB7hstll5bu-54r-IbAH4u3GajuVmc2injodzmCUaf2X1EZmE7eyv0IaH-J_NCknh1wEv31sN3inR_Jn9kWv3FYNV9zItgnF_BHLtjLhwUmwg=w1120-h322-no?authuser=0"
						alt="Logo for Vivant Ventures."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Intelligents"
					image={false}
					reverse={false}
					subtitle="A podcast from two brothers about etiquette for the technology age. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUHf8AJsnlV2JHa7swKElItqMNkZvi4rJEFcjmwqV_UGl3ZNEWCbMj7tQ7Su8doiO4DPN1AZSI3ogPQ89jBbwc2rUU-6h9lUOekB2VlCde1udqfLtT1xrKIofACtPoW6XqMRJUlZyUBhtFWhkZKbsJ77w=w1120-h322-no?authuser=0"
						alt="Logo for Intelligents."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Ernie the Kernel"
					image={false}
					reverse={false}
					subtitle="Website helping parents avoid foods that contain corn syrup. Circa 2001."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUYC2BaPgQSAGkr11rWDwuIpCMZl2GHSWM6SAYLljygrhcbDwYAB20YJA3ma3zdS71O3Cx27xg3h1wOYR5dIwOREAhjyE5qwOcABw93ZacWHpLVGkUH_66QGfEX3uyjFORAduGh3BYft2qxQ6NK_laRGw=w1120-h322-no?authuser=0"
						alt="Logo for Ernie the Kernel."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Centro Partners"
					image={false}
					reverse={false}
					subtitle="Central American foreign policy activists and advisors. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVhPi9unNHQGtn8M1e3GYBaV0L9BPyOGkL8mVxKW17Y7JgAtIKQWmv8Miu22OqWyr56gJkufQOI4lrK3jEh9bGdGIorW-3Ctw5ELAOstsreqxg83Obq6sj73XPFu7MGle90Zy2Q36qvnWvXucVj1SArdQ=w1120-h322-no?authuser=0"
						alt="Logo for Centro Partners."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Ronin Agency"
					image={false}
					reverse={false}
					subtitle="Software development agency specializing in e-commerce. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXRIFz5U0Uu3gWb4ZpiOUsawzxyf20mmJTJgtLbZLZAhTFUdS9Zf0znCJWxijYAj-1RH0ajyNzhsDRgDcBoID-iHI143hMuhMAtJpevQ2shQPuiUP07eaj6UFPXLcx7btIiU9uBTuV8AUZfr973ovVSVg=w1120-h322-no?authuser=0"
						alt="Logo for Ronin Agency."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Forest & Fiber"
					image={false}
					reverse={false}
					subtitle="Premium accessories for all, made from organic materials. Circa 2004."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVkRafNghxJcm9mV9JnPMp7-J7-kCcV7c5l0PKnwgn1jgUnhIiS-Xgsw1fgyvPOmRAAGZTm8eFZx_6o8aQGGxJ0bosHQvAiznGKXMqvyVwHEVdKt1OgPkwWHgv5UkOpY5A1QZGf_LqrW21I0K7RavTcKQ=w1120-h322-no?authuser=0"
						alt="Logo for Forest & Fiber."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Wanderer Magazine"
					image={false}
					reverse={false}
					subtitle="Mid-Atlantic region camping and outdoor travel periodical. Circa 2005."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXrhg_W-VVBB_sWLjBRN5Adnt5xIcrXvn0sneAueJ3YCmIwuXMlWSIdloLk8AUTIq9UdocYUfBfOJd5J9IJOHeJH5UGLnD0cf10s5BqGH9To6OX_VvT6x72FlpTfkiHwh9wfIGE1HUDpMH04wTElC8pYA=w1120-h322-no?authuser=0"
						alt="Logo for Wanderer Magazine."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Monumental Clothing"
					image={false}
					reverse={false}
					subtitle="Skate and snowboarding apparel with military-inspired designs. Circa 2011."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUVNgMXjr5ftpHbd5dpFpYA07CcSKMnV5_psu4dvrAmDkKfMGPIZOpFZR2PpiJSSmHEIeUj2z-lKlCExY_HMDpjThpR75RA8tX47Zylsqt7bQ6t4seKqEpjuF__5b6TATPMTet6kUSfWDqq0CBBNdbYSw=w1120-h322-no?authuser=0"
						alt="Logo for Monumental Clothing."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Phree Racing"
					image={false}
					reverse={false}
					subtitle="On-demand decals, wraps, and pinstripe prints for car freaks. Circa 2005."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLU2Aeaz7Ly0Pp3qH5-MeVDRzvVNGUmjBXfKaXFfecednrp93snno-q3KOYrj-IDu9NbOp2olPu8qMYgTh_euP-TZqv63lPMhoEVHt7YGD_M5T48QYWemrH6OAr-wb8LXOG3X7iAvIMReEU1gzQYRM436A=w1120-h322-no?authuser=0"
						alt="Logo for Phree Racing."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="HealthShift"
					image={false}
					reverse={false}
					subtitle="A platform helping people monitor and take control of their mental and physical health. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLULPnnD7OhLMkGOMhnlP635Z8kkfO5zG3HCuddAQRDI7O_fArQ9dC3VwpUGamVpfLgrMdZMjheC1Lj8Sh4cVloJohWbajBn3-zZzuF_56FDt71w4n-qOytuHyffuUQJSp-5OnupuuGQmpwkNmrErWJiGA=w1120-h322-no?authuser=0"
						alt="Logo for HealthShift."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Bob Ries for Senate"
					image={false}
					reverse={false}
					subtitle="Bob Ries for Congress 2014 branded campaign package. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUDJbGH2MGW2O-Haik22Sb_NYL-Uv13ffECntzvKhk4rEi0r3HiIQ6fieGhuBm7tDs2gdTIy1Jp1K8ufRrm4FMy9kEnpuJNeXF12uigV76Z0p2S6IIonMwotab5gVaWeLlSgPtcr7rk5XcYkPry2FEzaQ=w1120-h322-no?authuser=0"
						alt="Logo for Bob Ries for United States Senate."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Peloton Fitness"
					image={false}
					reverse={false}
					subtitle="Gym franchise helping people fall in love with cycling. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXc5Fq3AoBO8Tpuo0CEorHIHG7YTpw_Cn3g1pSVNooVhX5esQHVFApsxi80_mlsnz2h2iS5l8_tq7X4r7TL7YLtTKDWp3sP_uNePUnhAieFUudULSYNs12omwobT910Rh6i8Mf--3DFK0qsGTaRb0arZQ=w1120-h322-no?authuser=0"
						alt="Logo for Peloton Fitness."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Spotsi"
					image={false}
					reverse={false}
					subtitle="Find the scenic route with this app for outdoor tourism. Circa 2008."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUWn8cU0pHaZLPzbhD8zyJrx6PFrNWVhzeWtXh-1PivgF4XcTue7O1yF-lqYlq5TtLsDP9_aPUw39gZ7pjfh6YXI9qVaA1w2phUxKj_nwDYpO8E36-nwcLL03OLg_xPY1WEOb4YzpRmcBYs5FCJFiti5g=w1120-h322-no?authuser=0"
						alt="Logo for Spotsi app."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Mass Media Music Group"
					image={false}
					reverse={false}
					subtitle="Record label of sonic sounds in rap, hip-hop, soul, and R&B. Circa 2003."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWuQ0jtgBv8w5IfKubZQMzrrDCbfLoBz3ZF8-4BycLjrhq9UeFOoXvs5GVuYvQVujqfrasoyaX9ZIS7oAQX9SzALC-uqixUmNGjt4JtF5x7QHyc50kP9maxoPX-TIZsBrYz9CL0pQ0WcDsG07Kg6nGBsw=w1120-h322-no?authuser=0"
						alt="Logo for Mass Media Music Group."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Chalk Studio"
					image={false}
					reverse={false}
					subtitle="Educational technology custom made for early childhood education classrooms. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVEbu7lRk4t_ky9egiz2gREHX8706eVdkbzT4avklu0fl4hC43gqcoOR9tK5_5bs8n3gNgp7Is03znSI_6Vvb_ozs9isi9WyXA7M5bjnalU3KY-LUOabEt-GsLI_XroqpDWc0sfU_m7QAwcpEq2N6zSTA=w1120-h322-no?authuser=0"
						alt="Logo for Chalk Studio."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Accentive"
					image={false}
					reverse={false}
					subtitle="Passport, visa, ESL, and international study agency. Circa 2007."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWQEdso5EVi3CKRAodAsGiY4BdLdV2SADmEHGi85fye1pMrmvd1xzG9eufEnMvn6BLjI9CWo_irbx32aQ-jKhK_irrlrdgK09dO3hyrBL4Xm7cNaFkga3HO7JLUx7mwHx40MavIAP-jsoFMM9oc4LZSRw=w1120-h322-no?authuser=0"
						alt="Logo for Accentive."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Veritas Visionworks"
					image={false}
					reverse={false}
					subtitle="Cloud-based home security systems. Circa 2011."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWH98L8i2wIfIkdXUny5q5keF3dPjRFP_H2QN8MZEwkRFo3uww_PBdxUjEO7ocsuAymwGiSRE5KxH87YTwmTr-eEZ3e4HwgNwqPbtTBT10BH_Ir0ltKmiRUK3Itzzr89nmGa_goVrALnrxI9Gny3Sq1UQ=w1120-h322-no?authuser=0"
						alt="Logo for Veritas Visionworks."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="DataFi"
					image={false}
					reverse={false}
					subtitle="MVP precursor brand to Built Technologies. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVxPgfqwSfegPT1oSKs-rTM8MRcXjvwAMKebhn7ctppWYM9jlubiE7m17qKYvr2W0dD1cIq8qXfYMXsJsGNWTPjW2pXJ5ak9GmPcGCP1eDMZjzCZQD3D3AVO3_haT5_v76TcaQfgxpRxgcao_n7WjXcOQ=w1120-h322-no?authuser=0"
						alt="Logo for DataFi."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Coda Solutions"
					image={false}
					reverse={false}
					subtitle="Information security and data forensics for government. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXrAQAR4NuqoYxbd-wexFKBMEwhVyxqvdHyaqIK32WcRdjZTVTCBwIvCi-x2Wvun1o9t_rZ5PMk0fYb6IelSUOxa-UTxFPOBvWE6yhBBfxdP2Xj6eWi10EvDdtXjWw_rJ2ISZ-awiUcwzhDAvObJ1j7IQ=w1120-h322-no?authuser=0"
						alt="Logo for Coda Security Solutions."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Happenings"
					image={false}
					reverse={false}
					subtitle="Dada-inspired arthouse events with a non-linear format. Circa 2003."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX1gJ7UHfKtzehtNbJzVo4huZv1BBCJxSi1Ig5GGCuVt6KXPPgA1g5442f2lzYhSYYfRGRJIGt1zi6JCXvhaiad65hB8kBLea7TXhytW_rVrbq93YMfgMRMGUdxZk0s1PH5zkLxnlhyhVbMIcrI_Y71Xw=w1120-h322-no?authuser=0"
						alt="Logo for Happenings."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Xtract Vapors"
					image={false}
					reverse={false}
					subtitle="E-cig juice and hardware in a high-end vaping lounge. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUNCqhcuPb8zNpjhTMA3IUrgg12rVfpJP5yLh74k1GudrOgvxq_RKBFGsAofvRdNbUphFWHEjiGkmkpKd03fa7vdjvmh5Cawe4BVODY-fD-EWGdTyLkAnEOx-xl_n_wMtEdxU5Q6c0r3L8M7lcYJiBpBQ=w1120-h322-no?authuser=0"
						alt="Logo for Xtract Vapors."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="VeriFi"
					image={false}
					reverse={false}
					subtitle="Construction lending draw request verification pros. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXabvEXahF5nizd9FhHz9LxsZHAFb_x1yrj4Bw7kllMY8t6yjuiDtS5PuA9v1ZgPQAi-mXIrzEaD-OwzTbEEYQy8Na25YeeVliAheELQEEQHhP1tPOnaxPYY87_Jq9tJ7-6Lk7iqROAURkaJSP1rjVJ_A=w1120-h322-no?authuser=0"
						alt="Logo for VeriFi."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Coops"
					image={false}
					reverse={false}
					subtitle="Mobile couponing platform for same-day lightning deals. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVvUR9bmIQAqT_MyORtVYXyPifs8GO6KKuDVX11N6GwKBMbNKngdkQ0HJi48v_PKRF_B48ecBoShH0sadd3aZoAMzdmX4aUuzObvWnec5KpsG_ZtIuy3vJ_MvrZ1yey9NPeRC1TiH1oqqiswZES2uO0og=w1120-h322-no?authuser=0"
						alt="Logo for Coops.com."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Egencia"
					image={false}
					reverse={false}
					subtitle="Technical logo updates for the fourth largest travel management company. Circa 2016."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWvF4eQ_r_Ecfr0DeDYUd5_i4eiNBsrG-2H7HASCAAOh5C1wmoE7xHBZFkY5lNeDZAIQthCw2nZOc09_KC8iuUwlE5fF48SmJcYncJ8vQ-_NiQYBcBycJ6a_q2c0vydAT79gcP3N7QmmtQPiAFQxck0Xw=w1120-h322-no?authuser=0"
						alt="Logo for Egencia."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="JustOne Entertainment"
					image={false}
					reverse={false}
					subtitle="Artist management and event bookings. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUNdafh_yaajxdvbYaSL24U1TG4BH8uWzTQIalpptghgQy-uue4n0ymUfbumo_6Z9lw0eROF0yhUL_NCQ_m_hb5ofQTpjXs9FTevpdHfujotRbTWAefyGRhC-rZj2YCECvvQZpbJ_-Ehwf49rUEyOZG5Q=w1120-h322-no?authuser=0"
						alt="Logo for JustOne Entertainment."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Cutler Waterking"
					image={false}
					reverse={false}
					subtitle="A trusted source for plumbing installation and service. Circa 2001."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUDWAXU3U-SYhCJdSewffB89bAlA1b97CrvZY4m4Nw-IUqcaiLHOoCqwaTn8vPmYURL-7TF4RQoI0qpR8U16jv2Rb7El8dgvBRJ5IYMqZevtso3BgVhcRzXBxd5d3lzk30DNcpCIa1O-A0RZbQj4CpSXw=w1120-h322-no?authuser=0"
						alt="Logo for Cutler Waterking."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Elevate Award"
					image={false}
					reverse={false}
					subtitle="Internal peer-to-peer recognition program at Expedia Group. Circa 2018."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXOMome3oRusUA2B4bboyobyXQiS-2_DaGQjKHhljk_avZBCLEgCOsdUNhDKs8mXS0MWsf_uESozhmL5rxKvHw68cNKRBG0c9x3Q01nChGkT7B79EJm75dI65FVHe-aJv2ByhUo4h3KpOj7L61wRMWadw=w1120-h322-no?authuser=0"
						alt="Logo for Elevate Award."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Virtue Capital"
					image={false}
					reverse={false}
					subtitle="Market research, licensed investors, and personal wealth management. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXPVGSzwV6e_zC9-yMrZmBJO9q4qaty1kdoFSSQzdRSOisFIqDOTK-gBsAvtbeUktUQjVgLeG4TXOrxt7FF5lTNT01_7fEwm379pTcHtRJYC_gPD9p0Hr4lwyvBd4gfYurOX6Z5nnjaTHNdyD53BRk0Vw=w1120-h322-no?authuser=0"
						alt="Logo for Virtue Capital."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Emergence"
					image={false}
					reverse={false}
					subtitle="Consultants in waste cost reduction and recycling program management. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXrP99Xn0uKttO6aIUdMZA_J6PQYkLv-pqvWLiu08NmmOV9ul5nPhOwHS9JH8MvDM0Z29P6F-3bLWkmCRFA44mKADM6AASO9CmP3nt5CDdbyQ8zVUCRRuLhEApn_nZI8T5XojDG5OuXg7eTQe0SA1Lzdw=w1120-h322-no?authuser=0"
						alt="Logo for Emergence Environmental Management Group."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Pathfinders"
					image={false}
					reverse={false}
					subtitle="User-generated content travel blog presented by Lonely Planet. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLV9wE9sFuD3gjEzLm9FIY7VoEICQOkROv5nvaS3cs_RcTw4LcbWk-SCs88n5rFwOwjB1voRW9EWJA7eW_EOe6nRL_8mV-TT0kG9a11peRECwIco30Skqhab0fFKC-NrBXXNPCb-acaRMnjy0JE80fbWdA=w1120-h322-no?authuser=0"
						alt="Logo for Lonely Planet's Pathfinders blog."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Collins Law Firm"
					image={false}
					reverse={false}
					subtitle="Tennessee law firm providing legal solutions in pursuit of client goals. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUXt-A78TjKCJgA6V0Sq2SgesdefbZD_sYOvRViu7PZCXGcDrM6_E4DiiwcXHs8ORDzr6jSQqqT5AKeaJ9wk58l-Mj982FgUd7DklwLNHX87Pf_z616128QjyBC2aUOUq6Gk9NoQOYG7d14Qtmx5ndelQ=w1120-h322-no?authuser=0"
						alt="Logo for Collins Law Firm."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Quilted Press"
					image={false}
					reverse={false}
					subtitle="Publisher specializing in short-run novels about the American Heartland. Circa 2011."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUylybMbiTOR6neLjxphXbh_Al2QJXk6wz0QQzgXQ11r01ONOJSBqZfFtBIKelwSrbcXlwmQwPSUAM4oCHXba067BD7XIIuUvOsEHgmQmoX1oylIf7fmQDIkRgCJCz0tAZ6awzEw4ByVXRIhaOyjBKS7w=w1120-h322-no?authuser=0"
						alt="Logo for Quilted Press."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Arrister"
					image={false}
					reverse={false}
					subtitle="Online on-demand furniture configuration and fabrication. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUFSeUhDkdcwKQZTHmYPSUqxR0f28AtpGVZQgaf-3__ugxsCmYzrRz-194oq2tmwHLOAXehDj5xcxViU2Nt7ZGEDs81OSW9NlCdR6zhgtkF6ZJRTkm-tqUYBnipX5ULwcZ8QN-90rKkUYM5A1DzqP9s5A=w1120-h322-no?authuser=0"
						alt="Logo for Arrister."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Attndr"
					image={false}
					reverse={false}
					subtitle="Bands, brands, and fans come together for real-time tracking of engagement. Circa 2006."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWxADoPHH3HanLe_LSkdwPtuHRPsNZNwbwK7jnVb5ZXNLlqzVKTWg1QO5XPMcgiDJaLpPSxs4hysOEgOXPiDfKEw0SD2WtzPPgZ82Y2i_mRVHsfibfDzNCpiNFi8sJgfb-KI5auL1Bjsl9aSrxStzVR4A=w1120-h322-no?authuser=0"
						alt="Logo for Attndr."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Riley Carroll"
					image={false}
					reverse={false}
					subtitle="Logomark for letterer, designer, and illustrator Riley Carroll. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWbsrcdodH8QTA3RF-lydvZpkdcghY2UEOKKHTEyIyoca5ofAOwczwUL0qvx4a8Iwhs20bpb-F83RPctm5MmY9QQbYMxOPcLCu3aM7J4h00ciHB5llBWqTp5HGDyfKrVVsqvulOjUMXYjevD554TX-Aew=w1120-h322-no?authuser=0"
						alt="Logo for Riley Carroll."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Waypoint Therapy"
					image={false}
					reverse={false}
					subtitle="Counseling practice helping others navigate life. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVBj0dXWSedOYBdahWGUYWjyAysedRlL3ijlEXqdIw4-ZUaGJ0K6-B-ubCNfthZGQqpUVdb4OPO84LPaKpN_xDGiYdCHW2oPQ8b6u9j9EFGvvuqOH6Vjdh8Qi90Bqj6ZzsxSWJPxh4ognx6oJ7UchiBIg=w1120-h322-no?authuser=0"
						alt="Logo for Waypoint Therapy Solutions."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Eleven Partners"
					image={false}
					reverse={false}
					subtitle="Investment fund and advisory group for technology start-ups. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUZp3zpJPcsCmv8iFL6zPVBzotulqFVumLmd80OrPCuWTFYTPviwfrq7Z3sbhTqXKvCk4CILCYGV8whOspEvcZoE9As9Of6jORV8OVYLxu6650oMF1mf-hTf9YU7ClWkZ2vUFI-VNJZ8eK18gjX8woIWA=w1120-h322-no?authuser=0"
						alt="Logo for Eleven Investment Partners."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Elite Orthopedic"
					image={false}
					reverse={false}
					subtitle="Sports medicine consultants for the Tennessee Titans. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWKrvPs4FOiqOzoprAsGU0GizDyZ9lQJU6L_zQ8zx9dei6K2EDisiONxZtQlmaulRbUUGAhugiEcYe9Budl0dVE8ecWNm8iBD8H3Zbk5SaK1CJ-frbsPdufkFHpiYA6RZ1ApIgO0cdd1uldfZuHn2pWOw=w1120-h322-no?authuser=0"
						alt="Logo for Elite Orthopedic."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="JumpOnIt Deals"
					image={false}
					reverse={false}
					subtitle="Flash sale meta-search mobile application. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWP8y62CGDuY71ALZlqnDH1Z55ViJeqCpMzTaZ1GHa6x1jqZcu7MjwFUxPB8-ZqKWSjz0MdoB8Jvd5OHp6j57oQqipX6aQNpOEVVvHFz-oon0KT6OBrVl2iq-Q1sLr7que3xKWJHO-E6DChUCwL22SO6g=w1120-h322-no?authuser=0"
						alt="Logo for JumpOnIt Deals."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="ApexWorks"
					image={false}
					reverse={false}
					subtitle="Roofing repair and disaster restoration service experts. Circa 2012."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWgStUnt3qkH9Pc8ccY3f1SJzFdDm7UF19bDfMivMtx-3wmZQ5endX6NNKQWQnINQR7pFj6aDZHHVzoHu_D48yWr1MLb3RFtlEDdbrs9S-sLQ0mZ-Q9jXOS0zXpz1yAxxwIac2jXNiYJ40W81WvNFoV4w=w1120-h322-no?authuser=0"
						alt="Logo for ApexWorks."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Parthenon Media"
					image={false}
					reverse={false}
					subtitle="Trade publication design and general creative services. Circa 2013."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVuOXRuv4t8ScNovUYD6_UmKLzPAQuVb6U_Tw9agIR1nt8XjmdNdTTGLpbIywVAHQTd9xSP8YDY-md872zkg5fCqsCEb1W99r_E19vbaTVXBBogbRwM5__DXQWJDpeQgzpBUAjKXgawQXgwHaEpm6Vcrw=w1120-h322-no?authuser=0"
						alt="Logo for Parthenon Media."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Nurv Studio"
					image={false}
					reverse={false}
					subtitle="UX research and design consultancy. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLULjcPCjOqNfrpYnFB9BeBBryAq_4iE1y1pDFcAPlT08TpGaqaFwTMzXuNAvigw6ohPtlKVEasRy29ZZ3664E_TJ2fyAwiaZowZHQV0Gqhw2or9QVrqk7hDKvlBY7j3Dk-psCuwm3-wA_zLo8lIH9k1Gg=w1120-h322-no?authuser=0"
						alt="Logo for Nurv Studio."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="DARPA"
					image={false}
					reverse={false}
					subtitle="Program branding for DARPA’s 100 Year Starship Study. Circa 2008."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVVPrLalZ8uzeG_nhDKndVrKP-UwI_YNZO6thPQ-5O4mZtACZWquQBDtHnl8R-1ACy_vzeRZutZAoorabIM_W--EirUUEEOjp44sAk3F79yTf6cBflspfRj4va-IBPjH61hYvBMep2bCSzm_quIKkhoig=w1120-h322-no?authuser=0"
						alt="Logo for DARPA's 100 Year Starship Study."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Bold Framing"
					image={false}
					reverse={false}
					subtitle="My first paid logo project for a local picture framing company. Circa 1996."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXgdl_mn2l7AUdhmKDaGUllFopPyY7Bm5yETJ1iGgvtsfOlXAS-Ae2WuGC6SSo-BK3ucmwh-OtxqdGWJsP-j-6fFZNAp-esySeOHRFWO_p5bN6fqQgcIu5RdaH50fn8-Xn5d9QrWnKXa7x8FXkpnBxcxw=w1120-h322-no?authuser=0"
						alt="Logo for Bold Framing."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Jacob Byrd"
					image={false}
					reverse={false}
					subtitle="Pop-folk tunes from singer-songwriter Jacob Byrd. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX_MaY891-VfsJaep55OlS6XZe3_FHFLM9CavyRysMJdkEMFnO0VR8bNU6KjgremBs_Z9rpER34Wh3I0XOb97FKV5Q_zftQWrcuAi9ayvNsJp3PrbDJ86899bpQ9lm35FMub-V3m9_LtxrBbXAFY0KbnQ=w1120-h322-no?authuser=0"
						alt="Logo for Jacob Byrd."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Mejorico"
					image={false}
					reverse={false}
					subtitle="Independent Mexican alt-culture news and media outlet. Circa 2004."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUkqaWdhfLYfskokt3KSuFcvt8EDPYwmMhofdq2yjmgGchl5apO3rg8MEpUN6Haukmc7zNJZuShJh_I4awxsTcFJ7mV3c4ROzE2lScp8sOzdnEPQ0BHC503c1OWOZVKgCJCpPOj_LNY7iTlCCRe6LCGWQ=w1120-h322-no?authuser=0"
						alt="Logo for Mejorico."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Personal"
					image={false}
					reverse={false}
					subtitle="Early personal logomark for freelance work. Circa 2000."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXQJ1k4om6IkKvGzOprJhHwYKZBAO4uNZtcb70CsFcZ61pURIZf78MVyaCujgIHwU4uvc0j6csOLurqfPuFd2rwLAg-UbUUmiaAPioZBExbsydWh9jAtrHDlGivdrObkGNSFXiIulPmhO6mPhRRKKxAfw=w1120-h322-no?authuser=0"
						alt="Logo for Dylan Mullins."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Quill Society"
					image={false}
					reverse={false}
					subtitle="Extracurricular club for journalism nerds. Circa 1999."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWyYvgyudx-KnhrQCo9A4gIf3hF7i1fjzNWk8XuunJxoA3-FBVb2DskzSN7zRibi9EZZrUaegE-86aoNEjAaPTrEYSCbm3KMjIbPlutHZXFJ4tUkZYoUgxrXRhQq92JfCeDfBDJCmFk72UYq6qJzYzqWw=w1120-h322-no?authuser=0"
						alt="Logo for Quill Society."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Vinewire"
					image={false}
					reverse={false}
					subtitle="Blog, podcasts, videos, and more for oenophiles. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWjtm1QkxzhpxD8-YIiyLj99FVZhm6zAHDz_WuVyvgm9B9_yl4X_MyfF4XMBpNK3jx1CnM3EISXjx9So77dbvFSA6u0_MTtmI5okSdIBO-5nyWXHAiudcGLHRK5yTrV_jr1TKfdRqZYSsLl5a5dU1tzLw=w1120-h322-no?authuser=0"
						alt="Logo for Vinewire."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Drivetime"
					image={false}
					reverse={false}
					subtitle="Driving improvement school that picks you up and drops you off. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXbInklbeduo16n6dNiTaCyWvznS30DnzLxiBjhQ0K9HwmAbgTKnocGlFoPMRWPBJb-HX6FJ8ATz0jeZ0QFl4GdZFCN2jliNfPD6qW3_Wc_TrBUpxdt-mvResfmMdPi1Y1Gi6VRZAahME8SCi8RLVX1Ig=w1120-h322-no?authuser=0"
						alt="Logo for Drivetime."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Evergreen Health Center"
					image={false}
					reverse={false}
					subtitle="Recognized leader in primary, emergency, and specialty care services in Evergreen, Colorado. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLV_2a8SSrPiW_p70YU6pONygvSVYcmVD7xV_jlgeQ01_xJKgq47d7H66DRmbDkEZKFw_sznV1pkFVkzqwBqOxFGA2I0qLnplzFQaVBVmZCwlGAJDsiCHlQ0jlZVknTVnzJ4bvw8PyT7As34H5Wszqkqrw=w1120-h322-no?authuser=0"
						alt="Logo for Evergreen Health Center."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Egencia Connect"
					image={false}
					reverse={false}
					subtitle="Client usergroup and feedback panel. Circa 2016."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXwyAyyy7ffahoHBUy-X2UwI6k3s99li-NKl80ivRh2OT-_gSrZl5tufcmMa5adMedmtd1To3VU3Th7WntMu3HQUg3A8E_xwd5silHkPgqDP-yGBZY0_MIicftNQAsoCxK4X_jKp6MMpkeEl7a6LWuS7Q=w1120-h322-no?authuser=0"
						alt="Logo for Egencia Connect."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Cort Furniture"
					image={false}
					reverse={false}
					subtitle="Global brand update for Berkshire-Hathaway imprint. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLU7-BjiSrCQ7LYUnp6BuRqeJmjH_xKsU98c5uUPty7UF7FQhYUoTi1uMpIdi_p8uu80QduPEH7vzt9X-nhlB9ZXzc1EuJQp5GbNRpJ8vlWOUaqBsvBqunFP6i-oFfQjMvofxFY00MYOG2ncs5D8vSO9Lg=w1120-h322-no?authuser=0"
						alt="Logo for Cort Furniture."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Eloqua.com"
					image={false}
					reverse={false}
					subtitle="Lead scoring and marketing automation platform. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWzR9cljNhV2vUktC3-3CxjOeYj4yWF51ZrrKUBZXAJThTju7RgtPCyb1EmDOO3m5s6DPe7PmiKaySdC3OoYqX6KfKShwZnUR1ehRA_Pu_hUAMD0iwbBPwLhMd5R1TUyS6VwQa0asbKnoEtv9dh0RX2MA=w1120-h322-no?authuser=0"
						alt="Logo for Eloqua.com."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Vanish Travel"
					image={false}
					reverse={false}
					subtitle="Air travel mileage tracker and personal travel journal app. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWQRpTKXNPpMvhoPXC2ZpYZu-Q2NYGXIgsquJ394zjxA476S4lrJxeYHe5b-GCREJ6EWyWUdrLP1E-jJkSC5XSy_WOW87fLKwRZYUGxkQCpIBVTBYbVWVCMWDUL6kqwwkv_3-NrvoQ0jpealw8d9c_D5w=w1120-h322-no?authuser=0"
						alt="Logo for Vanish Travel."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Xite Sessions"
					image={false}
					reverse={false}
					subtitle="VCU Arts show featuring generative design works and guest lecturers. Circa 2002."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWLPUEv-ShIiFr_wHgYIflOOzfsNp0oXUFy1kpglgcNmcfBtyX4fF5isnCsJ86xpwR4ok9jVM_eYiaaOyJJCi9X3FWx7phRhY3XEQmXG4iu2MML5qhVAA1_QlqHxG6Z5mv-k3czmXcqvvXLLiOdB0DLcA=w1120-h322-no?authuser=0"
						alt="Logo for Xite Sessions."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Vintage 301"
					image={false}
					reverse={false}
					subtitle="Intimate culinary experiences in the heart of Winston-Salem. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVKGnHYgmiLBeiE5qvqGiUIVXcECRsmvjFSGimICH5dk9jUPZ3tLlH6hxS92d9eGC1qi70O3-k1Xct0tX9SKKGscNPWp6C0HMqKZg06ziuOksygSwj9SSDTtjdMmKlES9taWNAdQIaN_4L2rCY1tDhHcw=w1120-h322-no?authuser=0"
						alt="Logo for Vintage 301."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Bay Diesel"
					image={false}
					reverse={false}
					subtitle="Marine engine and generator sales, service, and repair. Circa 2003."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXKOG7uULIbk3kbJSNkTKvdWJv0UqQTkYJaeDmQlmrvs_g6Io_PEyvAJgj6MFXvHIxF_slhd2zhIHL3NsSY9Ci7Hbe4KNVrle1_HW_avs8dVxvqAkFg0LmnXOzqoV7nySm1ViFl7q7mweK25IPlqv_Qig=w1120-h322-no?authuser=0"
						alt="Logo for Bay Diesel."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Flying Sauce Pizzeria"
					image={false}
					reverse={false}
					subtitle="Otherworldly hand-tossed pizza and beers. Circa 2001."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVFEnoCARkeG4n9dnskxmBv2Dx3uFr4LqdHoM9_xR6egpTMuLKH_Qa1I_rHU0CQJ73hFRcWdCRsQQys41m8c5I4_NG3nUODuLGvs92TtOaQQD41p5Q3Hx5VFra0RC9Z3bBI1alyF8DbwvzN6gsDBrcmyw=w1120-h322-no?authuser=0"
						alt="Logo for Flying Saucer Pizzeria."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Timeclocker"
					image={false}
					reverse={false}
					subtitle="Asset management and inventory tracking for construction crew jobsites. Circa 2012."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX9RrD-rq5quLlMbDNcZez4RHpp3EQA2U2U60cgRTDN3sPyQxOSkg0R9XE55VyTmkiIU86FKitIsh8OjSBWbr3LX59AtEyOG8wxJUPObHUW3rf0KMxKRqrtNlOqXQI2rCfzljZvoK-ppLC8HvmJdXpPig=w1120-h322-no?authuser=0"
						alt="Logo for Timeclocker."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Kelly Paige"
					image={false}
					reverse={false}
					subtitle="Album and tour branding for country music artist. Circa 2011."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUHx7qpn2Txs3y9sQfcOalZEFdovpMKP5sqpGOVJQnuBaUV0If7_k0naXuFtm6TtRSVS7eMi6ZD0ekSnTp7XrZSa6LVK7fwBaZqEPz5hs8xnyRvAG5P6mhyumSogFg8BgJKVB2NzgQpqDf7n6fv3Wfh5g=w1120-h322-no?authuser=0"
						alt="Logo for Kelly Paige."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Quinn Gaines"
					image={false}
					reverse={false}
					subtitle="Freelancer photographer and videographer. Circa 2005."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLV99GLYxKiaYARY6IJ4pjtPzU5Yrxk2s7uxiRVABBSVuLm0iTLUuCZHm9JOu_EvMcDgojX1MulR9uiT2MdHdpDgBo_Vq38EGdMsljBcvlsTui0AQOKaxtd2TLvF36Lr6ML78UpCLPgyy_OI8qTEZyYciQ=w1120-h322-no?authuser=0"
						alt="Logo for Quinn Gaines."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Powder X"
					image={false}
					reverse={false}
					subtitle="Expert-quality mobile powder coating application and removal. Circa 2014."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWOUi2QTOd9XZn7AWmnhxaQKIPbyDl-0NVPgpBHpFVUqMVPyLDTjFa87TnK7miU71NGo3mZzEqX6znvKLMFu4fZMOQ4KmnyDK1kP-koPspoU0ISi6AIq29plR8VhqfsFnAVFs_HnokmPnWseFLJdALOvg=w1120-h322-no?authuser=0"
						alt="Logo for Powder X."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Paper Spaceship"
					image={false}
					reverse={false}
					subtitle="Developer of educational games for kids and teens. Circa 2008."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLUUk7iV1cOWWQxs4p2DKXYoQpiKBv98kMRPbeh9ock05ifuVwaz9Bn96a-I_8mEAHG__pCJD7rn9xYMwdFfq_A0pNt-OU_s7xiw7kDfIX8jRXMjfKvZlLA7d9Fb5Ba_IdK_9rzFbKC1P6onFpN_NYN6bA=w1120-h322-no?authuser=0"
						alt="Logo for Paper Spaceship."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Rallyup"
					image={false}
					reverse={false}
					subtitle="NASCAR infosite for driver, fleet, and sponsor news. Circa 2000."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLVos6x7JrA5_qmZsvSKo5Rk8-enJTDs6K8W82MiI44ZKn1nmZOkEWpYUL_f93T7UFnSMmbNIsqgWARo7PAfl1MDZHKFCqu0SPq8u83IMvr3GJZvD5tiar-Ph5Q3-2IjKyFckH_hyoYJPQTp4aHyxQ75Tw=w1120-h322-no?authuser=0"
						alt="Logo for Rallyup."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Smileology"
					image={false}
					reverse={false}
					subtitle="Your family-friendly neighborhood dentist. Circa 2009."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX1xRPlsCcTwMM7vhLHZwdDiKXGiLnnlk0VyLzzUFao83vVj1E9KNOxGbpiNIhtbRXDh3Lw2CvRmNiIbWnImCKOgF5jEgn_K5QUSI-XaZlX4bhdomKiu92omGKEhn_ZUqWcxrXTTs_MtMQ3dcB2RjfWSw=w1120-h322-no?authuser=0"
						alt="Logo for Smileology Dentistry."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Barely Art"
					image={false}
					reverse={false}
					subtitle="Graffiti art collective from Richmond, Virginia. Circa 2001."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLX-rjUDsU6kWFVQPu6MvN-cVGJCfU_aiwtiehPOZ8g1bBdTNT8c1t5YNUOdeS2_kmOyDOrKaeXJG3lKldvCRQbII69RdjrTRXl1n9xjswZOLLu5y8jYo59VYNMOlHy7FDWv_RToFK9ll1NdLUV70NqsRg=w1120-h322-no?authuser=0"
						alt="Logo for Barely Art Collective."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Goner Magazine"
					image={false}
					reverse={false}
					subtitle="Off-the-grid lifestyle journal for men. Circa 2010."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWJp_KIG_JBnHgmq00LLByFQs5SdWxN98L0aqA2KoxHqBe9JggVi74z5q8PO7EKcQT-lqygJL1y9YDWdAK6t5slWDU2nIv77Op_l0XTbhKF90J7k45M88EPYvNirrEtRR9iAO30JecKYCsLOFcpUugruQ=w1120-h322-no?authuser=0"
						alt="Logo for Goner Magazine."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Odd Couple"
					image={false}
					reverse={false}
					subtitle="Hip-hop lyrical duo hailing from Norfolk, Virginia. Circa 2004."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXLiZqm8TQV17VJuzpjEzMmPcA0HFECqwSS3nThAB9rD9iSW0dfPaaP8L0CkGtV4RTqVzpOALgX8Ju3rSn-ZS7kVN6kLpoVgIIYOsg4Fvomn023VE-eKV2EMvUm2cQv9eH6_yMzOWlJYid_2uvPrmsE6w=w1120-h322-no?authuser=0"
						alt="Logo for Odd Couple Music."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Uberpay"
					image={false}
					reverse={false}
					subtitle="Digital layaway for online purchases. Circa 2015."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLW1O1hmMFCmIX1KMapF6QAUzIX-7ZN1EoFET7qJSjTnpzAe3A5IZhvQJWvMXMN4thE5CuD63j6c8fEYUneJXGeMio_xEqs-LqHUklt92-lHSHBLgK96g0pic9jJd9XPz-QO9Xn7vYiP_ALd-yfKIOQSWw=w1120-h322-no?authuser=0"
						alt="Logo for Uberpay."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Cardiac Center"
					image={false}
					reverse={false}
					subtitle="The largest group cardiac surgery practice in the US. Circa 2007."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLWwjdiATu3ladicx7yV7vmH7ujXYdONBaT-iMraPpMf6QKy1sDfMQBgItGh4mx0PLA882bEwz4ILdG89Jox-CLphO4-FZaAayL6_4QXT3FrkyIOKsuq_YWDg01awKUEDsfYKQKO_GZqNg0KGrDTbUao0A=w1120-h322-no?authuser=0"
						alt="Logo for Cardiac Center."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

			</>
		);
	}
}
