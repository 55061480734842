import React from 'react';
import ReactDOM from 'react-dom';

export default class CaseStudyResults extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	Statistics = ({ number, description }) => (
		<div className="statistics">
			<h1 className="statistics__number">{number}</h1>
			<p className="statistics__description paragraph--small">{description}</p>
		</div>
	);

	render() {
		const { Statistics } = this;
		return (
			<div className="caseStudy__results">
				<div className="caseStudy__results__header">
					<h3 className="caseStudy__results__header__text">Results</h3>
				</div>
				<div className="caseStudy__results__statistics">
					{this.props.statistics.map((stat, index) => (
						<Statistics number={stat.number} description={stat.description} />
					))}
				</div>
			</div>
		);
	}
}
