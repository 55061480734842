import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as Calculate from '../../helpers/Calculate';
import * as ProjectsData from '../../helpers/ProjectsData';

import CaseStudySummary from '../../components/CaseStudySummary';
import CaseStudyCollage from '../../components/CaseStudyCollage';
import CaseStudySection from '../../components/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudyImage';
import CaseStudyQuote from '../../components/CaseStudyQuote';
import CaseStudyResults from '../../components/CaseStudyResults';
import CaseStudyTestimonies from '../../components/CaseStudyTestimonies';
import CaseStudySuggestions from '../../components/CaseStudySuggestions';
import Spacer from '../../components/Spacer';

export default class HealthShift extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<CaseStudySummary
					paragraph={{
						__html:
							'How can companies help employees focus on personal wellness? By bringing consumer-grade experience to a workplace wellness platform. HealthShift’s goal was to white-label their product to three Fortune 500 corporations in year one. Through rapid prototyping and testing, we launched an MVP that integrated digital health technology and trusted medical experts into one platform, and helped HealthShift achieve its aggressive first-year growth goal.'
					}}
					details={[
						{ title: 'Year', content: '2017' },
						{ title: 'Client', content: 'HealthShift' },
						{ title: 'Role', content: 'Native App Design, Content Strategy, Naming, Branding' }
					]}
				/>

				<Spacer spacing={80} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUJBmjAta_qAyzWeBFJ6KdxUepoafUuzmYaJkRgktKV7qHAd5vJAi4Bt6H7qJRDVUHwhPzZ-ib6-Tsk9ln9Rxp-irOJ-mzzfkMtdJUauW1JM-cnz0Sr-eGXsF5K7bZg_rtaq8JMHqCUPiOB9xsNg3zKoQ=w2560-h1608"
					alt="Sample iOS screens of the HealthShift platform."
				/>

				<Spacer spacing={180} />

				<CaseStudyQuote
					content="We helped our employers triple their investment in workplace wellness."
					person={{ name: 'Anita Bailey', title: 'SVP of Innovation, Johnson & Johnson' }}
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Onboarding that activates"
					subtitle=""
					reverse={false}
				>
					<p className="paragraph--small">To help members improve personal health and avoid preventable disease, you have to know as much about their biometrics and daily habits as possible. To easily activate users and help build trust, we followed the research toward a more experiential onboarding flow with game-like interactions, eschewing the data-entry style approach of form fields and lots of typing. The demo product tested positively with users, who had an average onboarding time of ~1.5 minutes and reported a low effort score (2 out of 10).</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={true}
					source="https://lh3.googleusercontent.com/pw/AM-JKLUQmqeaV-cBDTYyV429g7_IqKv-dc2GuNdxKl0xVGF_uyhql8Z017QyCuVD4cGAHLqfihzEgCfsQXtR0Zn9hR8xvFSTP7eOe7rTEGfDX7GnJQ7au3iDfAscCxiaph2QBBNUPt4So6MGBPvl2xAfEJDREA=w3477-h2529"
					alt="Sample screens of the HealthShift onboarding experience."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Company-subsidized health content"
					image={false}
					reverse={false}
					subtitle="As an additional revenue stream, companies can purchase and unlock various health content from the platform marketplace. From white papers and video content, to on-demand workouts and subsidized 1:1 coaching, this additional stream of product revenue grew to a primary value creator for the business."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLXLUNJYtA0Gn6QYBes03UDC5b6L-GNvpsKd23jn5NPf4RyruLBX9I42wfEpxZMfTMOO3U-Frc9S0ELeGew-HEN5yWjF5HnZAEE9wwWSfpngbUmx6MQTaC8lafxD0Pu329Qu0OA6fVVtPGrPgQcCfwItJg=w1680-h1686"
						alt="Two sample images of workout-oriented app screens from the HealthShift native application."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Find the exact help you need"
					subtitle="HealthShift has the 3rd largest provider network in the US, available to all of its platform members"
					reverse={false}
				>
					<p className="paragraph--small">HealthShift brass had multiple decades of experience in insurance and corporate HR, so their network of contacts was large. As the technology team developed the software interface to connect all of these networks, the user experience needed to promote the same vastness of choice. The search experience gives members the ability to find health experts by geography, speciality, hospital system, and more, putting tens of thousands of fitness coaches, doctors, and mental health professionals at users' fingertips.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/pw/AM-JKLXEXggj3k_qAltT5POg0ozAopMNX7htaIxGmfzv7EBYeBLxNBGNkFDJm8At4_L4dzZhNlMGKicFOv6Uo3FJM5R1AQQjjF8_2HWIqCTI7DNm3uaEfbX_z-j1ukw1I5YZTnOCPxNS82rrZsCHPdwLQP0-bQ=w2560-h1790"
					alt="Three sample images of provider-oriented app screens from the HealthShift native application."
				/>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Track all of your health activities"
					image={false}
					reverse={false}
					subtitle="Whether drinking water, finishing a solo workout, or competing against co-workers, HealthShift helps track it all. Get daily overviews, interval reports, and new content recommendations. The more that members interact on the platform, the more it can help shift behaviors toward achieving user goals."
					>
					<img
						className="caseStudy__section__image"
						src="https://lh3.googleusercontent.com/pw/AM-JKLW6dbZwDHMh65mz8wHQTlO24qr0nEkGto3ftaKOvOOWhVTJpfvUnKLeSCbVRDBuBDEOIyZT9B_zTiOwf88ZGkmOrnB8hPSpNK4ZqUSk_KXE6TmVdN5ejDo-Sd_4Xl5JHhZmM9hlQ80nTLSc3-VnzwfUAg=w1689-h1749"
						alt="Sample Dashboard screen, and stylized depiction of the Activity screen from the HealthShift native application."
					/>
				</CaseStudySection>

				<Spacer spacing={180} />

				<CaseStudySection
					title="Schedule consults and talk to health professionals"
					subtitle="A digital-first platform that helps connect patients with providers"
					reverse={false}
				>
					<p className="paragraph--small">The platform gives providers access to thousands of new prospective patients and unlocks digital capabilities that may be missing from their traditional practice. Patients can read reviews and learn about provider practice areas, and when they’re ready to proceed, they can book a variety of appointments, including 1:1 text messaging or video calls through the app, or schedule a direct phone call from the provider. When it’s time to pay, patients can pay with a card or virtual wallet, providers get paid by the platform, and HealthShift orchestrates it all from the middle for a nominal per-transaction fee.</p>
				</CaseStudySection>

				<Spacer spacing={60} />

				<CaseStudyImage
					extended={false}
					source="https://lh3.googleusercontent.com/DYI_lMjGk53EPjw0igpxGusYm8vnsjJQzAngZHACNwaDqvUFVKlYkI6tnaWPEbqGUvQemGoF1jOvD-T0FZF1NueKa_YGUVuNUlDtulyEH9lL-3IuHnJbIwbI6g9C5LX9KpXomACaeAMWg3aUlZErvasNjBdRqYROkS4wqCo5aF9EYLq1ljK9jvYCp-xh5nv4ioJH4Kbksh4w4_St1O0lMnU0A61KBrh2yikvZnoM_ghbFWapYa7i4aPM-dLq0zl__1HcqjGOqzm9dUD2dOz0RRooEvELPJFPCGD-GxRagUlxcHMTtoYO3mdTnt_o4FYoGwFJfuDELeBJaIaKjBMpNfpuG_6sAL4bFzvCdS6CLAbyCz6atELMMyBsapq9TSphn4-_tKX0cK8qkCVugYfRWYJtAQPKaYxoBumn0693fuXFMvIjHRJGwCEltKf5yBrkUH4AFPp0Y9ADy0QxHBoUI2vZol2G1KTWhE0SSjpspNb3WEJjIKup4JcSF91VofsdLsqWgTpxBPokpUm7M7ED4t7zSIVarY8hMLXGm8VHxeDeXbeeGLELkh5o_PAgOrf2CCGGkXjEvDinj0AXgw3fyaID-hAYM-TWRvEIZperARSHKoLQCAhRxSw2azwnnbEwD5cyAXJNdvRWHnVIG0t3IhNciXAJtfEQzhhgQi1922UtLPLRzWgNfsDxyBbIGcQuHQYyUHcrYTMEBCriOkU2YoRJ=w2763-h1929"
					alt="Three sample images of provider-oriented app screens from the HealthShift native application."
				/>

				<Spacer spacing={180} />

				<CaseStudyResults
					statistics={[
						{ number: '+4', description: 'Signed four new Fortune 500 clients in year one.' },
						{ number: '+812', description: 'Average new users added to the platform per client.'},
						{ number: '92k', description: 'Average user activities logged per month per client.'},
					]}
				/>

				<p className="caseStudy__reflection paragraph--small">After eleven long months of growth hacking the platform from concept to reality, the company's success was rewarded with an acquisition by Johnson & Johnson's internal innovation lab in 2018 for a multi-million dollar sum. The leadership at HealthShift moved into senior leadership roles at J&J and continued to build the platform for additional J&J workplace wellness needs.</p>

				<Spacer spacing={180} />

			</>
		);
	}
}
